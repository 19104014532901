import { instance } from "../libs/client";

class JointService {
  updateJoint = async (id, data) => {
    return instance.put(`joint/${id}`, data);
  };
  listJoint = async () => {
    return instance.get(`joint`);
  };
  listJointsByJob = async (id) => {
    return instance.get(`joint/${id}`);
  };
}
const jointService = new JointService();
export { jointService };

const en = {
  login: {
    invalid: "Invalid credentials.",
    loggedIn: "Successfully logged in.",
    expired: "Your account has expired.",
  },
  auth: {
    login: "Login",
    search: "Search",
    language: "Language",
    casingList: "Casing List",
    reportList: "Report List",
    jobList: "Casing Running List",
    equipmentList: "Equipment List",
    settings: "Settings",
    csvUpload: "CSV Upload",
    map: "Map",
    report: "Report",
    log: "Log Records",
    password: "Password",
    username: "Username",
    email: "Email",
    welcome: "Welcome",
    welcomeSubTitle: "Please enter your details.",
    changePassword: "Change Password",
    changePasswordSubTitle: "Please enter yor new password.",
    change: "Change",
    userList: "User List",
    casing: "Casing",
    casingNameCasing: "Casing",
    customerList: "Customer List",
    settings: "Settings",
  },
  routes: {
    casingList: "Casing List",
    jobList: "Casing Running List",
    equipmentList: "Equipment List",
    settings: "Settings",
    csvUpload: "CSV Upload",
  },
  validation: {
    empty: "Cannot be empty",
    string: "Input must be in string format",
    email: "Input must be in email format",
    number: "Input must be in number format",
    max30: "30 is the maximum value for this input",
    min0: "0 is the minimum value for this input",
    date: "Input must be in date format",
  },
  form: {
    edit: "Edit",
    selectFile: "Select File",
    removePicture: "Remove Picture",
    selectDate: "Select Date",
    timePeriod: "Time Period",
    sortFilter: "Search Filter",
    unauthorized: "You are not authorized for this operation",
  },
  buttons: {
    start: "Start",
    login: "Login",
    next: "Next",
    prev: "Prev",
    add: "Add",
    delete: "Delete",
    addNew: "Add New",
    change: "Change",
    stop: "Cancel",
    save: "Save",
    edit: "Save",
    edit2: "Edit",
    removeFilter: "Remove Filter",
    closeButton: "Close",
    update: "Update",
    send: "Send",
    failed: "Unfortunately, the transaction could not be completed.",
    go: "Go to File",
    download: "Job Order",
    downloads: "Download Job Detail",
  },
  dashboard: {
    reports: "Reports",
    status: "Status",
    country: "Country",
    city: "City",
    rig: "Rig",
    well: "Well",
    editReport: "Edit Report",
    saveReport: "Save Report",
    reportName: "Report Name",
    deleteReportSubTitle: "Are you sure you want to delete the report named ",
  },
  csv: {
    addReport: "Add Report",
    clickToUpload: "Click to upload",
    csvOnly: "CSV only",
    csvAdded: "",
    pleaseUploadAFile: "Please upload a file",
    createReport: "Create Report",
    invalid: "Invalid file",
    invalidExp:
      "The device that created the file you uploaded is not registered to your company.",
  },
  job: {
    casingRunning: "Casing Running",
    all: "All",
    active: "Active",
    activeJob: "Active Job",
    waiting: "Waiting",
    done: "Done",
    waitingJob: "Waiting Job",
    pendingWaiting: "Pending Approval",
    createNewJob: "Create New Job",
    jobs: "Jobs",
    allJob: "All Jobs",
    deleteJobSubTitle: " Are you sure you want to delete the job named?",
    job: "Job",
    status: "Status",
    jobName: "Job Name",
    jobNo: "Job No",
    rig: "Rig",
    well: "Well",
    wellDepth: "Well Depth (m)",
    createJobWith: "Request Tally",
    ppcf: "",
    approved: "Approved",
    deliveryDate: "Delivery Date",
    flotation: "Flotation",
    casingTopLeft: "Casing Top Length (m)",
    casingShoeDepth: "Shoe Depth (m)",
    ratHole: "Rat Hole (m)",
    casingCompany: "Casing Co.",
    casingSupervisor: "Casing Supervisor",
    tongOperator: "Tong Operator",
    threadRepresentativeCompany: "Thread Rep. Company",
    threadRepeat: "Thread Rep (s)",
    equipment: "",
    squeeze: "Squeeze (Ib.ft)",
    companyRepresentative: "Comp.Rep.",
    lease: "Lease",
    firstTechnician: "Tech 1",
    secondTechnician: "Tech 2",
    stabTechnician: "Stab Technician",
    fieldInformation: "Field Information",
    jobInformation: "Job Information",
    mudWeight: "Mud Weight (ppcf)",
    uploadReport: "Upload Report",

    ///
    lots: "",
    lot: "Lot",
    lotNo: "Lot No",
    lotType: "Lot Type",
    type: "Type",
    compound: "Compound",
    size: "Size",
    weight: "Weight",
    grade: "Grade",
    highShoulder: "High Shoulder",
    lowShoulder: "Low Shoulder",
    updateLot: "Update Lot",
    record: "Record",
    log: "Log",
    dateRecord: "Date",
    location: "Location",
    ipAddress: "IP Address",
    records: "Records",
    ///
    casingTally: "",
    joints: "",
    joint: "Joint",
    jointNo: "Joint No",
    centralizer: "Centralizer",
    materialSpecific: "Material Specific",
    casingNo: "Casing No",
    length: "Length",
    totalLength: "Total Length",
    materialTopEndDepth: "Material Top End Depth",
    wMud: "Mud Weight (t)",
    totalWMud: "Mud Weight Total (t)",
    updateJoint: "Update Joint",
    sameName: "Cannot be same casing name.",
    noChartDataJoint: "This Joint has no data.",
    passive: "The Job is not active.",
    updated: "Updated.",
    failed: "Failed.",
    saved: "Saved.",
    deleted: "Deleted.",
    quickAdd: "Quick Add Joint",
    casingQty: "Casing Quantity",
    totalLength: "Total Length",
    made: "Casing",
    quickAddInfo:
      "*When Adding a Quick Casing, Previous Casings will be Deleted.",
  },
  equipment: {
    equipmentList: "",
    equipment: "Equipment",
    list: "List",
    createNewEquipment: "Create New Equipment",
    tongManufacturer: "Tong Manufacturer",
    tongSize: "Tong Size",
    tongArmLength: "Tong Arm Length",
    torqueSubSerial: "Torque Serial No",
    loadCellSerial: "Load Cell Serial",
    loadCellCapacity: "Load Cell Capacity",
    loadCellSensitivity: "Load Cell Sensitivity",
    cellCalibrationDate: "Cell Calibration Date",
    threadCount: "Thread Count",
    comment: "Info",
    deleteEquipmentSubTitle:
      " Are you sure you want to delete the equipment named",
    edit: "EDIT",
    delete: "DELETE",
    all: "All",
    material: "Material",
    created: " created successfully.",
    updated: " updated successfully.",
    deleted: " deleted successfully.",
  },
  casing: {
    casingList: "",
    casing: "",
    createNewCasing: "Create New Casing",
    createNewCasings: "Create New Casing",
    casingTally: "Casing Tally",
    casingName: "Casing Name",
    producerCompany: "Producer Company",
    casingType: "Casing Type",
    casingSize: "Casing Size (m)",
    grade: "Grade",
    weight: "Weight (Ib/ft)",
    threadCompound: "Thread Compound",
    wall: "Wall (in)",
    estimatedTorque: "Estimated Torque (ft/Ib)",
    ID: "ID (in)",
    driftID: "Drift ID (in)",
    minTorque: "Min Tork",
    maxTorque: "Max Tork",
    optTorque: "Opt Tork",
    shoulderTorque: "shdlr",
    maxDelta: "Max Delta",
    turn: "Turn",
    maxTurn: "Max",
    minTurn: "Min",
    referenceTorque: "Reference Torque",
    collapse: "Collapse (psi)",
    burst: "Burst (psi)",
    jointStrenght: "Joint Strng",
    bodyYield: "Body Yield",
    lbs: "1000 lbs",
    deleteCasingSubTitle: " Are you sure you want to delete the named casing?",
    upload: "Upload",
    minimumTorque: "Minimum Torque",
    optimumTorque: "Optimum Torque",
    maximumTorque: "Maksimum Torque",
    shouldersTorque: "Shoulder Torque",
    maxsTurn: "Max Turn",
    minsTurn: "Min Turn",
    created: " created successfully.",
    updated: " updated successfully.",
    deleted: " deleted successfully.",
  },
  user: {
    userList: "",
    user: "User",
    list: "List",
    name: "Name",
    lastName: "Last Name",
    phoneNumber: "Phone",
    email: "Email",
    company: "Company",
    avatar: "Image",
    userLimit: "User Limit",
    image: "Image",
    address: "Address",
    title: "Title",
    password: "Password",
    passwordRepeat: "Password Repeat",
    notMatchPassword: "Passwords are not matching!",
    createNewUser: "Create New User",
    noCredit: "No user credits left",
    noCreditHeader: "User credit full",
    deleteUserSubTitle: "Are you sure you want to delete the user named",
    profile: "Profile",
    page: "Page",
    role: "Role",
    jamlog: "Jamlog",
  },
  imageUploader: {
    max2Mb: "File size cannot be larger than 2mb!",
  },
  fileInput: {
    profilSubTitle: "Click to upload picture.",
    uploadHeaderPicture: "Profile Picture",
    documentSubTitle: "Click To Upload Document",
    uploaderHeaderDoc: "Document",
    uploadAvatar: "Upload Avatar",
    changePic: "Change Picture",
    cropPicture: "Crop Picture",
    removePicture: "Remove Picture",
    uploadFile: "Upload File",
    uploaderFile: "Upload File",
    //Equipment
    equipmentPicture: "Equipment Picture",
    equipSubTitle: "Click to upload profile picture.",
  },
  noData: {
    header: "No Data",
    logText: "There is no log record for now.",
    casingText: "There is no recorded casing.",
    equipmentText: "There is no recorded equipment.",
    jobText: "There is no recorded job.",
    userText: "There is no recorded user.",
  },
  map: {
    onProgress: "This operation is in progress.",
    goJobs: "To edit this work order, you can go to the work orders page.",
    completed: "Completed",
    active: "Active",
    choose: "Choose Location",
    rigs: "Rigs",
    manuelJob: "Continue with manual Job",
  },
  mail: {
    name: "Customer Company Name",
    requestTally: "Create Customer",
    email: "Email",
    validDay: "Account Validation Day",
    requset: "Request",
    rentJamlog: "Rent Jamlog",
    sent: "Email successfully sent.",
    alreadySent: "This email has already been used.",
    node: "Choose Jamlog",
    jamlog: "Jamlog Device",
  },
  jamlog: {
    header: "Choose Device",
    text: "Select the Jamlog device where the job you created will be used.",
    placeholder: "Jamlog Device List",
  },
  customer: {
    customer: "Customer",
    name: "Customer Name",
    mail: "Customer Mail",
  },
};

export default en;

import React, { useState } from "react";
import { Upload } from "../../assets/icons";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { readService } from "../../services/read.service";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { InfoModal } from "../../components";
const UploadData = ({ setUploaded, setUploadModal }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState([]);
  const { auth, report } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});
  const ReportData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", value);
    const res = await readService
      .readFile(formData)
      .then((res) => {
        if (res.data.code == 1) {
          setInfoModalData({
            success: false,
            text: t("csv:invalidExp"),
            header: t("csv:invalid"),
            label: "OK",
          });
          setInfoModal(true);
        } else {
          report.loading = true;
          report.setReportData(res?.data);
          report.loading = false;
          setUploaded(true);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const changeHandler = (event) => {
    setValue(event.target.files[0]);
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      {loading ? (
        <div className="min-h-[295px] w-full flex items-center justify-center">
          <div className="w-10 h-10">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center flex-col my-auto">
          <div className="absolute flex gap-x-6 h-[40px] top-2 right-2">
            <button
              type="button"
              onClick={() => {
                setUploaded(false);
                setUploadModal(false);
              }}
              className="w-[160px] h-full bg-white text-secondary-700 font-semibold flex flex-row items-center justify-center border rounded-lg text-sm"
            >
              {t("buttons:stop")}
            </button>
          </div>
          {value?.name ? (
            <div className="w-full mx-5 h-[230px]  flex items-center justify-center border-[2px] border-dashed border-[#DBEEF1] flex-col ">
              <input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{
                  backgroundColor: "red",
                  display: "block",
                  margin: "10px auto ",
                  width: "95%",
                  height: "230px",
                  opacity: "0",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: "1",
                }}
              />
              <Upload />
              <p className="text-[#005F6A] mt-1 text-lg font-semibold">
                {value?.name}
              </p>
              <p className="text-[#005F6A] mt-1 text-xs "> CSV added</p>
            </div>
          ) : (
            <div className="w-full mx-5 h-[230px]  flex items-center justify-center border-[2px] border-dashed border-[#DBEEF1] flex-col ">
              <input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{
                  backgroundColor: "red",
                  display: "block",
                  margin: "10px auto ",
                  width: "95%",
                  height: "230px",
                  opacity: "0",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: "1",
                }}
              />
              <Upload />
              <p className="text-[#005F6A] mt-1 text-lg font-semibold">
                {t("csv:clickToUpload")}
              </p>
              <p className="text-[#005F6A] mt-1 text-xs ">{t("csv:csvOnly")}</p>
            </div>
          )}
          {value?.name ? (
            <button
              onClick={ReportData}
              className="bg-[#005F6A] text-white font-semibold py-3 w-[300px] rounded-lg mt-4 "
            >
              {t("csv:createReport")}
            </button>
          ) : (
            <button className="bg-[#005F6A] text-white font-semibold py-3 w-[300px] rounded-lg mt-4 opacity-20 cursor-not-allowed">
              {t("csv:pleaseUploadAFile")}
            </button>
          )}
        </div>
      )}
      <InfoModal
        isOpen={infoModal}
        setIsOpen={setInfoModal}
        text={infoModalData?.text}
        header={infoModalData?.header}
        label={infoModalData?.label}
        isSuccess={infoModalData?.success}
      />
    </div>
  );
};

export default UploadData;

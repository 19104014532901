import React, { useState, useEffect } from "react";
import { PencilIcon, PlusIcon } from "../../assets/icons";
import { userService } from "../../services";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CreateNewUser from "./CreateNewUser";
import { useStore } from "../../hooks/useStores";
import { GrLanguage } from "react-icons/gr";
import {
  PaginationComponent,
  Loading,
  InfoModal,
  ChangeLanguage,
  TableSearch,
  NoData,
} from "../../components";
import { roles } from "../../static/constants";
const UserList = () => {
  const tableFilter = [
    {
      value: "name",
    },
    {
      value: "lastName",
    },
    {
      value: "email",
    },
    {
      value: "role",
    },
  ];
  const [newUser, setNewUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});
  const [searchVal, setSearchVal] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  //lang
  const { t } = useTranslation();
  const { auth } = useStore();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  const handleUsersPage = async () => {
    setLoading(true);
    await userService
      .getUsersPaginated(pageNo, searchVal)
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if ((pageNo + 1) >> res.data.meta.pageCount) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleUsersPage();
  }, [pageNo, searchVal]);
  return (
    <>
      {auth.user.role == roles.CA || auth.user.role == roles.CS ? (
        <>
          {" "}
          <div className="w-full h-full flex flex-col relative">
            <div className="w-full h-14 flex items-center justify-between xs:gap-2 px-3 border-b-2 border-secondary-200">
              <div className="flex items-center justify-center gap-x-1">
                <span className="font-bold text-xl xs:text-base text-black">
                  {t("user:user")}
                </span>
                <span className="font-bold text-xl xs:text-base text-[#005F6A]">
                  {t("user:list")}
                </span>
              </div>
              <div className="flex gap-4 items-center">
                <div>
                  <button
                    onClick={() => {
                      setSelectedUser(null);
                      setNewUser(true);
                    }}
                    className="w-[200px] xs:w-[160px] xs:h-8 h-10 rounded-lg bg-[#00727F] hover:border border-teal-500 flex items-center justify-center gap-2 p-2 text-sm xs:text-xs font-medium text-white"
                  >
                    <PlusIcon color="#FFFFFF" />
                    {t("user:createNewUser")}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setVisibleLanguage(!visibleLanguage);
                    }}
                    style={{
                      alignItems: "center",
                      right: match ? 0 : -25,
                      position: "",
                      cursor: "pointer",
                      justifyContent: "center",
                      borderRigth: "0px solid black",
                    }}
                    className="bg-[#FFFFFF] select-none rounded-md w-10  h-10 xs:w-7 xs:h-7 text-white flex border"
                  >
                    <GrLanguage className="xs:w-4 xs:h-4" color="#344054" />
                  </button>
                </div>
                {visibleLanguage && (
                  <ChangeLanguage
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    setVisibleLanguage={setVisibleLanguage}
                  />
                )}
              </div>
            </div>
            <div
              style={{ zIndex: newUser ? "-10" : "0" }}
              className="w-full flex flex-1 overflow-y-auto scrollbar-hide flex-col border border-gray-200 shadow rounded-lg mt-6"
            >
              <div className="w-full h-[73px] min-h-[73px] flex items-center px-6 border-b border-gray-200">
                <span className="h-[22px] px-2 flex items-center justify-center bg-teal-50 rounded-2xl text-xs font-medium text-teal-700">
                  {`${totalCount}/${auth?.user?.company?.userLimit} ${t(
                    "user:user"
                  )}`}
                </span>
                <div className="flex flex-row gap-4 ml-auto">
                  <div className="w-[200px] ml-auto xs:w-[190px]">
                    <TableSearch
                      setSearchVal={setSearchVal}
                      height={44}
                      placeholder={t("auth:search")}
                    />
                  </div>
                </div>
              </div>
              <div className="xs:w-full xs:overflow-x-scroll h-full overflow-hidden">
                <div className=" w-full xs:min-w-[1440px] h-11 min-h-[44px] flex items-center justify- bg-gray-50 border-b border-gray-200">
                  {tableFilter?.map((item, i) => {
                    return (
                      <div className="grow w-[calc(100%/10)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                        <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                          {t(`user:${item?.value}`)}
                        </p>
                      </div>
                    );
                  })}
                  <div className="grow  w-[calc(100%/30)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r  border-gray-200">
                    <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                      {t("equipment:edit")}
                    </p>
                  </div>
                </div>
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <div className="w-12 h-12">
                      <Loading />
                    </div>
                  </div>
                ) : totalCount <= 0 ? (
                  <div className="flex items-center justify-center h-full">
                    <NoData
                      header={t("noData:header")}
                      text={t("noData:userText")}
                    />
                  </div>
                ) : (
                  pageData?.map((person, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full h-[66px] xs:min-w-[1440px] min-h-[66px] border-b border-gray-200 hover:bg-gray-50 flex items-center"
                      >
                        {tableFilter.map((d, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                setSelectedUser(person);
                              }}
                              className="grow w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200"
                            >
                              <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                                {person[d.value]}
                              </p>
                            </div>
                          );
                        })}
                        <div className="grow w-[calc(100%/30)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                          <div
                            onClick={() => {
                              setSelectedUser(person);
                              setNewUser(true);
                            }}
                            className="w-[72px] min-w-[72px] h-full cursor-pointer flex items-center justify-center gap-2 border-gray-200"
                          >
                            <PencilIcon className="w-5 h-5 text-secondary-500" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <PaginationComponent
              pageNo={pageNo}
              setPageNo={setPageNo}
              pageCount={pageCount}
            />
            <div
              className={`${
                newUser ? "w-full left-0" : "w-0 left-[100%]"
              } overflow-hidden top-0 h-full bottom-0 transition-all duration-500 absolute z-50 bg-white`}
            >
              {newUser && (
                <CreateNewUser
                  selectedUser={selectedUser}
                  setNewUser={setNewUser}
                  handleUsers={handleUsersPage}
                  setInfoModalOpen={setInfoModal}
                  setInfoModalData={setInfoModalData}
                />
              )}
            </div>
          </div>
          <InfoModal
            isOpen={infoModal}
            setIsOpen={setInfoModal}
            text={infoModalData?.text}
            header={infoModalData?.header}
            label={infoModalData?.label}
            isSuccess={infoModalData?.success}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserList;

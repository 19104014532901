import React, { useState, useEffect } from "react";
import { PlusIcon, JsonIcon } from "../../assets/icons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GrLanguage } from "react-icons/gr";
import SendMailModal from "../JobComponents/SendMailModal";
import { readService } from "../../services";
import {
  PaginationComponent,
  Loading,
  TableSearch,
  ChangeLanguage,
  NoData,
} from "../../components";
import { useStore } from "../../hooks/useStores";
import { roles } from "../../static/constants";
const CustomerList = () => {
  const { auth } = useStore();
  const [searchVal, setSearchVal] = useState();
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [tempReports, setTempReports] = useState([]);
  const { t } = useTranslation();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  const handleReports = async () => {
    setLoading(true);
    await readService
      .getCustomerReports()
      .then((res) => {
        setPageData(res.data);
        setTempReports(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleReports();
  }, []);
  useEffect(() => {
    if (searchVal) {
      const filterJobs = tempReports.filter((e) => {
        let name;
        if (e.name) {
          name = `${e?.name}`;
        } else {
          name = `${e?.jobName}`;
        }
        return name
          .replace(/^\./g, "")
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setPageData(filterJobs);
    } else {
      setPageData(tempReports);
    }
  }, [searchVal, setSearchVal]);
  const tableHeadItems = [
    { label: t("job:approved"), value: "isActive" },
    { label: t("customer:name"), value: "name" },
    { label: t("customer:mail"), value: "mail" },
    { label: t("job:jobName"), value: "jobName" },
    { label: t("job:companyRepresentative"), value: "companyRep" },
    { label: t("job:lease"), value: "lease" },
    { label: t("job:rig"), value: "rig" },
    { label: t("job:well"), value: "well" },
    { label: t("job:casingCompany"), value: "casingCompany" },
    { label: t("job:deliveryDate"), value: "date" },
    { label: "", value: "edit" },
  ];
  return (
    <>
      {auth.user.role == roles.CA ? (
        <>
          <div className="w-full h-full flex flex-col relative">
            <div className="w-full h-14 flex items-center justify-between px-3 border-b-2 border-secondary-200">
              <div className="flex items-center justify-center gap-x-1">
                <span className="font-bold text-xl text-black">
                  {t("customer:customer")}
                </span>
                <span className="font-bold text-xl text-[#005F6A]">
                  {t("equipment:list")}
                </span>
              </div>
              <div className="flex gap-4 items-center">
                <div>
                  <button
                    onClick={() => {
                      setMailModal(true);
                    }}
                    className="w-[160px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-[#005F6A] hover:border border-teal-50 flex items-center justify-center gap-2 text-sm font-medium text-white"
                  >
                    <PlusIcon width={12} height={12} color={"#fff"} />
                    <p className="text-sm xs:text-xs text-white">
                      {t("mail:requestTally")}
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setVisibleLanguage(!visibleLanguage);
                    }}
                    style={{
                      alignItems: "center",
                      right: match ? 0 : -25,
                      position: "",
                      cursor: "pointer",
                      justifyContent: "center",
                      borderRigth: "0px solid black",
                    }}
                    className="bg-[#FFFFFF] select-none rounded-md w-10 h-10 xs:w-9 xs:h-9 text-white flex border"
                  >
                    <GrLanguage color="#344054" />
                  </button>
                </div>
                {visibleLanguage && (
                  <ChangeLanguage
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    setVisibleLanguage={setVisibleLanguage}
                  />
                )}
              </div>
            </div>
            <div className="w-full flex flex-1 overflow-y-auto  scrollbar-hide flex-col border border-gray-200 shadow rounded-lg mt-6 overflow-hidden">
              <div className="w-full h-[73px] min-h-[73px] flex items-center px-6 xs:px-2 border-b border-gray-200">
                <span className="h-[22px] px-2 flex items-center justify-center bg-teal-50 rounded-2xl text-xs font-medium text-teal-700">
                  {pageData.length} {t("job:job")}
                </span>
                <div className="flex flex-row gap-4 ml-auto relative ">
                  <div className="w-[200px] xs:w-[180px] ml-auto">
                    <TableSearch
                      setSearchVal={setSearchVal}
                      height={44}
                      placeholder={t("auth:search")}
                    />
                  </div>
                </div>
              </div>
              <div className="xs:overflow-x-auto h-full">
                <div className="xs:min-w-[1000px] w-full h-full">
                  <div className="w-full h-11 min-h-[44px]  flex bg-gray-50  border-b border-gray-200">
                    <div className="w-full h-11 xs:min-w-[1440px] bg-gray-50 flex items-center justify-between">
                      {tableHeadItems?.map((past, i) => {
                        return (
                          <span
                            key={i}
                            className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                          >
                            {past.label}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  {loading ? (
                    <div className="flex items-center justify-center h-full">
                      <div className="w-12 h-12">
                        <Loading />
                      </div>
                    </div>
                  ) : pageData.length <= 0 ? (
                    <div className="flex items-center justify-center h-full">
                      <NoData
                        header={t("noData:header")}
                        text={t("noData:logText")}
                      />
                    </div>
                  ) : (
                    pageData
                      .sort((a, b) => {
                        return (
                          moment(b?.date).format("X") -
                          moment(a?.date).format("X")
                        );
                      })
                      .map((b, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              className="flex  w-full xs:min-w-[1440px] h-[72px] items-center border-b"
                            >
                              <span className="text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                <li className="min-w-[72px] border-gray-200 min-h-[72px] flex items-center justify-center group-hover:bg-slate-50">
                                  <span
                                    style={{
                                      backgroundColor:
                                        b?.s3Key != null
                                          ? "#0E9384"
                                          : "#F79009",
                                    }}
                                    className="w-3 h-3 rounded-full border border-gray-300 shadow-sm"
                                  ></span>
                                </li>
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.user?.name ? b?.user?.name : "---"}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.user?.email ? b?.user?.email : "---"}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.jobName
                                  ? b?.jobName
                                  : b?.name
                                  ? b?.name
                                  : "---"}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.companyRep ? b?.companyRep : "---"}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.lease ? b?.lease : "---"}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.rig}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.well}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {b?.casingCompany ? b?.casingCompany : "---"}
                              </span>
                              <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                {moment(b?.deliveryDate).format("DD/MM/YYYY")}
                              </span>
                              {/* <span
                            onClick={() => {
                              //   setSelectedJob(b);
                              //   setNewJob(true);
                            }}
                            className="md:text-[10px] text-center text-xs cursor-pointer w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                          >
                            <EditIcon width={24} height={24} />
                          </span> */}
                              {/* <span
                            onClick={() => {
                              //   setSelectedJob(b);
                              //   setIsAlert(true);
                            }}
                            className="md:text-[10px] text-center text-xs cursor-pointer w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                          >
                            <BsTrash size={24} />
                          </span> */}
                              <span
                                onClick={() => {
                                  //   getJson(b);
                                }}
                                className="md:text-[10px] text-center text-xs cursor-pointer w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                              >
                                <JsonIcon />
                              </span>
                            </div>
                          </>
                        );
                      })
                  )}
                </div>
              </div>
            </div>
            {/* <PaginationComponent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        /> */}
          </div>
          <SendMailModal isOpen={mailModal} setIsOpen={setMailModal} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerList;

import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../components";
import { jobService, nodeService } from "../../services";
import { useAlert, positions } from "react-alert";
import { InfoModal, Select } from "../../components";
const SendMailModal = ({ isOpen, setIsOpen }) => {
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [days, setDays] = useState(3);
  const [nodes, setNodes] = useState("");
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();
  const alert = useAlert();
  function closeModal() {
    setEmail("");
    setIsOpen(false);
  }
  const sendMail = async () => {
    const res = await jobService
      .requestTally({ name, email, days, nodes: [nodes] })
      .then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          closeModal();
          alert.success(t("mail:sent"), {
            position: positions.TOP_LEFT,
          });
        } else if (res.data.code == 2) {
          setInfoModalData({
            success: false,
            text: t("user:noCredit"),
            header: t("csv:invalid"),
            label: "OK",
          });
          setInfoModal(true);
        } else if (res.data.code == 3) {
          setInfoModalData({
            success: false,
            text: t("mail:alreadySent"),
            header: t("csv:invalid"),
            label: "OK",
          });
          setInfoModal(true);
        } else {
          closeModal();
          alert.error(t("job:failed"), {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNodes = async () => {
    await nodeService
      .listNodes()
      .then((res) => {
        setOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleNodes();
    return () => {};
  }, []);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999] flex" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full  w-auto  items-center justify-center sm:p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-auto xs:mt-40 sm:mt-40 transform overflow-hidden rounded-2xl bg-white p-2 xs:px-0 text-left align-middle shadow-xl transition-all">
                  <div className="h-full overflow-auto w-full xs:w-fit max-w-[400px] max-h-[650px] min-h-[500px] scrollbar-hide">
                    <div className="min-h-[300px] flex flex-col  text-center justify-between min-w-[400px] xs:min-w-fit max-w-[400px] p-3">
                      <div className="">
                        <div className="text-left font-medium text-[20px] text-gray-900">
                          {t("mail:requestTally")}
                        </div>
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          sendMail();
                        }}
                        className="my-2 h-full w-full flex flex-col gap-y-4 mt-4"
                      >
                        <div className="w-full flex flex-col font-medium text-[16px] gap-y-4">
                          <div className="w-full flex flex-col text-left gap-3">
                            <Input
                              label={t("mail:name")}
                              width={"100%"}
                              onChange={(e) => setName(e)}
                              value={name}
                            />
                            <Input
                              label={t("mail:email")}
                              width={"100%"}
                              onChange={(e) => setEmail(e)}
                              value={email}
                              inputType="email"
                            />
                            <Input
                              label={t("mail:validDay")}
                              width={"100%"}
                              onChange={(e) => setDays(e)}
                              value={days}
                              inputType="number"
                            />
                            <Select
                              placeholder={t("mail:jamlog")}
                              width={"100%"}
                              items={options?.map((node) => ({
                                label: node.name,
                                value: node.id,
                              }))}
                              onChange={(v) => {
                                setNodes(v);
                              }}
                              value={nodes}
                            />
                            <div className="my-1"></div>
                          </div>
                        </div>
                        <div className="w-full flex justify-center gap-x-3">
                          <button
                            className={`bg-white text-secondary-700 border border-secondary-300 w-full max-w-[98%] p-2.5 rounded-lg`}
                            onClick={closeModal}
                            type="button"
                          >
                            {t("buttons:stop")}
                          </button>
                          <button
                            className={`bg-teal-700 text-white w-full max-w-[98%] p-2.5 rounded-lg`}
                            type="submit"
                          >
                            {t("buttons:send")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <InfoModal
        isOpen={infoModal}
        setIsOpen={setInfoModal}
        text={infoModalData?.text}
        header={infoModalData?.header}
        label={infoModalData?.label}
        isSuccess={infoModalData?.success}
      />
    </>
  );
};

export default SendMailModal;

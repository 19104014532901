import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Input from "../Inputs/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../Select/Select";
import { jointService } from "../../services";
import { useAlert, positions } from "react-alert";
import { Loading } from "../../components";
const UpdateJointModal = ({
  modalIsOpen,
  title,
  width,
  closeModal,
  height,
  i,
  selectedJoint,
  setEditJointModal,
  handleJoints,
  jobInfo,
  lots,
  casingItems,
  joints,
  setJoints,
}) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    centralizer: yup.boolean(),
    lotNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lengthVal: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const calculateWmud = (len = 0, lotNo) => {
    const find = lots?.filter((c, i) => {
      return lotNo == c.lotNo;
    });
    let flo = jobInfo?.flotation ? jobInfo?.flotation : 0;
    let wei = find[0]?.weight ? find[0]?.weight : 0;
    const res = Number(len) * Number(wei) * Number(flo) * 0.00148;
    return res;
  };
  const removeJoint = () => {
    setJoints(
      joints?.filter(
        (item, i) => item?.casingName !== selectedJoint?.casingName
      )
    );
    setEditJointModal(false);
  };
  const onSubmit = async (data) => {
    data.wMud = calculateWmud(data.lengthVal, data.lotNo).toFixed(2);
    data.lots = lots;
    if (selectedJoint) {
      setLoading(true);
      if (selectedJoint.id) {
        await jointService
          .updateJoint(selectedJoint.id, data)
          .then((res) => {
            if (res.data.code == 0) {
              handleJoints();
            } else {
              alert.error("Failed.", {
                position: positions.TOP_LEFT,
              });
            }
          })
          .catch((err) => console.log(err));
      }
      const filtered = joints.filter((j, i) => {
        return j?.casingNo != selectedJoint?.casingNo;
      });
      data.casingNo = selectedJoint?.casingNo;
      if (Number(selectedJoint.casingName) && !Number(data.casingName)) {
        const newJoints = [...filtered, data].map((j, i) => {
          if (Number(j.casingNo) > selectedJoint.casingNo) {
            if (Number(j.casingName)) {
              return {
                ...j,
                casingName:
                  Number(j.casingName) > 1 ? Number(j.casingName) - 1 : 1,
              };
            } else return j;
          } else return j;
        });
        setJoints(newJoints.sort((a, b) => a?.casingNo - b?.casingNo));
      } else {
        setJoints(
          [...filtered, data].sort((a, b) => a?.casingNo - b?.casingNo)
        );
      }
      alert.success("Updated.", {
        position: positions.TOP_LEFT,
      });
      setLoading(false);
      setEditJointModal(false);
    } else {
      alert.error("Failed.", {
        position: positions.TOP_LEFT,
      });
    }
  };
  useEffect(() => {
    if (selectedJoint?.lengthVal) {
      setValue("centralizer", selectedJoint?.centralizer);
      setValue("lotNo", Number(selectedJoint?.lotNo));
      setValue("lengthVal", selectedJoint?.lengthVal);
      setValue("casingName", selectedJoint?.casingName);
    } else {
    }
  }, [selectedJoint]);
  return (
    <>
      <Modal
        appElement={document.getElementById("app")}
        closeTimeoutMS={350}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={`h-[440px] w-[450px] xs:w-[350px] ReactModal`}
        style={{
          overlay: {
            background: "rgba(0, 0, 0, 0.3)",
          },
          content: {
            position: "absolute",
            transform: "translate(-50%,-50%)",
            width: width,
            left: "50%",
            top: "50%",
            background: "#fff",
            height: height,
            padding: "16px",
            boxShadow:
              "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            borderRadius: "12px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "20px",
          },
        }}
      >
        <div className="flex h-full w-full flex-col justify-between mt-4">
          <span className="font-medium text-2xl text-[#101828]" key={i}>
            {title}
          </span>
          {loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <div className="w-12 h-12">
                <Loading />
              </div>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col w-full h-fit items-start"
            >
              <div className="w-full flex flex-col items-start gap-4 mt-3">
                <div className="flex flex-row items-center gap-2">
                  {/* <input
                    type="checkbox"
                    value={true}
                    {...register("centralizer")}
                  /> */}
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={true}
                      {...register("centralizer")}
                      className="sr-only peer"
                    />
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-teal-700 peer-checked:bg-teal-700" />
                  </label>
                  <div className="text-sm font-medium">
                    {t("job:centralizer")}
                  </div>
                </div>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      placeholder={t("job:materialSpecific")}
                      items={casingItems}
                      width={"100%"}
                      fontSize={14}
                      onChange={(v) => {
                        setValue("lotNo", v);
                      }}
                      value={value}
                      validate={errors?.lotNo ? "error" : ""}
                      errorMessage={errors?.lotNo ? errors?.lotNo?.message : ""}
                    />
                  )}
                  name="lotNo"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("job:length")}
                      width={"100%"}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputType={"number"}
                      validate={errors?.lengthVal ? "error" : ""}
                      errorMessage={
                        errors?.lengthVal ? errors?.lengthVal?.message : ""
                      }
                    />
                  )}
                  name="lengthVal"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("casing:casingName")}
                      width={"100%"}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      validate={errors?.casingName ? "error" : ""}
                      errorMessage={
                        errors?.casingName ? errors?.casingName?.message : ""
                      }
                    />
                  )}
                  name="casingName"
                />
              </div>
              <div className="flex w-full flex-row items-start gap-4 mt-4">
                <button
                  onClick={() => {
                    removeJoint();
                  }}
                  type="button"
                  className="w-full xs:w-[42%] h-full bg-red-600 text-white flex flex-row items-center justify-center border shadow rounded-lg"
                >
                  {t("buttons:delete")}
                </button>

                <button
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                  className="w-full h-[40px] xs:w-[150px] flex items-center justify-center border shadow rounded-lg bg-white text-sm text-gray-700"
                >
                  {t("buttons:stop")}
                </button>
                <button
                  type="submit"
                  className="w-full h-[40px]  xs:w-[150px] flex items-center justify-center border shadow rounded-lg bg-[#00727F] text-sm text-white"
                >
                  {t("buttons:update")}
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default UpdateJointModal;

import React from "react";
import NoDataImage from "../../assets/icons/NoDataImage.svg";
const NoData = ({ icon, header = "Empty", text = "No Data Found" }) => {
  return (
    <div className="flex flex-col items-center max-w-[352px] gap-3 mx-auto mt-5">
      <div
        className="w-[172px] h-[128px] relative flex justify-center "
        style={{
          backgroundImage: `url(${NoDataImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="text-white absolute bottom-6 ml-1.5 w-8 h-8 max-w-8 max-h-8 flex items-center justify-center">
          {icon}
        </div>
      </div>
      <div className="flex flex-col items-center justify-between text-center">
        <p className="font-medium text-[18px] text-gray-700 xs:text-base">
          {header}
        </p>
        <p className="text-[14px] font-normal text-gray-500 my-2 xs:text-xs ">
          {text}
        </p>
      </div>
    </div>
  );
};

export default NoData;

import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select } from "../../components";
import { jobService } from "../../services";
import { useAlert, positions } from "react-alert";
import { InfoModal } from "../../components";
import { SelectIcon } from "../../assets/icons";
const ChooseNodeModal = ({
  isOpen,
  setIsOpen,
  nodes,
  createJob,
  selectedNode,
  setSelectedNode,
}) => {
  const { t } = useTranslation();
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999] flex" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full  w-auto  items-center justify-center sm:p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-auto xs:mt-40 sm:mt-40 transform overflow-hidden rounded-2xl bg-white p-2 xs:px-0 text-left align-middle shadow-xl transition-all">
                  <div className="h-full overflow-auto w-full xs:w-fit max-w-[450px] max-h-[340px] min-h-[320px]">
                    <div className="min-h-[320px] flex flex-col  text-center justify-between items-center min-w-[400px] xs:min-w-fit max-w-[450px] p-3">
                      <div>
                        <div className="flex items-center justify-center w-14 h-14 bg-[#F2F4F7]">
                          <SelectIcon width={32} height={32} />
                        </div>
                      </div>
                      <div className="flex flex-col justify-center items-center gap-y-3">
                        <div className="font-medium text-[16px] text-[#101828]">
                          {t("jamlog:header")}
                        </div>
                        <span className="text-[14px] text-gray-600">
                          {t("jamlog:text")}
                        </span>
                      </div>
                      <div className="w-full flex flex-col font-medium text-[16px] gap-y-4">
                        <div className="w-full flex flex-col text-left gap-3">
                          <Select
                            placeholder={t("jamlog:placeholder")}
                            items={nodes.map((n) => {
                              return {
                                value: n.id,
                                label: n.name,
                              };
                            })}
                            width={"100%"}
                            fontSize={14}
                            onChange={(v) => {
                              setSelectedNode(v);
                            }}
                            value={selectedNode}
                          />
                        </div>
                      </div>
                      <div className="w-full flex justify-center gap-x-3">
                        <button
                          className={`bg-teal-700 text-white w-full max-w-[98%] p-2.5 rounded-lg`}
                          onClick={() => {
                            createJob();
                          }}
                        >
                          {t("buttons:save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ChooseNodeModal;

import { CheckIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { EditIcon } from "../../assets/icons";
import UpdateLotModal from "../../components/Modals/UpdateLotModal";
import Select from "../../components/Select/Select";
const LotTable = ({
  setNewLot,
  setNewJoint,
  selectedJob,
  casings,
  lots,
  setLots,
  jobInfo,
}) => {
  // Inherited Data From Casings
  const [selectedCasing, setSelectedCasing] = useState({});
  const [selectedLot, setSelectedLot] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const { t } = useTranslation();
  const tableHeadItems = [
    { label: t("job:lotNo"), value: "lotNo" },
    { label: "Casing", value: "casing" },
    { label: t("job:type"), value: "typeConn" },
    { label: t("job:compound"), value: "threadCompound" },
    { label: t("job:size"), value: "size" },
    { label: t("job:weight"), value: "weight" },
    { label: t("job:grade"), value: "grade" },
    { label: t("casing:maxTorque"), value: "maxTorque" },
    { label: t("casing:optTorque"), value: "optTorque" },
    { label: t("casing:minTorque"), value: "minTorque" },
    { label: t("casing:maxsTurn"), value: "maxTurn" },
    { label: t("casing:minsTurn"), value: "minTurn" },
    { label: t("job:highShoulder"), value: "highShoulder" },
    { label: t("job:lowShoulder"), value: "lowShoulder" },
    { label: t("casing:referenceTorque"), value: "referenceTorque" },
    { label: "", value: "delete" },
  ];
  useEffect(() => {
    if (selectedJob && selectedJob.id) {
      setLots(selectedJob?.lot?.sort((a, b) => a?.lotNo - b?.lotNo));
    } else {
      setLots([]);
    }
  }, []);
  const schema = yup.object({
    typeConn: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    threadCompound: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    size: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    weight: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    grade: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    maxTorque: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    lotNo: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    optTorque: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    minTorque: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    maxTurn: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    minTurn: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    highShoulder: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    lowShoulder: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    referenceTorque: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });
  const {
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    data.lotNo = lots.length + 1;
    setSelectedCasing();
    setLots((lot) => [...lot, data]);
    reset({
      typeConn: "",
      grade: "",
      weight: "",
      size: "",
      minTorque: "",
      optTorque: "",
      maxTorque: "",
      highShoulder: "",
      lowShoulder: "",
      minTurn: "",
      maxTurn: "",
      referenceTorque: "",
      threadCompound: "",
      casing: "",
    });
  };
  const removeLot = (e) => {
    setLots(lots?.filter((lot, i) => i !== e));
    lots.map((lot, i) => {
      lot.lotNo = i;
    });
  };
  useEffect(() => {
    const filter = casings?.filter((casing, i) => {
      return casing?.id == selectedCasing;
    })[0];
    if (filter && filter.id) {
      setValue("casing", selectedCasing);
      setValue("typeConn", filter?.typeConn);
      setValue("grade", filter?.grade);
      setValue("weight", filter?.weight);
      setValue("size", filter?.casingSize);
      setValue("minTorque", filter?.min);
      setValue("optTorque", filter?.opt);
      setValue("maxTorque", filter?.max);
      setValue("highShoulder", filter?.shdlr);
      setValue("lowShoulder", filter?.shdlr);
      setValue("minTurn", filter?.minTurn);
      setValue("maxTurn", filter?.maxTurn);
      setValue("referenceTorque", filter?.referenceTorque);
      setValue("threadCompound", filter?.threadCompound);
    }
  }, [selectedCasing, setSelectedCasing]);
  return (
    <div className="flex flex-col xs:overflow-auto  items-start px-4 py-5 gap-4 w-full h-full">
      <div className="xs:overflow-x-auto xs:flex-col xs:w-full h-[94%] xs:h-full">
        <div className="flex flex-row items-center px-5 h-14 border-b rounded-[2px] border-gray-200 w-full">
          <div className="flex items-center justify-center">
            <p className="font-medium text-xl">Lots</p>
          </div>
        </div>
        <div className="h-full">
          <div className="w-full xs:min-w-[1440px] h-11  bg-gray-100 flex items-center justify-between">
            {tableHeadItems?.map((d, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    d.value == "casing"
                      ? "w-[calc(100%/7)]"
                      : "w-[calc(100%/17)]"
                  } border-r  text-xs justify-center text-center h-full border-t font-medium text-gray-500 flex gap-1.5 items-center`}
                >
                  {d?.label}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col xs:min-w-[1440px] items-start w-full  h-full overflow-y-scroll  overflow-hidden scrollbar-hide">
            {lots?.map((b, i) => {
              return (
                <div
                  key={i}
                  className={`even:bg-gray-50 flex min-h-[72px] w-full h-[72px] items-center border-b rounded`}
                >
                  {tableHeadItems.map((d, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          d.value == "casing"
                            ? "w-[calc(100%/7)]"
                            : "w-[calc(100%/17)]"
                        } text-xs h-full border-r justify-center font-medium text-gray-500 flex gap-1.5 items-center`}
                      >
                        {d.value == "lotNo" ? (
                          `#${i + 1}`
                        ) : d.value == "delete" ? (
                          <button
                            onClick={() => {
                              removeLot(i);
                            }}
                            className=" w-8  h-8 flex flex-row items-center rounded justify-center bg-red-100 text-red-500"
                          >
                            <TrashIcon width={16} height={16} />
                          </button>
                        ) : d.value == "casing" ? (
                          `${b?.size}" ${b?.grade} ${b?.weight} lb/ft ${b?.typeConn}`
                        ) : b[d.value] ? (
                          b[d.value]
                        ) : (
                          "---"
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex  w-full h-[72px] items-center border-b rounded">
                <div className="text-xs w-[calc(100%/17)] h-full border-r justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                  #{lots?.length + 1}
                </div>
                <Controller
                  control={control}
                  className
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="text-xs w-[calc(100%/7)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <Select
                        className={
                          "border-solid border-y-0 border-x-0 border-b rounded border-secondary-300 mt-0.5"
                        }
                        items={casings?.map((m, i) => {
                          return { label: m?.casingName, value: m?.id };
                        })}
                        width={"90%"}
                        fontSize={12}
                        onChange={(v) => {
                          setSelectedCasing(v);
                        }}
                        value={value}
                        validate={errors?.casing ? "error" : ""}
                        errorMessage={
                          errors?.casing ? errors?.casing?.message : ""
                        }
                      />
                    </div>
                  )}
                  name="casing"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.typeConn ? "error" : ""}
                        errorMessage={
                          errors?.typeConn ? errors?.typeConn?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.typeConn
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="typeConn"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.threadCompound ? "error" : ""}
                        errorMessage={
                          errors?.threadCompound
                            ? errors?.threadCompound?.message
                            : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.threadCompound
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="threadCompound"
                />
                <span className="text-xs w-[calc(100%/17)] justify-center items-center text-center h-full border-r font-medium text-gray-500 flex gap-1.5">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div className=" text-xs w-full justify-center  h-full  font-medium text-gray-500 flex gap-1.5 items-center">
                        <input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          validate={errors?.size ? "error" : ""}
                          errorMessage={
                            errors?.size ? errors?.size?.message : ""
                          }
                          className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                            errors.size
                              ? "border-red-500"
                              : "border-secondary-300"
                          } border-b rounded  text-center`}
                        />
                      </div>
                    )}
                    name="size"
                  />
                </span>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.weight ? "error" : ""}
                        errorMessage={
                          errors?.weight ? errors?.weight?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.weight
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="weight"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.grade ? "error" : ""}
                        errorMessage={
                          errors?.grade ? errors?.grade?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.grade
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="grade"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.maxTorque ? "error" : ""}
                        errorMessage={
                          errors?.maxTorque ? errors?.maxTorque?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.maxTorque
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="maxTorque"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.optTorque ? "error" : ""}
                        errorMessage={
                          errors?.optTorque ? errors?.optTorque?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.optTorque
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="optTorque"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.minTorque ? "error" : ""}
                        errorMessage={
                          errors?.minTorque ? errors?.minTorque?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.minTorque
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="minTorque"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.maxTurn ? "error" : ""}
                        errorMessage={
                          errors?.maxTurn ? errors?.maxTurn?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.maxTurn
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="maxTurn"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.minTurn ? "error" : ""}
                        errorMessage={
                          errors?.minTurn ? errors?.minTurn?.message : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.minTurn
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="minTurn"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.highShoulder ? "error" : ""}
                        errorMessage={
                          errors?.highShoulder
                            ? errors?.highShoulder?.message
                            : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.highShoulder
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="highShoulder"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.lowShoulder ? "error" : ""}
                        errorMessage={
                          errors?.lowShoulder
                            ? errors?.lowShoulder?.message
                            : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.lowShoulder
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="lowShoulder"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className=" text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.referenceTorque ? "error" : ""}
                        errorMessage={
                          errors?.referenceTorque
                            ? errors?.referenceTorque?.message
                            : ""
                        }
                        className={`w-[80%] px-2 py-0.5 focus:outline-0 ${
                          errors.referenceTorque
                            ? "border-red-500"
                            : "border-secondary-300"
                        } border-b rounded  text-center`}
                      />
                    </div>
                  )}
                  name="referenceTorque"
                />

                <div className="text-xs w-[calc(100%/17)] justify-center  h-full border-r  font-medium text-gray-500 flex gap-1.5 items-center">
                  <button
                    type="submit"
                    onClick={() => {}}
                    className=" w-8  h-8 flex flex-row items-center rounded justify-center bg-[#D1FADF]"
                  >
                    <CheckIcon width={16} height={16} color={"#027A48"} />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end  items-center gap-6 w-full h-[40px]">
        <div className="flex items-end xs:justify-end xs:w-full gap-6 h-[40px]">
          <button
            onClick={() => setNewLot(false)}
            type="button"
            className="w-[144px] h-full bg-white flex flex-row items-center justify-center border shadow rounded-lg text-gray-700"
          >
            {t("buttons:stop")}
          </button>
          <button
            onClick={() => {
              setNewJoint(true);
            }}
            className="w-[144px] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
          >
            {t("buttons:next")}
          </button>
        </div>
      </div>
      {/* <UpdateLotModal
        modalIsOpen={updateModal}
        closeModal={() => setUpdateModal(false)}
        setUpdateModal={setUpdateModal}
        selectedLot={selectedLot}
        lots={lots}
        setLots={setLots}
        title={`${selectedLot?.size}" ${selectedLot?.grade} ${selectedLot?.weight} lb/ft ${selectedLot?.typeConn}`}
      /> */}
    </div>
  );
};

export default LotTable;

import React, { useState } from "react";
import Report from "../../screens/reportComponents/Report";
import UploadData from "../../screens/reportComponents/UploadData";
import Modal from "react-modal";
const UploadReportModal = ({
  setUploadModal,
  isOpen,
  width,
  closeModal,
  height,
  setSelectedJob,
}) => {
  const [uploaded, setUploaded] = useState(false);
  return (
    <Modal
      appElement={document.getElementById("app")}
      closeTimeoutMS={350}
      isOpen={isOpen}
      onRequestClose={() => {
        setUploadModal(false);
        setUploaded(false);
      }}
      className={`h-[820px] w-[1108px] ReactModal`}
      style={{
        overlay: {
          background: "rgba(0, 0, 0, 0.3)",
        },
        content: {
          position: "absolute",
          transform: "translate(-50%,-50%)",
          width: width,
          left: "50%",
          top: "50%",
          background: "#fff",
          height: height,
          padding: "0px",
          boxShadow:
            "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          borderRadius: "12px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "20px",
          margin: "0px",
        },
      }}
    >
      <div className="w-full h-full">
        {uploaded ? (
          <Report
            setSelectedJob={setSelectedJob}
            setUploaded={setUploaded}
            setUploadModal={setUploadModal}
          />
        ) : (
          <UploadData
            setUploaded={setUploaded}
            setUploadModal={setUploadModal}
          />
        )}
      </div>
    </Modal>
  );
};

export default UploadReportModal;

import { instance } from "../libs/client";
/* eslint-disable camelcase */

class UserService {
  getUsers = async () => {
    return instance.get("user");
  };

  getUsersPaginated = async (pageNo, search = "", type = "") => {
    return instance.get(
      `user/paginated?page=${pageNo}${search ? `&search=${search}` : ""}`
    );
  };

  addUser = async (data) => {
    return instance.post("user/", data);
  };

  updateUser = async (id, data) => {
    return instance.put(`/user/${id}`, data);
  };

  deleteUser = async (id) => {
    return instance.delete(`/user/${id}`);
  };

  listAllUsers = async () => {
    return instance.get("user/allUsers");
  };

  changePassword = async (data) => {
    return instance.post(`user/change`, data);
  };
  listNodesByUser = async () => {
    return instance.get("user/nodes");
  };
}

const userService = new UserService();

export default userService;

import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { CloseIcon } from "../../assets/icons";

const AdminModal = ({
  modalIsOpen,
  closeModal,
  children,
  height,
  title,
  width,
  i,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      appElement={document.getElementById("app")}
      closeTimeoutMS={350}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={`h-[97%] w-[600px] ReactModal`}
      style={{
        overlay: {
          background: "rgba(0, 0, 0, 0.3)",
        },
        content: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          width: width,
          background: "#fff",
          height: height,
          padding: "24px 32px 52px",
          border: "1px solid #EAECF0",
          boxShadow:
            "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          borderRadius: "16px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <div className="flex w-full items-center justify-between mb-4">
        <span className="font-semibold text-2xl text-[#101828]" key={i}>
          {title}
        </span>
        <button
          onClick={closeModal}
          className={`ml-auto p-4 rounded-lg hover:bg-slate-100`}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="w-full h-[90%] overflow-y-auto no-scrollbar border-b border-gray-200 mb-3">
        {children}
      </div>
      {/* <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
      <div className="w-full h-[90%] overflow-y-auto border-b border-gray-200 mb-3">
        {children}
      </div>
      <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
        <button
          onClick={closeModal}
          className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
        >
          {t("buttons:stop")}
        </button>
        <button
          onClick={closeModal}
          className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
        >
          {t("buttons:save")}
        </button>
      </div> */}
    </Modal>
  );
};

export default AdminModal;

import React, { useState } from "react";
import { Upload } from "../../assets/icons";
import { useStore } from "../../hooks/useStores";
import { readService } from "../../services/read.service";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
const ImportJobData = ({ setImportModal, setSelectedJob, setNewJob }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState([]);
  const { auth, report } = useStore();
  const [loading, setLoading] = useState(false);
  const JobData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", value);
    const { data } = await readService
      .readJob(formData)
      .then((res) => {
        setSelectedJob({
          jobName: res?.data?.jobData?.JobName,
          casingCompany: res?.data?.jobData?.CasingCompany,
          casingSuper: res?.data?.jobData?.CasingSupervisor,
          tongOperator: res?.data?.jobData?.TongOperator,
          threadRepCompany: res?.data?.jobData?.ThreadCompanyRepresentative,
          threadRep: res?.data?.jobData?.ThreadRep,
          squeeze: res?.data?.jobData?.Squeeze,
          companyRep: res?.data?.jobData?.CompanyRep,
          lease: res?.data?.jobData?.Lease,
          firstTech: res?.data?.jobData?.FirstTechnician,
          secondTech: res?.data?.jobData?.SecondTechnician,
          stabTechnician: res?.data?.jobData?.StabTechnician,
          rig: res?.data?.jobData?.Rig,
          well: res?.data?.jobData?.Well,
          deliveryDate: res?.data?.jobData?.Date,
          // job: res?.data?.jobData?.KuyuCapi,
          wellDepth: Number(res?.data?.jobData?.WellDepth),
          casingShoe: Number(res?.data?.jobData?.CasingShoeDepth),
          casingUp: Number(res?.data?.jobData?.CasingUstKalan),
          ratHole: res?.data?.jobData?.RatHole,
          // job: res?.data?.jobData?.PreviousCasing,
          ppcf: res?.data?.jobData?.Ppcf,
          flotation: res?.data?.jobData?.Flotation,
          lot: res?.data?.lotData?.map((l, i) => {
            return {
              grade: l?.Grade,
              highShoulder: l?.HighShoulder,
              lotNo: l?.LotNo,
              lowShoulder: l?.LowShoulder,
              maxTorque: l?.MaxTorque,
              maxTurn: l?.MaxTurn,
              minTorque: l?.MinTorque,
              minTurn: l?.MinTurn,
              optTorque: l?.OptTorque,
              referenceTorque: l?.ReferenceTorque,
              size: l?.Size,
              threadCompound: l?.ThreadCompound,
              typeConn: l?.TypeConn,
              weight: l?.Weight,
            };
          }),
          joint: res?.data?.casingData?.map((l, i) => {
            return {
              casingNo: l?.CasingNo,
              casingName: l?.CasingName,
              lotNo: l?.LotSet,
              lengthVal: l?.LengthVal,
              centralizer: l?.Centralizer ? true : false,
              wMud: l?.WMud,
            };
          }),
        });
        setNewJob(true);
        setImportModal(false);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  };
  const changeHandler = (event) => {
    setValue(event.target.files[0]);
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      {loading ? (
        <div className="min-h-[295px] w-full flex items-center justify-center">
          <div className="w-10 h-10">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center flex-col my-auto">
          {value?.name ? (
            <div className="w-full mx-5 h-[230px]  flex items-center justify-center border-[2px] border-dashed border-[#DBEEF1] flex-col ">
              <input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv, .xls, .xlsx, text/csv, application/csv,
                text/comma-separated-values, application/csv, application/excel,
                application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{
                  backgroundColor: "red",
                  display: "block",
                  margin: "10px auto ",
                  width: "95%",
                  height: "230px",
                  opacity: "0",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: "1",
                }}
              />
              <Upload />
              <p className="text-[#005F6A] mt-1 text-lg font-semibold">
                {value?.name}
              </p>
              <p className="text-[#005F6A] mt-1 text-xs "> CSV added</p>
            </div>
          ) : (
            <div className="w-full mx-5 h-[230px]  flex items-center justify-center border-[2px] border-dashed border-[#DBEEF1] flex-col ">
              <input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv, .xls, .xlsx, text/csv, application/csv,
                text/comma-separated-values, application/csv, application/excel,
                application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{
                  backgroundColor: "red",
                  display: "block",
                  margin: "10px auto ",
                  width: "95%",
                  height: "230px",
                  opacity: "0",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: "1",
                }}
              />
              <Upload />
              <p className="text-[#005F6A] mt-1 text-lg font-semibold">
                {t("csv:clickToUpload")}
              </p>
              <p className="text-[#005F6A] mt-1 text-xs ">
                {" "}
                {t("csv:csvOnly")}
              </p>
            </div>
          )}
          <div className="flex items-center justify-center gap-x-2">
            <button
              onClick={() => setImportModal(false)}
              className="bg-[#005F6A] text-white opacity-70 font-semibold py-3 w-[300px] rounded-lg mt-4 "
            >
              {t("buttons:stop")}
            </button>
            {value?.name ? (
              <button
                onClick={JobData}
                className="bg-[#005F6A] text-white font-semibold py-3 w-[300px] rounded-lg mt-4 "
              >
                {t("csv:createReport")}
              </button>
            ) : (
              <button className="bg-[#005F6A] text-white font-semibold py-3 w-[300px] rounded-lg mt-4 opacity-20 cursor-not-allowed">
                {t("csv:pleaseUploadAFile")}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportJobData;

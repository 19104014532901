import { instance } from "../libs/client";

class JobService {
  createJob = async (data) => {
    return instance.post(`job/`, data);
  };
  getJobs = async () => {
    return instance.get(`job/`);
  };
  updateJob = async (id, data) => {
    return instance.put(`job/${id}`, data);
  };
  getJSON = async (id, data) => {
    return instance.get(`job/${id}`);
  };
  deleteJob = async (id) => {
    return instance.delete(`job/${id}`);
  };
  requestTally = async (data) => {
    return instance.post(`job/request-tally`, data);
  };
}
const jobService = new JobService();
export { jobService };

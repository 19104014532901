import React, { useState, useEffect } from "react";
import { useStore } from "../../hooks/useStores";
import moment from "moment";
import JointTable from "./JointTable";
import ReportHeader from "./ReportHeader";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import UpdateJobInfo from "./UpdateJobInfo";
import AttachJobOrder from "./AttachJobOrder";
const Report = ({ setUploadModal, setUploaded, setSelectedJob }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("attachJobOrder");
  const [selectedJobOrder, setSelectedJobOrder] = useState();
  const [coverPage, setCoverPage] = useState({});
  const [secondPage, setSecondPage] = useState({});
  const { report } = useStore();
  const [joints, setJoints] = useState([]);
  useEffect(() => {
    setCoverPage({
      date: report?.reportData?.coverPageData?.date,
      lease: report?.reportData?.coverPageData?.lease,
      wellDepth: report?.reportData?.coverPageData?.wellDepth,
      jobName: report?.reportData?.coverPageData?.jobName,
      companyRepresentative:
        report?.reportData?.coverPageData?.companyRepresentative,
    });
    setSecondPage({
      companyRep: report?.reportData?.coverPageData?.companyRepresentative,
      rig: report?.reportData?.secondPageData?.rig,
      well: report?.reportData?.secondPageData?.well,
      firstTechnician: report?.reportData?.secondPageData?.firstTechnician,
      secondTechnician: report?.reportData?.secondPageData?.secondTechnician,
      casingCo: report?.reportData?.secondPageData?.casingCo,
      casingSuper: report?.reportData?.secondPageData?.casingSuper,
      // threadRep: report?.reportData?.secondPageData?.threadRep,
      tongOperator: report?.reportData?.secondPageData?.tongOperator,
      ratHole: report?.reportData?.secondPageData?.ratHole,
      ppcf: report?.reportData?.secondPageData?.ppcf,
      flotation: report?.reportData?.secondPageData?.flotation,
      casingUp: report?.reportData?.secondPageData?.casingUp,
      casingShoe: report?.reportData?.secondPageData?.casingShoe,
    });
  }, []);
  const createReport = () => {
    setSelectedJob(selectedJobOrder);
    setUploaded(false);
    setLoading(true);
    const mapper = report?.reportData?.tableData
      .map((e) => {
        return e
          .map((d) => {
            const filter = joints.filter((element) => {
              return element == d.JAMJointN;
            });
            if (filter.length > 0) {
              return d;
            }
          })
          .filter((element) => {
            return element != undefined;
          });
      })
      .filter((element) => {
        return element != undefined;
      });

    const newJob = report?.reportData?.jobData
      .map((e) => {
        const newValue = e.value
          .map((d) => {
            const filter = joints.filter((element) => {
              return element == d.tableData.JAMJointN;
            });
            if (filter.length > 0) {
              return d;
            }
          })
          .filter((element) => {
            return element != undefined;
          });
        if (newValue.length > 0) {
          return { ...e, value: newValue };
        }
      })
      .filter((element) => {
        return element != undefined;
      });
    report.loading = true;
    report.setReportData({
      company: report?.reportData?.company,
      companyEmail: report?.reportData?.companyEmail,
      companyImage: report?.reportData?.companyImage,
      coverPageData: {
        ...report?.reportData?.coverPageData,
        ...coverPage,
      },
      secondPageData: {
        ...report?.reportData?.secondPageData,
        ...secondPage,
      },
      user: report?.reportData?.user,
      jobData: newJob,
      tableData: mapper,
      gps: report?.reportData?.gps,
      jsonOut: report?.reportData?.jsonOut,
      jobOrder: selectedJobOrder?.id,
      uniqueId: report?.reportData?.uniqueId,
      jamVersion: report?.reportData?.jamVersion,
    });
    report.loading = false;
    report.chooseLocation = true;
    setLoading(false);
    setUploadModal(false);
  };
  useEffect(() => {
    const arr = report?.reportData?.tableData?.map((joi, i) => {
      return joi.map((j, i) => {
        return j.JAMJointN;
      });
    });
    setJoints(arr?.flat(1));
  }, []);
  return (
    <div>
      <div className="flex flex-col items-center  border-[1px]  min-h-[820px] h-[820px]">
        <ReportHeader
          type={"table"}
          title={moment(report?.reportData?.coverPageData?.date, "X")
            .utc()
            .format("DD/MM/YYYY")}
        />
        <div
          className={`absolute flex gap-x-6 h-[40px] top-2 right-2 ${
            selectedTab == "updateJobInfo" ? "hidden" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => {
              if (selectedTab == "attachJobOrder") {
                setUploaded(false);
                setUploadModal(false);
              }
              if (selectedTab == "updateJobInfo") {
                setSelectedTab("attachJobOrder");
              }
              if (selectedTab == "lotTable") {
                setSelectedTab("updateJobInfo");
              }
            }}
            className="w-[160px] h-full bg-white text-secondary-700 font-semibold flex flex-row items-center justify-center border rounded-lg text-sm"
          >
            {selectedTab != "attachJobOrder"
              ? t("buttons:prev")
              : t("buttons:stop")}
          </button>
          <button
            type="button"
            onClick={() => {
              if (selectedTab == "attachJobOrder") {
                setSelectedTab("updateJobInfo");
              }
              if (selectedTab == "updateJobInfo") {
                setSelectedTab("lotTable");
              }
              if (selectedTab == "lotTable") {
                createReport();
              }
            }}
            className="w-[160px] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white text-sm"
          >
            {selectedTab != "lotTable"
              ? t("buttons:next")
              : t("csv:createReport")}
          </button>
        </div>
        {selectedTab == "attachJobOrder" ? (
          <div className="w-[95%] h-[95%] overflow-y-scroll scrollbar-hide">
            <AttachJobOrder
              setSelectedTab={setSelectedTab}
              setSelectedJobOrder={setSelectedJobOrder}
              selectedJobOrder={selectedJobOrder}
            />
          </div>
        ) : selectedTab == "updateJobInfo" ? (
          <div className="w-[95%] overflow-y-scroll h-full scrollbar-hide">
            <UpdateJobInfo
              setSelectedTab={setSelectedTab}
              coverPage={coverPage}
              secondPage={secondPage}
              setCoverPage={setCoverPage}
              setSecondPage={setSecondPage}
            />
          </div>
        ) : (
          <div className="overflow-y-scroll h-full scrollbar-hide">
            {report?.reportData?.tableData?.map((items, index) => {
              return (
                <JointTable
                  setUploadModal={setUploadModal}
                  key={index}
                  item={items}
                  tableTitle={moment(
                    report?.reportData?.coverPageData?.date,
                    "X"
                  )
                    .utc()
                    .format("DD/MM/YYYY")}
                  pageNo={Number(items[0]?.pageNumber) - 2}
                  revNo={report?.reportData?.reportData?.revNo}
                  reportDate={moment().format("DD/MM/YYYY-HH:mm")}
                  companyImage={report?.reportData?.companyImage}
                  company={report?.reportData?.company}
                  user={report?.reportData?.user}
                  companyEmail={report?.reportData?.companyEmail}
                  jobData={report?.reportData?.jobData}
                  tableData={report?.reportData?.tableData}
                  joints={joints}
                  setJoints={setJoints}
                />
              );
            })}
          </div>
        )}
        <Footer
          reportDate={moment().utcOffset("+0300").format("DD/MM/YYYY-HH:mm")}
          pageNo={"0"}
        />
      </div>
    </div>
  );
};

export default Report;

import { Outlet } from "react-router-dom";

const AuthNavigation = () => {
  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center lg:flex-none ">
          <div className=" w-full max-w-sm lg:w-96 ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthNavigation;

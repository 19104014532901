import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["token", "isLogged", "user", "language"],
      storage: window.localStorage,
    });
  }

  token = {
    access_token: undefined,
  };
  language = "tr";
  user = null;
  isLogged = false;

  setLanguage(lang) {
    this.language = lang;
  }

  setLogged(status) {
    this.isLogged = status;
  }
  setUser(user) {
    this.user = user;
  }
  setToken(token) {
    this.token = token;
  }
  logOut() {
    this.token = {
      access_token: undefined,
    };
    this.user = null;
    this.isLogged = false;
  }

  get accessToken() {
    return this.token.access_token;
  }

  // async hydrateStore() {
  //   await hydrateStore(this);
  // }
}
const authStore = new AuthStore();
export { authStore };

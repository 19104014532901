import React, { useState } from "react";
import { EyeIcon } from "../../assets/icons";
const NewInput = ({
  label,
  icon,
  placeholder,
  onChange,
  onBlur,
  value,
  height,
  disabled,
  validate,
  iconPosition,
  field,
  type,
  errorMessage,
  width,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <label
        style={{ width: width }}
        className="text-gray-700 h-[70px] text-sm font-medium leading-5 flex flex-col relative"
      >
        {label}
        <div
          style={{ height: height ? height : "40px" }}
          className={`py-2.5 px-3.5 flex items-center bg-white border border-gray-300 shadow-sm rounded-lg text-base text-gray-600 focus:outline-none mt-1.5 ${
            disabled ? "bg-secondary-50 " : <></>
          }`}
        >
          {icon ? (
            type !== "password" ? (
              <span
                className={`absolute  ${
                  iconPosition === "right" ? "right-[14px]" : "left-[14px] mr-5"
                }  text-secondary-500 max-w-[20px] max-h-[20px]`}
              >
                {icon}
              </span>
            ) : (
              <></>
            )
          ) : type == "password" ? (
            <span
              className={`absolute right-[14px]  max-w-[20px] max-h-[20px] ${
                validate === "success"
                  ? "text-validate-success"
                  : validate === "warning"
                  ? "text-validate-warning"
                  : validate === "error"
                  ? "text-validate-error"
                  : "text-secondary-500"
              }`}
              onClick={() => setIsVisible(!isVisible)}
            >
              <EyeIcon />
            </span>
          ) : (
            <></>
          )}
          <input
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            onBlur={onBlur}
            disabled={disabled}
            type={
              type == "password"
                ? isVisible
                  ? "text"
                  : "password"
                : type == "number"
                ? "number"
                : "text"
            }
            rest={rest}
            className={`bg-white text-base text-gray-600 focus:outline-none`}
          />
        </div>

        {errorMessage && validate && (
          <p className="text-[10px] text-red-500 absolute -bottom-2 right-3 bg-white">
            {errorMessage || validate.toLocaleUpperCase()}
          </p>
        )}
      </label>
    </>
  );
};

export default NewInput;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LoginAvatarPng,
  LoginBackground,
  LoginImage,
  UpuLogo,
} from "../../assets/images";
import Input from "../../components/Inputs/Input";
import { BlackLogo, UpuJamlogLogo, JamlogIcon } from "../../assets/icons";
import { authService } from "../../services";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useAlert, positions } from "react-alert";
import { roles } from "../../static/constants";
const Login = () => {
  const { t } = useTranslation();
  const { auth, app } = useStore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();
  const alert = useAlert();
  const onLogin = (e) => {
    if (!email && !password) {
      setEmailError(true);
      setPasswordError(true);
    } else if (!password) {
      setPasswordError(true);
      setEmailError(false);
    } else if (!email) {
      setEmailError(true);
      setPasswordError(false);
    } else {
      e.preventDefault();
      app.setLoadingOverlay(true);
      authService
        .login({
          email,
          password,
        })
        .then(async ({ data: { user, ...rest } }) => {
          if (user == 2) {
            alert.error(t("login:expired"), {
              position: positions.TOP_LEFT,
            });
          } else if (user) {
            auth.setToken(rest);
            auth.setUser(user);
            auth.setLogged(true);
            authService.setAuthInterceptor(auth.accessToken);
            app.setLoadingOverlay(false);

            alert.success(t("login:loggedIn"), {
              position: positions.TOP_LEFT,
            });
            if (user.role === roles.CTM) {
              navigate("/dashboard/job-list");
            } else {
              navigate("/dashboard/map");
            }
          } else {
            alert.error(t("login:invalid"), {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <>
      <div className="flex w-screen overflow-hidden h-screen">
        <div className="w-[45%] md:hidden sm:hidden xs:hidden h-full bg-white">
          <div className="absolute top-7 left-8">
            <JamlogIcon />
          </div>
          <img src={LoginImage} className={"w-full h-full"} />
        </div>
        <div className="w-[55%] md:w-full xs:w-full sm:w-full h-full flex bg-white py-11 px-9 md:bg-[#005F6A] sm:bg-[#005F6A] xs:bg-[#005F6A]">
          <div className="w-full h-full bg-transparent flex flex-col justify-center gap-y-16 items-center xs:bg-white sm:bg-white md:bg-white rounded-2xl">
            <UpuJamlogLogo className="w-[358px] h-[68px] sm:w-[286px] xs:w-[216px]" />
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-y-[8px] mb-1.5">
                <p className="text-3xl text-secondary-900 font-semibold leading-8">
                  {t("auth:welcome")}
                </p>
                <p className="text-[16px] text-secondary-500 mb-1.5">
                  {t("auth:welcomeSubTitle")}
                </p>
              </div>
              <div className={"flex flex-col xs:px-6 w-[320px]"}>
                <label
                  style={{ width: "100%" }}
                  className="text-gray-700 h-[70px] text-sm font-medium leading-5 flex flex-col relative"
                >
                  {t("auth:email")}
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className={`h-11 py-2.5 px-3.5 bg-white border border-gray-300 shadow-sm rounded-lg text-base text-gray-600 focus:outline-none mt-1.5 `}
                  />
                </label>
              </div>
              <div
                className={
                  "flex flex-col xs:px-6 w-[320px] text-secondary-500 mt-2.5"
                }
              >
                <label
                  style={{ width: "100%" }}
                  className="text-gray-700 h-[70px] text-sm font-medium leading-5 flex flex-col relative"
                >
                  {t("auth:password")}
                  <input
                    type={"password"}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`h-11 py-2.5 px-3.5 bg-white border border-gray-300 shadow-sm rounded-lg text-base text-gray-600 focus:outline-none mt-1.5 `}
                  />
                </label>
              </div>

              <div className={"flex flex-col xs:px-6 w-[320px] box-border"}>
                <button
                  onClick={(e) => {
                    onLogin(e);
                  }}
                  className="w-full h-11 mt-2 bg-[#005F6A] rounded-lg text-white"
                >
                  {t("auth:login")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useState, Fragment } from "react";
import { CloseIcon, DownArrow } from "../../assets/icons";
import { Listbox, Transition } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const MultiSelect = ({
  items,
  value = [],
  onChange,
  className,
  placeholder,
  icon,
  colliderClassName = "",
  disabled,
  width,
  fontSize = 14,
}) => {
  return (
    <Listbox
      disabled={disabled}
      value={value}
      onChange={(i) => {
        onChange(value.length > 0 ? [...value, i.value] : [i.value]);
      }}
    >
      {({ open }) => (
        <div
          style={{ width: width }}
          className="min-h-[70px] w-full max-h-[70px] flex flex-col justify-between"
        >
          {placeholder && (
            <div
              style={{
                fontSize: fontSize,
              }}
              className="text-sm pl-0 text-secondary-700 font-medium"
            >
              {placeholder}
            </div>
          )}
          <div className={`relative ${colliderClassName}`}>
            <Listbox.Button
              className={`${
                open
                  ? ` ${
                      className ? " " + className : ""
                    } !rounded-t-default !rounded-b-none`
                  : `${
                      className ? className : "bg-white border"
                    } text-secondary-600`
              }     ${
                disabled ? "bg-secondary-50 " : ""
              } min-h-[44px] relative w-full px-[14px] py-[9px]  flex items-center border rounded-lg border-secondary-300 outline-secondary-300 text-secondary-500 leading-6 focus:ring-0 
    
  }`}
            >
              {icon && icon}

              <span className="flex flex-row gap-x-2 mr-2.5 overflow-x-auto scrollbar-hide">
                {items
                  ?.sort((a, b) => a - b)
                  ?.map((d) => {
                    if (value?.includes(d.value)) {
                      return (
                        <div className="flex items-center text-[14px]">
                          <CloseIcon
                            className="h-2.5 w-2.5 mr-1.5"
                            onClick={() => {
                              onChange(value.filter((i) => i !== d.value));
                            }}
                          />
                          <span>{d.label}</span>
                        </div>
                      );
                    }
                  }) || placeholder}
              </span>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <DownArrow
                  className={`h-2.5 w-2.5  ${
                    open
                      ? "rotate-180 text-secondary-600"
                      : " text-secondary-600"
                  } `}
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-10 mt-0 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none ${
                  open
                    ? "rounded-bl-lg rounded-br-lg border-gray-300 text-secondary-700 border-t-0 border-[1px]"
                    : ""
                }`}
              >
                {items &&
                  items.map((item) => {
                    if (!value?.includes(item.value))
                      return (
                        <Listbox.Option
                          key={item.value}
                          className={({ active }) =>
                            classNames(
                              item.value === value
                                ? "bg-white text-black"
                                : "text-black",
                              "cursor-pointer select-none relative hover:bg-gray-200 rounded-lg mx-1 py-2 pl-3"
                            )
                          }
                          value={item}
                        >
                          {({ active }) => (
                            <>
                              <div
                                className={classNames(
                                  item.value === value
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate , text-xs"
                                )}
                              >
                                {item.label}
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      );
                  })}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default MultiSelect;

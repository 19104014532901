import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { casingService } from "../../services/casing.service";
import { useAlert, positions } from "react-alert";
import { Loading, AlertModal, Input } from "../../components";
import { BsTrash } from "react-icons/bs";
import { useStore } from "../../hooks/useStores";
import { roles } from "../../static/constants";
const CreateNewCasing = ({
  setNewCasing,
  selectedCasing,
  handleCasings,
  setInfoModalOpen,
  setInfoModalData,
}) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const schema = yup.object({
    producerCompany: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    typeConn: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    grade: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    threadCompound: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    weight: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingSize: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    wall: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    idIn: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    driftDiameter: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    min: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    opt: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    max: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    shdlr: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    maxDelta: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    collapse: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    burst: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    jointStrenght: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    bodyYield: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    minTurn: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    maxTurn: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    referenceTorque: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });

  const {
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const all = useWatch({
    control: control,
  });
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);
  useEffect(() => {
    if (selectedCasing && selectedCasing.id) {
      setValue("producerCompany", selectedCasing?.producerCompany);
      setValue("typeConn", selectedCasing?.typeConn);
      setValue("grade", selectedCasing?.grade);
      setValue("weight", selectedCasing?.weight);
      setValue("casingSize", selectedCasing?.casingSize);
      setValue("wall", selectedCasing?.wall);
      setValue("idIn", selectedCasing?.idIn);
      setValue("driftDiameter", selectedCasing?.driftDiameter);
      setValue("min", selectedCasing?.min);
      setValue("opt", selectedCasing?.opt);
      setValue("max", selectedCasing?.max);
      setValue("shdlr", selectedCasing?.shdlr);
      setValue("maxDelta", selectedCasing?.maxDelta);
      setValue("collapse", selectedCasing?.collapse);
      setValue("burst", selectedCasing?.burst);
      setValue("jointStrenght", selectedCasing?.jointStrenght);
      setValue("bodyYield", selectedCasing?.bodyYield);
      setValue("minTurn", selectedCasing?.minTurn);
      setValue("maxTurn", selectedCasing?.maxTurn);
      setValue("referenceTorque", selectedCasing?.referenceTorque);
      setValue("threadCompound", selectedCasing?.threadCompound);
    } else {
    }
  }, [selectedCasing]);
  const deleteCasing = async () => {
    if (auth?.user?.role == roles.CA || auth?.user?.role == roles.CS) {
      setLoading(true);
      const res = await casingService
        .deleteCasing(selectedCasing.id)
        .then((res) => {
          if (res.data.code == 0) {
            handleCasings();
            setIsAlert(false);
            setNewCasing(false);
            setInfoModalData({
              success: true,
              text: `${selectedCasing?.casingSize}" ${selectedCasing?.grade} ${
                selectedCasing?.weight
              } lb/ft ${selectedCasing?.typeConn} ${t("casing:deleted")}`,
              header: selectedCasing?.casingName,
              label: "OK",
            });
            setInfoModalOpen(true);
          } else {
            setIsAlert(false);
            setNewCasing(false);
            setInfoModalData({
              success: false,
              text: t("buttons:failed"),
              header: `${selectedCasing?.casingSize}" ${selectedCasing?.grade} ${selectedCasing?.weight} lb/ft ${selectedCasing?.typeConn}`,
              label: "OK",
            });
            setInfoModalOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    } else {
      setNewCasing(false);
      setIsAlert(false);
      setInfoModalData({
        success: false,
        text: t("form:unauthorized"),
        header: `${selectedCasing?.casingSize}" ${selectedCasing?.grade} ${selectedCasing?.weight} lb/ft ${selectedCasing?.typeConn}`,
        label: "OK",
      });
      setInfoModalOpen(true);
    }
  };
  const onSubmit = async (data) => {
    if (auth?.user?.role == roles.CA || auth?.user?.role == roles.CS) {
      setLoading(true);
      if (selectedCasing && selectedCasing.id) {
        const res = await casingService
          .updateCasing(selectedCasing.id, data)
          .then((res) => {
            if (res.data.code == 0) {
              handleCasings();
              setLoading(false);
              setNewCasing(false);
              setInfoModalData({
                success: true,
                text: `${data?.casingSize}" ${data?.grade} ${
                  data?.weight
                } lb/ft ${data?.typeConn} ${t("casing:updated")}`,
                header: `${data?.casingSize}" ${data?.grade}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            } else {
              setInfoModalData({
                success: false,
                text: t("buttons:failed"),
                header: `${data?.casingSize}" ${data?.grade}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const res = await casingService
          .createCasing(data)
          .then((res) => {
            if (res.data.code == 0) {
              handleCasings();
              setLoading(false);
              setNewCasing(false);
              setInfoModalData({
                success: true,
                text: `${data?.casingSize}" ${data?.grade} ${
                  data?.weight
                } lb/ft ${data?.typeConn} ${t("casing:created")}`,
                header: `${data?.casingSize}" ${data?.grade}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            } else {
              setInfoModalData({
                success: false,
                text: t("buttons:failed"),
                header: `${data?.casingSize}" ${data?.grade}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setNewCasing(false);
      setInfoModalData({
        success: false,
        text: t("form:unauthorized"),
        header: `${data?.casingSize}" ${data?.grade}`,
        label: "OK",
      });
      setInfoModalOpen(true);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex flex-col"
    >
      <div className="flex flex-row items-center px-5 h-14 border-b-[2px] border-gray-200 w-full">
        <div className="flex items-center justify-center">
          <p className="font-medium text-xl">
            {selectedCasing
              ? `${selectedCasing?.casingSize}" ${selectedCasing?.grade} ${selectedCasing?.weight} lb/ft ${selectedCasing?.typeConn}`
              : t("casing:createNewCasing")}
          </p>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-16 h-16">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full flex-1 mt-6 px-5 overflow-y-auto scrollbar-hide">
          <div className="w-full  xs:hidden xs:min-w-full flex flex-row items-start gap-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:producerCompany")}
                  onChange={onChange}
                  onBlur={onBlur}
                  width={"32%"}
                  value={value}
                  validate={errors.producerCompany ? "error" : ""}
                  errorMessage={
                    errors.producerCompany ? errors.producerCompany.message : ""
                  }
                />
              )}
              name="producerCompany"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:casingType")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.typeConn ? "error" : ""}
                  errorMessage={errors.typeConn ? errors.typeConn.message : ""}
                />
              )}
              name="typeConn"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:casingSize")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.casingSize ? "error" : ""}
                  errorMessage={
                    errors.casingSize ? errors.casingSize.message : ""
                  }
                />
              )}
              name="casingSize"
            />
          </div>
          <div className="w-full  hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:producerCompany")}
                  onChange={onChange}
                  onBlur={onBlur}
                  width={"100%"}
                  value={value}
                  validate={errors.producerCompany ? "error" : ""}
                  errorMessage={
                    errors.producerCompany ? errors.producerCompany.message : ""
                  }
                />
              )}
              name="producerCompany"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:casingType")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.typeConn ? "error" : ""}
                  errorMessage={errors.typeConn ? errors.typeConn.message : ""}
                />
              )}
              name="typeConn"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:casingSize")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.casingSize ? "error" : ""}
                  errorMessage={
                    errors.casingSize ? errors.casingSize.message : ""
                  }
                />
              )}
              name="casingSize"
            />
          </div>

          <div className="w-full xs:hidden xs:min-w-full  flex flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:grade")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.grade ? "error" : ""}
                  errorMessage={errors.grade ? errors.grade.message : ""}
                />
              )}
              name="grade"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:weight")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.weight ? "error" : ""}
                  errorMessage={errors.weight ? errors.weight.message : ""}
                />
              )}
              name="weight"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:threadCompound")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.threadCompound ? "error" : ""}
                  errorMessage={
                    errors.threadCompound ? errors.threadCompound.message : ""
                  }
                />
              )}
              name="threadCompound"
            />
          </div>
          <div className="w-full   hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:grade")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.grade ? "error" : ""}
                  errorMessage={errors.grade ? errors.grade.message : ""}
                />
              )}
              name="grade"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:weight")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.weight ? "error" : ""}
                  errorMessage={errors.weight ? errors.weight.message : ""}
                />
              )}
              name="weight"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:threadCompound")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.threadCompound ? "error" : ""}
                  errorMessage={
                    errors.threadCompound ? errors.threadCompound.message : ""
                  }
                />
              )}
              name="threadCompound"
            />
          </div>
          <hr className="w-full h-[1px] mt-6"></hr>

          <div className="w-full xs:hidden xs:min-w-full  flex flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:wall")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.wall ? "error" : ""}
                  errorMessage={errors.wall ? errors.wall.message : ""}
                />
              )}
              name="wall"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:ID")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.id ? "error" : ""}
                  errorMessage={errors.id ? errors.id.message : ""}
                />
              )}
              name="idIn"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:driftID")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.driftDiameter ? "error" : ""}
                  errorMessage={
                    errors.driftDiameter ? errors.driftDiameter.message : ""
                  }
                />
              )}
              name="driftDiameter"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:wall")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.wall ? "error" : ""}
                  errorMessage={errors.wall ? errors.wall.message : ""}
                />
              )}
              name="wall"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:ID")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.id ? "error" : ""}
                  errorMessage={errors.id ? errors.id.message : ""}
                />
              )}
              name="idIn"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:driftID")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.driftDiameter ? "error" : ""}
                  errorMessage={
                    errors.driftDiameter ? errors.driftDiameter.message : ""
                  }
                />
              )}
              name="driftDiameter"
            />
          </div>
          <hr className="w-full h-[1px] mt-6"></hr>
          <div className="w-full xs:hidden xs:min-w-full  flex flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:minimumTorque")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.min ? "error" : ""}
                  errorMessage={errors.min ? errors.min.message : ""}
                />
              )}
              name="min"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:optimumTorque")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.opt ? "error" : ""}
                  errorMessage={errors.opt ? errors.opt.message : ""}
                />
              )}
              name="opt"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:maximumTorque")}
                  width={"32%"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.max ? "error" : ""}
                  errorMessage={errors.max ? errors.max.message : ""}
                />
              )}
              name="max"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:minimumTorque")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.min ? "error" : ""}
                  errorMessage={errors.min ? errors.min.message : ""}
                />
              )}
              name="min"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:optimumTorque")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.opt ? "error" : ""}
                  errorMessage={errors.opt ? errors.opt.message : ""}
                />
              )}
              name="opt"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:maximumTorque")}
                  width={"100%"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.max ? "error" : ""}
                  errorMessage={errors.max ? errors.max.message : ""}
                />
              )}
              name="max"
            />
          </div>

          <div className="w-full xs:hidden xs:min-w-full  flex flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:shouldersTorque")}
                  width={"32%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.shdlr ? "error" : ""}
                  errorMessage={errors.shdlr ? errors.shdlr.message : ""}
                />
              )}
              name="shdlr"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:maxDelta")}
                  width={"32%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.maxDelta ? "error" : ""}
                  errorMessage={errors.maxDelta ? errors.maxDelta.message : ""}
                />
              )}
              name="maxDelta"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:maxsTurn")}
                  width={"32%"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.maxTurn ? "error" : ""}
                  errorMessage={errors.maxTurn ? errors.maxTurn.message : ""}
                />
              )}
              name="maxTurn"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full  flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:shouldersTorque")}
                  width={"100%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.shdlr ? "error" : ""}
                  errorMessage={errors.shdlr ? errors.shdlr.message : ""}
                />
              )}
              name="shdlr"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:maxDelta")}
                  width={"100%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.maxDelta ? "error" : ""}
                  errorMessage={errors.maxDelta ? errors.maxDelta.message : ""}
                />
              )}
              name="maxDelta"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:maxsTurn")}
                  width={"100%"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.maxTurn ? "error" : ""}
                  errorMessage={errors.maxTurn ? errors.maxTurn.message : ""}
                />
              )}
              name="maxTurn"
            />
          </div>
          <div className="w-full xs:hidden xs:min-w-full  flex flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:minsTurn")}
                  width={"32%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.minTurn ? "error" : ""}
                  errorMessage={errors.minTurn ? errors.minTurn.message : ""}
                />
              )}
              name="minTurn"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:referenceTorque")}
                  width={"32%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.referenceTorque ? "error" : ""}
                  errorMessage={
                    errors.referenceTorque ? errors.referenceTorque.message : ""
                  }
                />
              )}
              name="referenceTorque"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:collapse")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.collapse ? "error" : ""}
                  errorMessage={errors.collapse ? errors.collapse.message : ""}
                />
              )}
              name="collapse"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:minsTurn")}
                  width={"100%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.minTurn ? "error" : ""}
                  errorMessage={errors.minTurn ? errors.minTurn.message : ""}
                />
              )}
              name="minTurn"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:referenceTorque")}
                  width={"100%"}
                  style={{
                    numberOfLines: 1,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors.referenceTorque ? "error" : ""}
                  errorMessage={
                    errors.referenceTorque ? errors.referenceTorque.message : ""
                  }
                />
              )}
              name="referenceTorque"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:collapse")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.collapse ? "error" : ""}
                  errorMessage={errors.collapse ? errors.collapse.message : ""}
                />
              )}
              name="collapse"
            />
          </div>

          <hr className="w-full h-[1px] mt-6"></hr>
          <div className="w-full xs:hidden xs:min-w-full  flex flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("casing:burst")}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.burst ? "error" : ""}
                  errorMessage={errors.burst ? errors.burst.message : ""}
                />
              )}
              name="burst"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={"Joint Strenght 1000 lbs"}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.jointStrenght ? "error" : ""}
                  errorMessage={
                    errors.jointStrenght ? errors.jointStrenght.message : ""
                  }
                />
              )}
              name="jointStrenght"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={"Body Yield 1000 lbs"}
                  width={"32%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.bodyYield ? "error" : ""}
                  errorMessage={
                    errors.bodyYield ? errors.bodyYield.message : ""
                  }
                />
              )}
              name="bodyYield"
            />
          </div>
          <div className="w-full  hidden xs:flex xs:flex-col xs:min-w-full  flex-row items-start gap-6 mt-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={"Burst (psi)"}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.burst ? "error" : ""}
                  errorMessage={errors.burst ? errors.burst.message : ""}
                />
              )}
              name="burst"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={"Joint Strenght 1000 lbs"}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.jointStrenght ? "error" : ""}
                  errorMessage={
                    errors.jointStrenght ? errors.jointStrenght.message : ""
                  }
                />
              )}
              name="jointStrenght"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={"Body Yield 1000 lbs"}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors.bodyYield ? "error" : ""}
                  errorMessage={
                    errors.bodyYield ? errors.bodyYield.message : ""
                  }
                />
              )}
              name="bodyYield"
            />
          </div>
        </div>
      )}

      <div className="flex flex-row justify-between items-center gap-6 w-full h-[40px] mt-4">
        <div className="flex items-end xs:items-center xs:justify-center xs:w-[100%] xs:ml-0 gap-6 h-[40px] ml-auto">
          {selectedCasing?.id && (
            <button
              onClick={() => {
                setIsAlert(true);
              }}
              type="button"
              className="w-[144px] xs:w-[42%] h-full bg-red-600 text-white flex flex-row items-center justify-center border shadow rounded-lg"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            onClick={() => {
              setNewCasing(false);
            }}
            type="button"
            className="w-[144px] xs:w-[42%] h-full bg-white flex flex-row items-center justify-center border shadow rounded-lg text-gray-700"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-[144px] xs:w-[42%] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
          >
            {selectedCasing ? t("buttons:edit") : t("buttons:add")}
          </button>
        </div>
      </div>
      <AlertModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        text={selectedCasing?.casingName + t("casing:deleteCasingSubTitle")}
        icon={<BsTrash className="text-3xl" />}
        header={"upu.jamlog"}
        label={"OK"}
        applyFunction={() => deleteCasing()}
        loading={loading}
      />
    </form>
  );
};

export default CreateNewCasing;

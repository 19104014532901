import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { CheckCircle, AlertTriangle } from "../../assets/icons/index";
const InfoModal = ({
  isOpen,
  setIsOpen,
  header = "upu.jamlog",
  text = "Success",
  label = "OK",
  isSuccess = true,
  onClick,
}) => {
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999] flex items-center"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full  w-auto  items-center justify-center sm:p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-auto xs:mt-40 sm:mt-40 transform overflow-hidden rounded-2xl bg-white p-2 xs:px-0 text-left align-middle shadow-xl transition-all">
                  <div className="overflow-auto w-full xs:w-fit max-w-[400px] max-h-[240px] min-h-[220px]">
                    <div className="flex flex-col items-center text-center justify-between gap-3 min-w-[400px] xs:min-w-fit max-w-[400px] p-3">
                      <div
                        className={`${
                          isSuccess
                            ? "w-12 h-12 bg-teal-100 p-2 rounded  text-teal-100"
                            : "w-12 h-12 bg-[#FEF0C7] p-2 rounded  text-[#DC6803]"
                        } flex items-center justify-center`}
                      >
                        {isSuccess ? (
                          <CheckCircle width={24} height={24} />
                        ) : (
                          <AlertTriangle width={24} height={24} />
                        )}
                      </div>
                      <div className="flex flex-col items-center gap-3">
                        <div className="font-medium text-[18px] text-gray-900">
                          {header}
                        </div>
                        <div className="font-normal text-[14px] text-gray-500">
                          {text}
                        </div>
                      </div>
                      <div className="w-full flex items-center justify-center font-medium text-[16px] mt-2">
                        <button
                          className={`${
                            isSuccess
                              ? "bg-teal-700 text-white"
                              : " bg-white text-secondary-700 border border-secondary-300"
                          } w-full max-w-[98%] p-2.5 rounded-lg`}
                          onClick={
                            onClick
                              ? onClick
                              : () => {
                                  setIsOpen(false);
                                }
                          }
                        >
                          {label}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default InfoModal;

import React, { useState, useEffect } from "react";
import { MdLocationPin, MdChevronRight, MdFilterList } from "react-icons/md";
import { BsPencil, BsTrash } from "react-icons/bs";
import { useStore } from "../../../hooks/useStores";
import {
  AlertModal,
  NewDatePicker,
  MenuDropdown,
  TableSearch,
  InfoModal,
} from "../../../components";
import { readService, jobService } from "../../../services";
import { useAlert, positions } from "react-alert";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { roles } from "../../../static/constants";
const DashboardFilterMenu = observer(
  ({
    reports,
    setSelectedReport,
    selectedReport,
    handleReports,
    setReports,
    tempReports,
    open,
  }) => {
    const { t } = useTranslation();
    const { app, report, auth } = useStore();
    const [searchVal, setSearchVal] = useState("");
    const [filter, setFilter] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [infoModalData, setInfoModalData] = useState({});
    const [filteredArray, setFilteredArray] = useState([]);
    const alert = useAlert();
    const filterMenu2 = ["company", "user", "name", "rig", "well"];
    const deleteReport = async () => {
      if (auth?.user?.role != roles.CTM) {
        setLoading(true);
        app.setLoadingOverlay(true);
        await readService
          .deleteReport(selectedReport.id)
          .then((res) => {
            if (res.data.code == 0) {
              app.setLoadingOverlay(false);
              alert.success(t("job:deleted"), {
                position: positions.TOP_LEFT,
              });
              handleReports();
              setIsAlert(false);
            } else {
              app.setLoadingOverlay(false);
              alert.error(t("job:failed"), {
                position: positions.TOP_LEFT,
              });
              setIsAlert(false);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setIsAlert(false);
        setInfoModalData({
          success: false,
          text: t("form:unauthorized"),
          header: selectedReport?.name,
          label: "OK",
        });
        setInfoModal(true);
      }
      setLoading(false);
    };
    const deleteJob = async (id) => {
      if (auth?.user?.role != roles.CTM) {
        setLoading(true);
        await jobService
          .deleteJob(selectedReport.id)
          .then((res) => {
            if (res.data.code == 0) {
              handleReports();
              setIsAlert(false);
              setInfoModalData({
                success: true,
                text: `${selectedReport?.jobName} ${t("equipment:deleted")}`,
                header: selectedReport?.jobName,
                label: "OK",
              });
              setInfoModal(true);
            } else {
              setIsAlert(false);
              setInfoModalData({
                success: false,
                text: `${selectedReport?.jobName} ${t("buttons:failed")}`,
                header: selectedReport?.jobName,
                label: "OK",
              });
              setInfoModal(true);
            }
          })
          .catch((err) => console.log(err));
        setLoading(false);
      } else {
        setIsAlert(false);
        setInfoModalData({
          success: false,
          text: t("form:unauthorized"),
          header: selectedReport?.jobName,
          label: "OK",
        });
        setInfoModal(true);
      }
    };
    const updateReport = () => {
      if (auth?.user?.role != roles.CTM) {
        report.setUpdateReportLocation(true);
      } else {
        setIsAlert(false);
        setInfoModalData({
          success: false,
          text: t("form:unauthorized"),
          header: selectedReport?.name,
          label: "OK",
        });
        setInfoModal(true);
      }
    };
    const handleDateFilter = (data) => {
      if (data[1] == null && filteredArray.length != tempReports.length) {
        const filterLog = filteredArray.filter((a) => {
          var date = moment(a.date).unix();
          var start = moment(data[0]).unix();
          var end = moment(data[0]).add(1, "day").unix();
          return date >= start && date <= end;
        });
        setFilteredArray(filterLog);
        setReports(filterLog);
      }
      if (
        data[0] != null &&
        data[1] != null &&
        filteredArray.length != tempReports.length
      ) {
        const filterLog = filteredArray.filter((a) => {
          var date = new Date(a.createdDate);
          return date >= data[0] && date <= data[1];
        });
        setFilteredArray(filterLog);
        setReports(searchVal == "" ? tempReports : filterLog);
      }
      if (data[0] != null && data[1] != null) {
        const filterLog = tempReports.filter((a) => {
          var date = new Date(a.createdDate);
          return date >= data[0] && date <= data[1];
        });
        setReports(filterLog);
      }
      if (data[0] == null && data[1] == null) {
        setReports(tempReports);
      }
    };

    useEffect(() => {
      if (searchVal) {
        let name;
        const mapper = filterMenu2?.map((key, i) => {
          return tempReports.filter((e) => {
            if (key == "company" || key == "user") {
              name = `${e?.[key]?.name} ${e?.[key.name]}`;
            } else {
              name = `${e?.[key]} ${e?.[key]}`;
            }
            return name
              .replace(/^\./g, "")
              .toLowerCase()
              .includes(searchVal.toLowerCase());
          });
        });
        setReports([...new Set(mapper.flat())]);
        setFilteredArray([...new Set(mapper.flat())]);
      } else {
        setReports(tempReports);
        setFilteredArray(tempReports);
      }
    }, [searchVal, setSearchVal]);

    return (
      <div
        style={{
          borderRight: "2px solid #EAECF080",
          background: "rgba(255, 255, 255, 0.5)",
          borderRight: " 2px solid rgba(234, 236, 240, 0.5)",
          backdropFilter: "blur(12px)",
        }}
        className={` ${
          open ? "w-[300px]" : "hidden"
        } h-full  z-10  p-4 flex flex-col , 
      `}
      >
        <div className="w-full flex flex-col items-start">
          <h3 className="text-[#003E46] text-[18px] font-semibold w-full border-b-[1px] border-[##D0D5DD] pb-2">
            {t("map:rigs")}
          </h3>

          <div className="flex items-center gap-x-1 my-2 w-full min-w-full ">
            <TableSearch
              setSearchVal={setSearchVal}
              height={35}
              placeholder={t("auth:search")}
            />
            <div
              onClick={() => {
                setFilter(!filter);
              }}
              className="p-2 border-[1px] bg-white rounded-lg"
            >
              <MdFilterList />
            </div>
          </div>
          {filter && (
            <div className="w-full flex flex-row">
              <NewDatePicker
                onChange={(v) => {
                  handleDateFilter(v);
                }}
                marginBottom={0}
                rangePicker={true}
                placeholder={t("job:dateRecord")}
                width={"100%"}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col items-center gap-y-2 w-full h-full overflow-y-scroll scrollbar-hide mt-[16px]">
          {reports?.map((rep, i) => {
            return (
              <div
                key={i}
                onClick={() => setSelectedReport(rep)}
                className={`w-full flex flex-col px-4 py-2 rounded relative  ${
                  selectedReport?.id == rep?.id
                    ? "bg-[#003E46] even:bg-[#004D56] text-white"
                    : "bg-secondary-100 border border-secondary-200 opacity-90 text-secondary-600"
                } 
              h-[100px] min-h-[95px] gap-y-0.5`}
              >
                <div
                  className={`absolute right-2 top-2 text-xs font-semibold ${
                    selectedReport?.id == rep?.id
                      ? "text-white"
                      : rep.status == "active"
                      ? "text-[#F79009]"
                      : "text-[#039855]"
                  }`}
                >
                  {rep.status == "active"
                    ? `${t("map:active")}`
                    : `${t("map:completed")}`}
                </div>
                <div className="flex justify-between flex-col">
                  <p className="text-xs font-semibold max-w-[84%] overflow-hidden">
                    {rep?.name ? rep?.name : rep?.jobName}
                  </p>
                </div>
                <p className=" text-xs">{rep?.rig + "  " + rep?.well}</p>
                <p className=" text-[10px] font-regular">
                  {rep?.createdDate
                    ? moment(rep?.createdDate).format("DD/MM/YYYY")
                    : moment(rep?.deliveryDate).format("DD/MM/YYYY")}
                </p>
                <div className="flex items-center justify-between ">
                  <div className="flex items-center">
                    <MdLocationPin />
                    <p className=" text-xs">
                      {rep?.location} / {"Turkey"}
                    </p>
                  </div>
                  <div
                    className={`rounded cursor-pointer ${
                      selectedReport?.id == rep?.id
                        ? "hover:bg-teal-700"
                        : "hover:bg-secondary-200"
                    }`}
                  >
                    <MenuDropdown
                      options={[
                        {
                          label: (
                            <a
                              className="cursor-pointer"
                              onClick={(e) => {
                                if (rep.status == "active") {
                                  // setInfoModalData({
                                  //   success: false,
                                  //   text: t("map:onProgress"),
                                  //   header: selectedReport?.jobName,
                                  //   label: "OK",
                                  // });
                                  // setInfoModal(true);
                                } else {
                                  e.preventDefault();
                                  window.open(
                                    `https://cdn.upu.io/${rep?.s3Key}`
                                  );
                                }
                              }}
                            >
                              <div
                                className={`rounded-lg flex items-center justify-between gap-x-3 ${
                                  rep.status == "active" && "text-secondary-300"
                                }`}
                              >
                                {t("buttons:go")}
                                <MdChevronRight
                                  color={`${
                                    rep.status == "active" ? "" : "#003E46"
                                  }`}
                                />
                              </div>
                            </a>
                          ),
                        },
                        {
                          label: (
                            <div
                              onClick={() => {
                                setIsAlert(true);
                              }}
                              className="rounded-lg flex items-center justify-between gap-x-3"
                            >
                              {t("buttons:delete")}
                              <BsTrash color="#003E46" />
                            </div>
                          ),
                        },
                        {
                          label: (
                            <div
                              onClick={() => {
                                if (rep.status == "active") {
                                  // setInfoModalData({
                                  //   success: false,
                                  //   text: t("map:goJobs"),
                                  //   header: selectedReport?.jobName,
                                  //   label: "OK",
                                  // });
                                  // setInfoModal(true);
                                } else {
                                  updateReport();
                                }
                              }}
                              className={`rounded-lg flex items-center justify-between gap-x-3 ${
                                rep.status == "active" && "text-secondary-300"
                              }`}
                            >
                              {t("form:edit")}
                              <BsPencil
                                color={`${
                                  rep.status == "active" ? "" : "#003E46"
                                }`}
                              />
                            </div>
                          ),
                        },
                        // {
                        //   label: (
                        //     <a
                        //       className="cursor-pointer"
                        //       target={"_blank"}
                        //       href={`https://cdn.upu.io/${rep?.jobJsonKey}`}
                        //     >
                        //       <div className="rounded-lg flex items-center justify-between gap-x-3">
                        //         {t("buttons:downloads")}
                        //         <MdChevronRight
                        //           className="rotate-90"
                        //           color="#003E46"
                        //         />
                        //       </div>
                        //     </a>
                        //   ),
                        // },
                      ]}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <AlertModal
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          text={`${
            selectedReport?.name
              ? selectedReport?.name
              : selectedReport?.jobName
          } ${t("dashboard:deleteReportSubTitle")}`}
          icon={<BsTrash className="text-3xl" />}
          header={"upu.jamlog"}
          label={"OK"}
          applyFunction={() => {
            if (selectedReport.status == "active") {
              deleteJob();
            } else {
              deleteReport();
            }
          }}
          loading={loading}
        />
        <InfoModal
          isOpen={infoModal}
          setIsOpen={setInfoModal}
          text={infoModalData?.text}
          header={infoModalData?.header}
          label={infoModalData?.label}
          isSuccess={infoModalData?.success}
        />
      </div>
    );
  }
);

export default DashboardFilterMenu;

import { instance } from "../libs/client";

class ReadService {
  readFile = async (data) => {
    return instance.post(`read`, data);
  };
  readJob = async (data) => {
    return instance.post(`read/job`, data);
  };
  saveFile = async (data) => {
    return instance.post(`read/save`, data, { timeout: 300000 });
  };
  getReports = async () => {
    return instance.get(`read/`);
  };
  getCustomerReports = async () => {
    return instance.get(`read/customer/`);
  };
  updateReport = async (id, data) => {
    return instance.put(`read/${id}`, data);
  };
  deleteReport = async (id) => {
    return instance.delete(`read/${id}`);
  };
  getReportsPaginated = async (
    pageNo,
    search = "",
    type = "",
    start = "",
    end = ""
  ) => {
    return instance.get(
      `read/paginated?page=${pageNo}${search ? `&search=${search}` : ""}${
        start ? `&start=${start}` : ""
      }${end ? `&end=${end}` : ""}`
    );
  };
}
const readService = new ReadService();
export { readService };

import { makeAutoObservable } from "mobx";

class EquipmentStore {
  constructor() {
    makeAutoObservable(this);
  }
  equipmentList = [];
  setEquipmentList = (data) => {
    this.equipmentList = data;
  };
}
const equipmentStore = new EquipmentStore();
export { equipmentStore };

import React, { useState, useEffect } from "react";
import { PlusIcon, JsonIcon } from "../../assets/icons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { readService } from "../../services";
import moment from "moment";
import { GrLanguage } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/useStores";
import {
  PaginationComponent,
  Loading,
  TableSearch,
  ChangeLanguage,
  NoData,
} from "../../components";
import { roles } from "../../static/constants";
const ReportList = () => {
  const navigate = useNavigate();
  const { auth } = useStore();
  const [searchVal, setSearchVal] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  const [select, setSelect] = useState("all");
  const [date, setDate] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const handleReportsPage = async () => {
    setLoading(true);
    await readService
      .getReportsPaginated(pageNo, searchVal, date[0], date[1])
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if (res.data.meta.pageCount < pageNo) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  useEffect(() => {
    handleReportsPage();
  }, [pageNo, searchVal, select, date]);
  return (
    <>
      {auth.user.role == roles.CA || auth.user.role == roles.CS ? (
        <div className="w-full h-full flex flex-col relative">
          <div className="w-full h-14 flex items-center justify-between px-3 border-b-2 border-secondary-200">
            <div className="flex items-center justify-center gap-x-1">
              <span className="font-bold text-xl text-black">
                {t("dashboard:reports")}
              </span>
              <span className="font-bold text-xl text-[#005F6A]">
                {t("equipment:list")}
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <div>
                <button
                  onClick={() => {
                    navigate("/dashboard/map");
                  }}
                  className="w-[150px] xs:w-[160px] xs:h-8 h-10 rounded-lg bg-[#00727F] hover:border border-teal-500 flex items-center justify-center gap-2 p-2 text-sm xs:text-xs font-medium text-white"
                >
                  <PlusIcon color="#FFFFFF" />
                  {t("job:uploadReport")}
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setVisibleLanguage(!visibleLanguage);
                  }}
                  style={{
                    alignItems: "center",
                    right: match ? 0 : -25,
                    position: "",
                    cursor: "pointer",
                    justifyContent: "center",
                    borderRigth: "0px solid black",
                  }}
                  className="bg-[#FFFFFF] select-none rounded-md w-10 h-10 xs:w-9 xs:h-9 text-white flex border"
                >
                  <GrLanguage color="#344054" />
                </button>
              </div>
              {visibleLanguage && (
                <ChangeLanguage
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  setVisibleLanguage={setVisibleLanguage}
                />
              )}
            </div>
          </div>
          <div className="w-full flex flex-1 overflow-y-auto  scrollbar-hide flex-col border border-gray-200 shadow rounded-lg mt-6 overflow-hidden">
            <div className="w-full h-[73px] min-h-[73px] flex items-center px-6 xs:px-2 border-b border-gray-200">
              <span className="h-[22px] px-2 flex items-center justify-center bg-teal-50 rounded-2xl text-xs font-medium text-teal-700">
                {totalCount} {t("dashboard:reports")}
              </span>
              <div className="flex flex-row gap-4 ml-auto relative ">
                <div className="w-[200px] xs:w-[180px] ml-auto">
                  <TableSearch
                    setSearchVal={setSearchVal}
                    height={44}
                    placeholder={t("auth:search")}
                  />
                </div>
              </div>
            </div>
            <div className="xs:overflow-x-auto h-full">
              <div className="xs:min-w-[1000px] w-full h-full">
                <div className="w-full h-11 min-h-[44px]  flex bg-gray-50  border-b border-gray-200">
                  <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                    <p className="text-xs font-medium text-gray-500">
                      {t("dashboard:reportName")}
                    </p>
                  </div>
                  <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                    <p className="text-xs font-medium text-gray-500">
                      {t("dashboard:rig")}
                    </p>
                  </div>
                  <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                    <p className="text-xs font-medium xs:text-center text-gray-500">
                      {t("dashboard:well")}
                    </p>
                  </div>
                  <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                    <p className="text-xs font-medium text-gray-500">
                      {t("job:dateRecord")}
                    </p>
                  </div>
                  <div className="grow w-[calc(100%/15)] h-full flex items-center justify-center gap-2 border-gray-200"></div>
                </div>
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <div className="w-12 h-12">
                      <Loading />
                    </div>
                  </div>
                ) : totalCount <= 0 ? (
                  <div className="flex items-center justify-center h-full">
                    <NoData
                      header={t("noData:header")}
                      text={t("noData:logText")}
                    />
                  </div>
                ) : (
                  pageData
                    .sort((a, b) => {
                      return (
                        moment(b?.date).format("X") -
                        moment(a?.date).format("X")
                      );
                    })
                    .map((data, index) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(`https://cdn.upu.io/${data?.s3Key}`);
                          }}
                          key={index}
                          className="w-full h-[66px] min-h-[66px] border-b border-gray-200 flex items-center"
                        >
                          <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {data?.name}
                            </p>
                          </div>
                          <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {data?.rig}
                            </p>
                          </div>
                          <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {data?.well}
                            </p>
                          </div>
                          <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {moment(data?.createdDate).format(
                                "DD/MM/YYYY-HH:mm:ss"
                              )}
                            </p>
                          </div>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(`https://cdn.upu.io/${data?.s3Key}`);
                            }}
                            className="grow w-[calc(100%/15)] h-full flex items-center justify-center gap-2 border-gray-200"
                          >
                            <JsonIcon className="w-6 h-6 cursor-pointer" />
                          </div>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
          </div>
          <PaginationComponent
            pageNo={pageNo}
            setPageNo={setPageNo}
            pageCount={pageCount}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ReportList;

import { makeAutoObservable } from "mobx";

class ReportStore {
  constructor() {
    makeAutoObservable(this);
  }

  chooseLocation = false;
  loading = false;
  updateReportLocation = false;
  reportData = [];

  setLoading = (status) => {
    this.loading = status;
  };

  setReportData = (data) => {
    this.reportData = data;
  };
  setChooseLocation = (status) => {
    this.chooseLocation = status;
  };
  setUpdateReportLocation = (status) => {
    this.updateReportLocation = status;
  };
}
const reportStore = new ReportStore();
export { reportStore };

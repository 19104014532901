import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { jobService } from "../../services";
import moment from "moment";
import { Loading, NoData } from "../../components";

const AttachJobOrder = ({
  setSelectedJobOrder,
  selectedJobOrder,
  setSelectedTab,
}) => {
  const { t } = useTranslation();
  const [jobs, setJobs] = useState([]);
  const [tempJobs, setTempJobs] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const handleJobs = async () => {
    setLoading(true);
    await jobService
      .getJobs()
      .then((res) => {
        setJobs(
          res.data.filter((j, i) => {
            return j.status == "active";
          })
        );
        setTempJobs(
          res.data.filter((j, i) => {
            return j.status == "active";
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (searchVal) {
      const filterJobs = tempJobs.filter((e) => {
        let name = `${e?.jobName}`;
        return name
          .replace(/^\./g, "")
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setJobs(filterJobs);
    } else {
      setJobs(tempJobs);
    }
  }, [searchVal, setSearchVal]);
  useEffect(() => {
    handleJobs();
  }, []);
  return (
    <>
      <div className="w-full h-[95%] flex flex-col relative">
        <div className="w-full flex flex-1 overflow-y-auto  scrollbar-hide flex-col border border-gray-200 shadow rounded-lg mt-6 overflow-hidden">
          <div className="xs:min-w-[1000px] w-full h-full">
            <div className="w-full h-11 min-h-[44px]  flex bg-gray-50  border-b border-gray-200">
              <div className="grow w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-gray-200">
                <p className="text-xs font-medium text-gray-500">
                  {t("job:jobNo")}
                </p>
              </div>
              <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                <p className="text-xs font-medium text-gray-500">
                  {t("job:jobName")}
                </p>
              </div>
              <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                <p className="text-xs font-medium text-gray-500">
                  {t("job:lease")}
                </p>
              </div>
              <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                <p className="text-xs font-medium xs:text-center text-gray-500">
                  {t("job:rig")}
                </p>
              </div>
              <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                <p className="text-xs font-medium text-gray-500">
                  {t("job:well")}
                </p>
              </div>
              <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                <p className="text-xs font-medium text-gray-500">
                  {t("job:dateRecord")}
                </p>
              </div>
              <div className="grow w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-gray-200"></div>
            </div>
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="w-12 h-12">
                  <Loading />
                </div>
              </div>
            ) : (
              <>
                {jobs?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedJobOrder(data)}
                      className="w-full h-[66px] min-h-[66px] border-b border-gray-200 flex items-center"
                    >
                      <div className="grow w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <p className="text-sm font-medium text-gray-500">
                          #{index + 1}
                        </p>
                      </div>
                      <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <p className="text-sm font-medium text-gray-500">
                          {data?.jobName}
                        </p>
                      </div>
                      <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <p className="text-sm font-medium text-gray-500">
                          {data?.lease}
                        </p>
                      </div>
                      <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <p className="text-sm font-medium xs:text-center text-gray-500">
                          {data?.rig}
                        </p>
                      </div>
                      <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <p className="text-sm font-medium text-gray-500">
                          {data?.well}
                        </p>
                      </div>
                      <div className="grow w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <p className="text-sm font-medium text-gray-500">
                          {moment(data?.deliveryDate).format("DD/MM/YYYY")}
                        </p>
                      </div>
                      <div className="grow w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-gray-200">
                        <input
                          checked={selectedJobOrder?.id == data?.id}
                          type="radio"
                          className="w-5 h-5 rounded-full border-secondary-200 accent-teal-600 checked:bg-teal-500"
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="w-full h-[66px] min-h-[66px] border-b border-gray-200 flex items-center">
                  <div
                    onClick={() => {
                      setSelectedTab("updateJobInfo");
                      setSelectedJobOrder(null);
                    }}
                    className="cursor-pointer grow w-full h-full flex items-center justify-center gap-2 border-gray-200"
                  >
                    <p className="text-base font-medium text-[#344054]">
                      {t("map:manuelJob")}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AttachJobOrder;

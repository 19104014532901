import React, { useState, useEffect } from "react";
import { Language } from "../../assets/icons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { logService } from "../../services";
import i18n from "../../services/i18n";
import moment from "moment";
import { MdFilterList } from "react-icons/md";
import { FiArrowDown } from "react-icons/fi";
import { GrLanguage } from "react-icons/gr";
import { roles } from "../../static/constants";
import { useStore } from "../../hooks/useStores";
import {
  PaginationComponent,
  Loading,
  ModalTemplate,
  ExpandableFilterModal,
  Select,
  NewDatePicker,
  TableSearch,
  ChangeLanguage,
  NoData,
} from "../../components";
const LogList = () => {
  const { auth } = useStore();
  const [searchVal, setSearchVal] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  const [filter, setFilter] = useState(false);
  const [select, setSelect] = useState("all");
  const [date, setDate] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const handleLogsPage = async () => {
    setLoading(true);
    await logService
      .getRecordsPaginated(pageNo, searchVal, select, date[0], date[1])
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if (res.data.meta.pageCount < pageNo) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  const handleDateFilter = (data) => {
    if (data[0] && data[1]) {
      setDate([
        moment(data[0]).format("MM/DD/YYYY"),
        moment(data[1]).format("MM/DD/YYYY"),
      ]);
    }
    if (!data[0] && !data[1]) {
      setDate([]);
    }
  };
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  useEffect(() => {
    handleLogsPage();
  }, [pageNo, searchVal, select, date]);
  return (
    <>
      {auth.user.role == roles.CA || auth.user.role == roles.CS ? (
        <div className="w-full h-full flex flex-col relative">
          <div className="w-full h-14 flex items-center justify-between px-3 border-b-2 border-secondary-200">
            <div className="flex items-center justify-center gap-x-1">
              <span className="font-bold text-xl text-black">
                {t("job:log")}
              </span>
              <span className="font-bold text-xl text-[#005F6A]">
                {t("job:records")}
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <div>
                <button
                  onClick={() => {
                    setVisibleLanguage(!visibleLanguage);
                  }}
                  style={{
                    alignItems: "center",
                    right: match ? 0 : -25,
                    position: "",
                    cursor: "pointer",
                    justifyContent: "center",
                    borderRigth: "0px solid black",
                  }}
                  className="bg-[#FFFFFF] select-none rounded-md w-10 h-10 xs:w-9 xs:h-9 text-white flex border"
                >
                  <GrLanguage color="#344054" />
                </button>
              </div>
              {visibleLanguage && (
                <ChangeLanguage
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  setVisibleLanguage={setVisibleLanguage}
                />
              )}
            </div>
          </div>
          <div className="w-full flex flex-1 overflow-y-auto  scrollbar-hide flex-col border border-gray-200 shadow rounded-lg mt-6 overflow-hidden">
            <div className="w-full h-[73px] min-h-[73px] flex items-center px-6 xs:px-2 border-b border-gray-200">
              <span className="h-[22px] px-2 flex items-center justify-center bg-teal-50 rounded-2xl text-xs font-medium text-teal-700">
                {totalCount} {t("job:record")}
              </span>
              <div className="flex flex-row gap-4 ml-auto relative ">
                <div
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="p-2 border-[1px] bg-white rounded-lg mt-2 cursor-pointer"
                >
                  <MdFilterList />
                </div>
                <div className="w-[200px] xs:w-[180px] ml-auto">
                  <TableSearch
                    setSearchVal={setSearchVal}
                    height={44}
                    placeholder={t("auth:search")}
                  />
                </div>
              </div>
            </div>

            {filter && (
              <div
                style={{
                  background: "white",
                }}
                className="w-full flex flex-row gap-4  px-4 py-4  "
              >
                <span className="w-[200px]">
                  <Select
                    className={"h-[36px]"}
                    onChange={(d) => {
                      setSelect(d);
                    }}
                    value={select}
                    items={[
                      { label: "All", value: "all" },
                      { label: "Login", value: "login" },
                      { label: "Logout", value: "logout" },
                      { label: "Create report", value: "create_report" },
                      { label: "Csv upload", value: "csv_upload" },
                      { label: "Import Tally", value: "import_tally" },
                      { label: "Delete Tally", value: "delete_tally" },
                      { label: "Download json", value: "download_json" },
                      { label: "Update Tally", value: "update_tally" },
                      { label: "Create Tally", value: "create_tally" },
                      { label: "Delete Casing", value: "delete_casing" },
                      { label: "Update Casing", value: "update_casing" },
                      { label: "Create Casing", value: "create_casing" },
                      { label: "Delete Equipment", value: "delete_equipment" },
                      { label: "Update Equipment", value: "update_equipment" },
                      { label: "Create Equipment", value: "create_equipment" },
                    ]}
                    placeholder={t("job:type")}
                  />
                </span>
                <span className="w-[300px] mt-0.5">
                  <NewDatePicker
                    onChange={(v) => {
                      handleDateFilter(v);
                    }}
                    label={t("job:dateRecord")}
                    rangePicker={true}
                  />
                </span>
              </div>
            )}
            <div className="xs:overflow-x-auto h-full">
              <div className="xs:min-w-[1000px] w-full h-full">
                <div className="w-full h-11 min-h-[44px]  flex bg-gray-50  border-b border-gray-200">
                  <div className="grow w-[calc(100%/90)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="text-xs font-medium text-gray-500">
                      {t("job:record")}
                    </p>
                    <FiArrowDown color="#667085" />
                  </div>
                  <div className="w-[calc(100%/6)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="text-xs font-medium text-gray-500">
                      {t("job:type")}
                    </p>
                    <FiArrowDown color="#667085" />
                  </div>
                  <div className="w-[calc(100%/6)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="text-xs font-medium xs:text-center text-gray-500">
                      {t("job:ipAddress")}
                    </p>
                    <FiArrowDown color="#667085" />
                  </div>
                  <div className="w-[calc(100%/6)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="text-xs font-medium text-gray-500">
                      {t("job:dateRecord")}
                    </p>
                  </div>
                </div>
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <div className="w-12 h-12">
                      <Loading />
                    </div>
                  </div>
                ) : totalCount <= 0 ? (
                  <div className="flex items-center justify-center h-full">
                    <NoData
                      header={t("noData:header")}
                      text={t("noData:logText")}
                    />
                  </div>
                ) : (
                  pageData
                    .sort((a, b) => {
                      return (
                        moment(b?.date).format("X") -
                        moment(a?.date).format("X")
                      );
                    })
                    .map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full h-[66px] min-h-[66px] border-b border-gray-200 hover:bg-gray-50 flex items-center"
                        >
                          <div className="grow w-[calc(100%/90)] h-full flex items-center pl-5 gap-2 border-r border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {data?.[i18n.language]}
                            </p>
                          </div>
                          <div className="w-[calc(100%/6)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {data?.type}
                            </p>
                          </div>
                          <div className="w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {data?.ipAddress}
                            </p>
                          </div>
                          <div className="w-[calc(100%/6)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                            <p className="md:text-[10px] text-xs font-medium text-gray-500">
                              {moment(data?.date).format("DD/MM/YYYY-HH:mm:ss")}
                            </p>
                          </div>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
          </div>
          <PaginationComponent
            pageNo={pageNo}
            setPageNo={setPageNo}
            pageCount={pageCount}
          />
          {/* <ModalTemplate
          isOpen={filterModal}
          setIsOpen={setFilterModal}
          children={<ExpandableFilterModal setFilterModal={setFilterModal} />}
          modalTitle={"Filter"}
        /> */}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LogList;

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useStore } from "../../hooks/useStores";
import { authService } from "../../services";

const PrivateRoute = observer(({ children }) => {
  const [loading, setLoading] = useState(true);
  const { auth } = useStore();

  useEffect(() => {
    if (auth.user && auth.isLogged && auth.token.access_token) {
      authService.setAuthInterceptor(auth.token.access_token);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <div />;
  if (!auth.isLogged || !auth.token.access_token || !auth.user)
    return <Navigate to="/auth/login" />;
  else return children;
});

export default PrivateRoute;

import { instance } from "../libs/client";

class LogService {
  getRecords = async () => {
    return instance.get(`log/`);
  };
  getRecordsPaginated = async (
    pageNo,
    search = "",
    type = "",
    start = "",
    end = ""
  ) => {
    return instance.get(
      `log/paginated?page=${pageNo}${search ? `&search=${search}` : ""}${
        type != "all" ? `&type=${type}` : ""
      }${start ? `&start=${start}` : ""}${end ? `&end=${end}` : ""}`
    );
  };
}
const logService = new LogService();
export { logService };

import { instance } from "../libs/client";

class CasingService {
  getCasings = async () => {
    return instance.get(`casing/`);
  };
  createCasing = async (data) => {
    return instance.post(`casing/`, data);
  };
  updateCasing = async (id, data) => {
    return instance.put(`casing/${id}`, data);
  };
  deleteCasing = async (id) => {
    return instance.delete(`casing/${id}`);
  };
  getCasingsPaginated = async (pageNo, search = "") => {
    return instance.get(
      `casing/paginated?page=${pageNo}${search ? `&search=${search}` : ""}`
    );
  };
}
const casingService = new CasingService();
export { casingService };

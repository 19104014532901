import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import {
  UpuLogo,
  MenuIcon,
  JamlogIcon,
  SettingsIcon,
} from "../../assets/icons";
import { LoginIcon } from "@heroicons/react/outline";
import { useStore } from "../../hooks/useStores";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../utils/generateFileUrl";
import { authService } from "../../services";
import { roles } from "../../static/constants";
const DashboardNavigation = observer(({ routes }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { auth } = useStore();
  const navigate = useNavigate();
  const logout = async () => {
    await authService.logout(auth.user);
    auth?.logOut();
    navigate("/auth/login");
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="h-screen w-screen flex test xs:hidden">
        {/* Static sidebar for desktop */}
        <div className="flex flex-shrink-0 no-scrollbar xs:hidden">
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`flex flex-col top-0 bottom-0 transition-all no-scrollbar ${
              isHovered ? "w-[260px]" : "w-20"
            }`}
          >
            <div className="flex-1 flex flex-col min-h-0 overflow-y-auto no-scrollbar bg-[#005F6A]">
              <div className="flex-1">
                {auth.user.role !== roles.CTM ? (
                  <NavLink
                    to="/dashboard/map"
                    className={`bg-[#005F6A] items-center mt-8 gap-2 flex group  ${
                      !isHovered
                        ? "items-center justify-center"
                        : "pl-4 items-center ml-3"
                    }`}
                  >
                    <JamlogIcon className="w-10 h-10" />
                    {isHovered && (
                      <label className="text-bold font-bold text-2xl -mb-2 text-white cursor-pointer">
                        upu.jamlog
                      </label>
                    )}
                  </NavLink>
                ) : (
                  <NavLink
                    to="/dashboard/job-list"
                    className={`bg-[#005F6A] items-center mt-8 gap-2 flex group  ${
                      !isHovered
                        ? "items-center justify-center"
                        : "pl-4 items-center ml-3"
                    }`}
                  >
                    <JamlogIcon className="w-10 h-10" />
                    {isHovered && (
                      <label className="text-bold font-bold text-2xl -mb-2 text-white cursor-pointer">
                        upu.jamlog
                      </label>
                    )}
                  </NavLink>
                )}
                <nav
                  aria-label="Sidebar"
                  className={`py-6 mx-2 flex flex-col gap-2 mt-3 ${
                    !isHovered ? "items-center" : "pl-1.5 pr-1.5"
                  } space-y-1`}
                >
                  {routes.map((item, i) => {
                    if (item.isVisible) {
                      if (item.roles.includes(auth.user.role)) {
                        return (
                          <NavLink
                            key={i}
                            to={item.path}
                            end
                            className={(isActive) => {
                              return `flex items-center py-3 px-3 rounded-lg text-white hover:bg-[#00727F] whitespace-nowrap gap-2 ${
                                isActive?.isActive && "bg-[#00727F]"
                              }`;
                            }}
                            style={{ display: item.display }}
                          >
                            <item.icon />
                            {isHovered && (
                              <span className="ml-1 text-white text-base whitespace-nowrap font-light">
                                {t(`auth:${item.name}`)}
                              </span>
                            )}
                          </NavLink>
                        );
                      }
                    }
                  })}
                </nav>
              </div>
              <nav
                aria-label="Sidebar"
                className={`py-6 mx-2 flex flex-col gap-2 mt-3 ${
                  !isHovered ? "items-center" : "pl-1.5 pr-1.5"
                } space-y-1`}
              >
                <NavLink
                  to={"change-password"}
                  end
                  className={(isActive) => {
                    return `mt-auto flex items-center py-3 px-3 rounded-lg text-white hover:bg-[#00727F] whitespace-nowrap gap-2 ${
                      isActive?.isActive && "bg-[#00727F]"
                    }`;
                  }}
                >
                  <SettingsIcon />
                  {isHovered && (
                    <span className="ml-1 text-white text-base whitespace-nowrap font-light">
                      {t("auth:settings")}
                    </span>
                  )}
                </NavLink>
              </nav>

              <div
                className={`flex pb-5 w-full justify-evenly items-center select-none ${
                  isHovered && "justify-start gap-3"
                }`}
              >
                {isHovered ? (
                  <>
                    <img
                      src={generateFileUrl(auth?.user?.company?.image)}
                      className={
                        "w-10 h-10 rounded-full border-2 bg-white border-secondary-200"
                      }
                      alt=""
                    />
                    <p
                      onClick={() => {
                        navigate("/dashboard/change-password");
                      }}
                      className={`text-sm w-1/2 text-white whitespace-nowrap text-left cursor-pointer truncate`}
                    >
                      {auth?.user?.name} {auth?.user?.lastName}
                    </p>
                    <span
                      to="/auth/login"
                      onClick={() => logout()}
                      className={`w-10 h-10 rounded-full bg-teal-50 cursor-pointer flex items-center justify-center `}
                    >
                      <LoginIcon
                        width={22}
                        height={22}
                        className={`text-teal-700 ${isHovered && "w-5 h-5"}`}
                      />
                    </span>
                  </>
                ) : (
                  <img
                    src={generateFileUrl(auth?.user?.company?.image)}
                    className={
                      "w-10 h-10 rounded-full border-2 bg-white border-secondary-200"
                    }
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1  min-w-0 flex flex-col overflow-hidden">
          <main className="flex-1 flex overflow-hidden">
            <section
              aria-labelledby="primary-heading"
              className="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last bg-[#005F6A]"
            >
              <h1 id="primary-heading" className="sr-only">
                Account
              </h1>
              <div className="w-full h-[98.5vh] bg-white flex bottom-0 mt-auto rounded-tl-[40px] p-4">
                <Outlet />
              </div>
            </section>
          </main>
        </div>
      </div>

      {/* mobile wiew */}

      <div className="w-screen h-screen flex-col hidden xs:flex">
        <div className="w-full h-16 bg-[#005F6A] flex justify-between px-4 items-center">
          <NavLink
            to="/dashboard/map"
            className={`bg-[#005F6A] items-center flex gap-1 `}
          >
            <JamlogIcon className="w-8 h-8" />
            <label className="text-bold font-bold text-2xl -mb-1 text-white cursor-pointer">
              upu.jamlog
            </label>
          </NavLink>
          <div>
            <Menu
              as={"div"}
              className={"flex flex-row-reverse items-center gap-3"}
            >
              <Menu.Button
                className={
                  "w-9 h-9 rounded-lg flex items-center justify-center bg-teal-50"
                }
              >
                <MenuIcon className=" w-5 h-5 text-white" />
              </Menu.Button>
              {/* Use the `Transition` component. */}
              <Transition
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-50 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items
                  className={
                    "flex justify-between items-center w-min h-9 rounded-lg bg-teal-50"
                  }
                >
                  {routes.map((item, i) => {
                    if (item.isVisible) {
                      if (item.roles.includes(auth.user.role)) {
                        return (
                          <NavLink
                            key={i}
                            to={item.path}
                            end
                            className={(isActive) => {
                              return `mx-1 border bg-[#005F6A] rounded-lg w-8 h-8 flex items-center justify-center ${
                                isActive?.isActive && "bg-[#00727F]"
                              }`;
                            }}
                            style={{ display: item.display }}
                          >
                            {<item.icon width={22} height={22} />}
                          </NavLink>
                        );
                      }
                    }
                  })}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          <div className="w-full h-full bg-white flex rounded-tl-[40px] p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
});

export default DashboardNavigation;

const tr = {
  login: {
    invalid: "Şifre veya kullanıcı adı hatalı.",
    loggedIn: "Başarıyla giriş yaptınız.",
    expired: "Hesabınızın süresi doldu.",
  },
  auth: {
    login: "Giriş Yap",
    search: "Ara",
    language: "Dil",
    casingList: "M.Borusu Listesi",
    reportList: "Rapor Listesi",
    jobList: "İş Listesi",
    equipmentList: "Ekipman Listesi",
    settings: "Ayarlar",
    csvUpload: "CSV Yükle",
    map: "Harita",
    report: "Rapor",
    log: "Log Kayıtları",
    password: "Şifre",
    username: "Kullanıcı Adı",
    email: "Email",
    welcome: "Hoşgeldiniz",
    welcomeSubTitle: "Lütfen bilgilerinizi giriniz.",
    changePassword: "Şifre Değiştir",
    changePasswordSubTitle: "Lütfen yeni şifrenizi giriniz.",
    change: "Değiştir",
    userList: "Kullanıcı Listesi",
    casing: "Muhafaza Borusu (Casing)",
    casingNameCasing: "Muhafaza Borusu",
    customerList: "Müşteri Listesi",
    settings: "Ayarlar",
  },
  routes: {
    casingList: "M.Borusu Listesi",
    jobList: "İş Listesi",
    equipmentList: "Ekipman Listesi",
    settings: "Ayarlar",
    csvUpload: "CSV Yükle",
  },
  validation: {
    empty: "Boş bırakılamaz",
    string: "Girdi stirng formatında olmalıdır",
    email: "Girdi email formatında olmalıdır",
    number: "Girdi sayı formatında olmalıdır",
    max30: "30 bu input için maksimum değerdir",
    min0: "0 bu input için minimum değerdir",
    date: "Girdi tarih formatında olmalıdır",
  },
  form: {
    edit: "Düzenle",
    selectFile: "Dosya Seç",
    removePicture: "Resmi Kaldır",
    selectDate: "Tarih Seçin",
    timePeriod: "Zaman Dilimi",
    sortFilter: "Arama Filtresi",
    unauthorized: "Bu işlem için yetkiniz yok",
  },
  buttons: {
    start: "Başlat",
    login: "Giriş",
    next: "Sonraki",
    prev: "Önceki",
    add: "Ekle",
    delete: "Sil",
    addNew: "Yeni Ekle",
    change: "Değiştir",
    stop: "Vazgeç",
    save: "Kaydet",
    edit: "Kaydet",
    edit2: "Düzenle",
    removeFilter: "Filtreyi Kaldır",
    closeButton: "Kapat",
    update: "Güncelle",
    send: "Gönder",
    failed: "Maalesef işlem tamamlanamadı.",
    go: "Raporu Görüntüle",
    download: "İş emri",
    downloads: "İş Detayını İndir",
  },
  dashboard: {
    reports: "Raporlar",
    status: "Durum",
    country: "Ülke",
    city: "Şehir",
    rig: "Rig",
    well: "Well",
    editReport: "Raporu Güncelle",
    saveReport: "Raporu Kaydet",
    reportName: "Rapor İsmi",
    deleteReportSubTitle: " isimli raporu silmek istediğinize emin misiniz?",
  },
  csv: {
    addReport: "Rapor Ekle",
    clickToUpload: "Yüklemek için tıklayın",
    csvOnly: "Sadece CSV",
    csvAdded: "",
    pleaseUploadAFile: "Lütfen bir dosya yükleyin",
    createReport: "Rapor Oluştur",
    invalid: "Geçersiz",
    invalidExp:
      "Yüklediğiniz dosyayı oluşturan cihaz şirketinize kayıtlı değil.",
  },
  job: {
    casingRunning: "İş",
    all: "Tümü",
    active: "Aktif",
    waiting: "Bekleyen",
    activeJob: "Aktif İşler",
    waitingJob: "Bekleyen İşler",
    done: "Tamamlandı",
    pendingWaiting: "Onay Bekleyen",
    createNewJob: "Yeni İş Oluştur",
    jobs: "İşler",
    allJob: "Tüm İşler",
    job: "İş",
    status: "Durum",
    jobName: "İş Adı",
    jobNo: "İş No",
    deleteJobSubTitle: " isimli işi silmek istediginize emin misiniz?",
    rig: "Kule",
    well: "Kuyu",
    createJobWith: "Tally İsteyin",
    wellDepth: "Kuyu Derinliği (m)",
    ppcf: "",
    approved: "Durum",
    deliveryDate: "Teslim Tarihi",
    flotation: "Yüzdürme",
    casingTopLeft: "Boru Üst Kalan (m)",
    casingShoeDepth: "Taban Derinliği (m)",
    ratHole: "Alt Kalan (m)",
    casingCompany: "Boru Üretici Firma",
    casingSupervisor: "Boru Üretici Yetkilisi",
    tongOperator: "Tong Operator",
    threadRepresentativeCompany: "Diş Üretici Firması",
    threadRepeat: "Diş Üretici (s)",
    equipment: "",
    squeeze: "Sıkma (Ib.ft)",
    companyRepresentative: "Baş Mühendis",
    lease: "Yüklenici Firma",
    firstTechnician: "Teknisyen 1",
    secondTechnician: "Teknisyen 2",
    stabTechnician: "Yedek Teknisyen",
    fieldInformation: "Saha Bilgisi",
    jobInformation: "İş Bilgisi",
    mudWeight: "Çamur Ağırlığı (ppcf)",
    uploadReport: "Rapor Yükle",
    ///
    lots: "Lots",
    lot: "Lot",
    lotNo: "Lot No",
    lotType: "Lot Tipi",
    type: "Tip",
    compound: "Standart",
    size: "Boyut",
    weight: "Ağırlık",
    grade: "Malzeme Kalitesi",
    highShoulder: "Yüksek Kavrama Torku",
    lowShoulder: "Düşük Kavrama Torku",
    updateLot: "Lot Güncelle",
    record: "Kayıt",
    log: "Kayıt",
    dateRecord: "Tarih",
    location: "Konum",
    ipAddress: "IP Adresi",
    records: "Defteri",
    ///
    casingTally: "",
    joints: "",
    joint: "Joint",
    jointNo: "Joint No",
    centralizer: "Merkezleyici",
    materialSpecific: "Malzeme Özelliği",
    casingNo: "Boru No",
    length: "Uzunluk",
    totalLength: "Toplam Uzunluk",
    materialTopEndDepth: "Malzeme Üst Ucu Derinliği",
    wMud: "Çamur Ağırlığı (t)",
    totalWMud: "Çamur Ağılığı Toplam (t) ",
    updateJoint: "Joint Güncelle",
    sameName: "Aynı isimden birden fazla olamaz.",
    noChartDataJoint: "Bu Jointin datasi bulunmamaktadır.",
    passive: "İş aktif değil.",
    updated: "Güncellendi.",
    failed: "Başarısız.",
    saved: "Kaydedildi.",
    deleted: "Silindi.",
    quickAdd: "Hızlı Muhafaza Borusu Ekle",
    casingQty: "Malzeme Adedi",
    totalLength: "Toplam Uzunluk",
    made: "Malzeme",
    quickAddInfo: "*Hızlı Boru Ekleme Yapılırken Önceki Borular Silinir.",
  },
  equipment: {
    equipmentList: "",
    equipment: "Ekipman",
    list: "Listesi",
    createNewEquipment: "Yeni Ekipman Oluştur",
    tongManufacturer: "Tong Üreticisi",
    tongSize: "Tong Boyutu",
    tongArmLength: "Tong Merkez Uzunluğu",
    torqueSubSerial: "Tong Seri Numarası",
    loadCellSerial: "Yük Hücre Seri Numarası",
    loadCellCapacity: "Yük Hücre Kapasitesi",
    loadCellSensitivity: "Yük Hücre Hassasiyeti",
    cellCalibrationDate: "Hücre Kalibrasyon Tarihi",
    threadCount: "Diş Miktarı",
    comment: "Açıklama",
    deleteEquipmentSubTitle:
      " isimli ekipmanı silmek istediginize emin misiniz?",
    edit: "DÜZENLE",
    delete: "SİL",
    all: "Hepsi",
    material: "Materyal",
    created: " başarıyla oluşturuldu.",
    updated: " başarıyla güncellendi.",
    deleted: " başarıyla silindi.",
  },
  casing: {
    casingList: "",
    casing: "",
    createNewCasing: "Yeni Boru Oluştur",
    createNewCasings: "Yeni Muhafaza Borusu Oluştur",
    casingTally: "İş Adı",
    casingName: "Boru Adı",
    producerCompany: "Yapımcı Firma",
    casingType: "Boru Tipi",
    casingSize: "Boru Boyutu (m)",
    grade: "Malzeme Kalitesi",
    weight: "Ağırlık (Ib/ft)",
    threadCompound: "Diş Standardı",
    wall: "Et Kalınlığı (in)",
    ID: "İç Çap Gövdesi",
    driftID: "Drift ID",
    minTorque: "Min Tork",
    estimatedTorque: "Tork Değerleri",
    maxTorque: "Max Tork",
    optTorque: "Opt Tork",
    shoulderTorque: "shdlr",
    maxDelta: "Max Delta",
    turn: "Tur",
    maxTurn: "Max",
    minTurn: "Min",
    referenceTorque: "Referans Torku",
    collapse: "Dış Basınç Dayınımı",
    burst: "İç Basınç Dayınımı",
    jointStrenght: "Bağlantı",
    lbs: "1000 lbs",
    bodyYield: "Body Yield",
    deleteCasingSubTitle: " isimli boruyu silmek istedigine emin misin?",
    upload: "Yükle",
    minimumTorque: "Minimum Tork",
    optimumTorque: "Optimum Tork",
    maximumTorque: "Maksimum Tork",
    shouldersTorque: "Shoulder Tork",
    maxsTurn: "Maksimum Tur",
    minsTurn: "Minimum Tur",
    created: " başarıyla oluşturuldu.",
    updated: " başarıyla güncellendi.",
    deleted: " başarıyla silindi.",
  },
  user: {
    userList: "",
    user: "Kullanıcı",
    list: "Listesi",
    name: "İsim",
    lastName: "Soyisim",
    phoneNumber: "Telefon",
    email: "Email",
    company: "Firma",
    avatar: "Resim",
    userLimit: "Kullanıcı Limiti",
    image: "Resim",
    address: "Adres",
    title: "Ünvan",
    password: "Şifre",
    passwordRepeat: "Şifre Tekrar",
    notMatchPassword: "Şifreler uyuşmuyor!",
    createNewUser: "Yeni Kullanıcı Oluştur",
    noCredit: "Kullanıcı kredisi doludur.",
    noCreditHeader: "Kullanıcı kredisi dolu",
    deleteUserSubTitle: " isimli kullanıcıyı silmek istediginize emin misiniz?",
    profile: "Profil",
    page: "Sayfası",
    role: "Rol",
    jamlog: "Jamlog",
  },
  imageUploader: {
    max2Mb: "Dosya boyutu 2mb dan büyük olamaz!",
  },
  fileInput: {
    profilSubTitle: "Profil resmi eklemek için tıklayın.",
    uploadHeaderPicture: "Profil resmi",
    documentSubTitle: "Dosya yüklemek için tıklayınız.",
    uploaderHeaderDoc: "Dosya",
    uploadAvatar: "Resim yükle",
    changePic: "Resim değiştir",
    cropPicture: "Resim kırp",
    removePicture: "Resmi kaldır",
    uploadFile: "Belge yükle",
    uploaderFile: "Dosya Yükle",
    //Equipment
    equipmentPicture: "Ekipman resmi",
    equipSubTitle: "Ekipman resmi eklemek için tıklayınız.",
  },
  noData: {
    header: "Veri yok",
    logText: "Şimdilik veri yok.",
    casingText: "Kayıtlı boru bulunmamaktadır.",
    equipmentText: "Kayıtlı ekipman bulunmamaktadır.",
    jobText: "Kayıtlı iş bulunmamaktadır.",
    userText: "Kayıtlı kullanıcı bulunmamaktadır.",
  },
  map: {
    onProgress: "Bu işlem devam ediyor.",
    goJobs:
      "Bu iş emrini düzenlemek için iş emirleri sayfasına gidebilirsiniz.",
    completed: "Tamamlandı",
    active: "Aktif",
    choose: "Lokasyon belirleyin",
    rigs: "Kuleler",
    manuelJob: "Manuel İş ile devam et",
  },
  mail: {
    name: "Müşteri Firma Adı",
    requestTally: "Müşteri Oluştur",
    email: "Email",
    validDay: "Hesap Geçerlilik Günü",
    requset: "Talep",
    rentJamlog: "Jamlog Kiralama",
    sent: "Email başarıyla gönderildi.",
    alreadySent: "Bu kullanıcıya talep gönderilmiş.",
    jamlog: "Jamlog Cihazı",
  },
  jamlog: {
    header: "Cihaz Seçin",
    text: "Oluşturduğunuz işin kullanılacağı Jamlog cihazını seçin.",
    placeholder: "Jamlog Cihaz Listesi",
  },
  customer: {
    customer: "Müşteri",
    name: "Müşteri Adı",
    mail: "Müşteri Mail",
  },
};

export default tr;

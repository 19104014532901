import { createContext } from "react";
import { appStore } from "../stores/app.store";
import { authStore } from "../stores/auth.store";
import { equipmentStore } from "../stores/equipment.store";
import { jobStore } from "../stores/job.store";
import { modalStore } from "../stores/modal.store";
import { reportStore } from "../stores/report.store";
const StoreContext = createContext({
  auth: authStore,
  app: appStore,
  modal: modalStore,
  report: reportStore,
  job: jobStore,
  equipment: equipmentStore,
});
const StoreContextProvider = ({ children }) => (
  <StoreContext.Provider>{children}</StoreContext.Provider>
);
export { StoreContext, StoreContextProvider };

import React from "react";

const Input = ({
  onChange,
  placeholder,
  value,
  onBlur,
  disabled,
  inputType,
  label,
  width,
  name,
  errorMessage = "",
  validate = "",
  ...rest
}) => {
  const changeChar = (value) => {
    const charMap = {
      Ç: "C",
      Ö: "O",
      Ş: "S",
      İ: "I",
      I: "I",
      Ü: "U",
      Ğ: "G",
      ç: "c",
      ö: "o",
      ş: "s",
      ı: "i",
      ü: "u",
      ğ: "g",
    };
    const charArr = value.split("");
    const changedArr = charArr.map((ch, i) => {
      if (charMap[ch] != null) {
        return charMap[ch];
      } else {
        return ch;
      }
    });
    const string = changedArr.join("");
    return string;
  };
  return (
    <>
      <label
        style={{ width: width }}
        className="text-gray-700 h-[70px] text-sm font-medium leading-5 flex flex-col relative"
      >
        {label}
        <input
          onChange={(e) => onChange(changeChar(e.target.value))}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          disabled={disabled}
          type={inputType}
          name={name}
          rest={rest}
          className={`h-11 py-2.5 px-3.5 bg-white border border-gray-300 shadow-sm rounded-lg text-base text-gray-600 focus:outline-none mt-1.5 ${
            disabled ? "bg-secondary-50 " : <></>
          }`}
        />
        {errorMessage && validate && (
          <p className="text-[10px] text-red-500 absolute -bottom-2 right-3 bg-white">
            {errorMessage || validate.toLocaleUpperCase()}
          </p>
        )}
      </label>
    </>
  );
};

export default Input;

import React, { useState, useEffect } from "react";
import { readService } from "../../services";
import DashboardFilterMenu from "./components/DashboardFilterMenu";
import MapContainer from "./components/NewMap";
import { useStore } from "../../hooks/useStores";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  ChangeLanguage,
  ModalTemplate,
  UploadReportModal,
} from "../../components";
import { Language, PlusIcon, UpuJamlogLogo, UpuLogo } from "../../assets/icons";
import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { GrLanguage } from "react-icons/gr";

import { observer } from "mobx-react";
import { roles } from "../../static/constants";
const Dashboard = observer(({ isLite, setCoordinates, coordinates }) => {
  const { t } = useTranslation();
  const { report, auth } = useStore();
  const [reports, setReports] = useState([]);
  const [tempReports, setTempReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [selectedJob, setSelectedJob] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [open, setOpen] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  //lang
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  const handleReports = async () => {
    await readService
      .getReports()
      .then((res) => {
        setReports(res.data);
        setTempReports(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleReports();
  }, []);
  return (
    <div className="w-full h-full flex flex-col  relative">
      <>
        {!isLite && (
          <>
            <div className="p-3 flex items-center relative flex-row justify-between">
              <div className="flex flex-row gap-2.5 items-end">
                <div className="flex items-center">
                  {report.chooseLocation || report.updateReportLocation ? (
                    <>
                      {" "}
                      <span className="text-secondary-700 font-semibold text-lg">
                        {t("map:choose")}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-black font-bold text-2xl">
                        upu.
                      </span>
                      <span className="text-[#005F6A] font-bold text-2xl">
                        jamlog
                      </span>
                    </>
                  )}
                </div>
              </div>
              {report?.chooseLocation || report?.updateReportLocation ? (
                <div className="flex gap-4 items-center">
                  <button
                    className="w-[150px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-red-600 hover:border border-red-500 flex items-center justify-center gap-2 text-sm font-medium text-white"
                    onClick={() => {
                      setSelectedReport(null);
                      setSelectedJob(null);
                      report.setUpdateReportLocation(false);
                      report.setChooseLocation(false);
                    }}
                  >
                    {t("buttons:stop")}
                  </button>
                  <button
                    className="w-[150px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-[#005F6A] hover:border border-teal-50 flex items-center justify-center gap-2 text-sm font-medium text-white"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    OK
                  </button>
                </div>
              ) : (
                <div className="flex gap-4 items-center">
                  <div>
                    <button
                      onClick={() => {
                        setSelectedReport(null);
                        setSelectedJob(null);
                        setUploadModal(!uploadModal);
                        report.setReportData({});
                        report.setUpdateReportLocation(false);
                        report.setChooseLocation(false);
                      }}
                      className="w-[150px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-[#005F6A] hover:border border-teal-50 flex items-center justify-center gap-2 text-sm font-medium text-white"
                    >
                      <PlusIcon width={12} height={12} color={"#fff"} />
                      <p className="text-sm xs:text-xs text-white">
                        {t("job:uploadReport")}
                      </p>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setVisibleLanguage(!visibleLanguage);
                      }}
                      style={{
                        alignItems: "center",
                        right: match ? 0 : -25,
                        position: "",
                        cursor: "pointer",
                        justifyContent: "center",
                        borderRigth: "0px solid black",
                      }}
                      className="bg-[#FFFFFF] select-none rounded-md w-10 h-10 flex border"
                    >
                      <GrLanguage color="#344054" />
                    </button>
                  </div>
                  {visibleLanguage && (
                    <ChangeLanguage
                      setSelectedLanguage={setSelectedLanguage}
                      selectedLanguage={selectedLanguage}
                      setVisibleLanguage={setVisibleLanguage}
                    />
                  )}
                </div>
              )}
            </div>{" "}
            <hr className="h-[1px] w-full text-secondary-400 ml-auto pb-2 -mt-2 drop-shadow-md xs:hidden" />
          </>
        )}

        <MapContainer
          setSelectedReport={setSelectedReport}
          selectedReport={selectedReport}
          handleReports={handleReports}
          reports={!isLite ? tempReports : []}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          hovered={hovered}
          setHovered={setHovered}
          isLite={isLite}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
        />
        {!(report.updateReportLocation || report.chooseLocation) && (
          <>
            <div
              className={`h-[93%] mt-[64px]  border-[2px] rounded-lg absolute left-0 top-0 ${
                isLite && "hidden"
              }`}
            >
              <DashboardFilterMenu
                setSelectedReport={setSelectedReport}
                reports={reports}
                setIsOpen={setIsOpen}
                selectedReport={selectedReport}
                handleReports={handleReports}
                tempReports={tempReports}
                setReports={setReports}
                open={open}
                setOpen={setOpen}
              />
              <button
                onClick={() => {
                  setOpen(!open);
                }}
                className={`items-center justify-center absolute top-4 ${
                  !open ? "left-4 w-8 h-8 rounded bg-[#003E46]" : "right-4"
                } top-0 xs:flex hidden `}
              >
                {!open ? (
                  <FiMenu size={24} color="white" />
                ) : (
                  <GrClose size={16} color="#005F6A" />
                )}
              </button>
            </div>
            {hovered && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                {hovered.location}
              </div>
            )}
          </>
        )}
        <UploadReportModal
          setSelectedJob={setSelectedJob}
          isOpen={uploadModal}
          setUploadModal={setUploadModal}
        />
      </>
    </div>
  );
});

export default Dashboard;

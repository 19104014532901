import React, { useState, useEffect } from "react";
import { PencilIcon, PlusIcon, Language } from "../../assets/icons";
import CreateNewCasing from "./CreateNewCasing";
import { casingService } from "../../services";
import i18next from "i18next";
import { GrLanguage } from "react-icons/gr";

import { useTranslation } from "react-i18next";
import {
  PaginationComponent,
  Loading,
  ChangeLanguage,
  InfoModal,
  TableSearch,
  NoData,
} from "../../components";
const CasingList = ({ isModal = false, setClose }) => {
  const [newCasing, setNewCasing] = useState(false);
  const [selectedCasing, setSelectedCasing] = useState({});
  const [searchVal, setSearchVal] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});
  //lang
  const { t } = useTranslation();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  const handleCasingsPage = async () => {
    setLoading(true);
    await casingService
      .getCasingsPaginated(pageNo, searchVal)
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if ((pageNo + 1) >> res.data.meta.pageCount) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleCasingsPage();
  }, [pageNo, searchVal]);
  return (
    <>
      <div className="w-full h-full flex flex-col relative">
        <div className="w-full h-14 flex items-center justify-between px-3 border-b-2 border-secondary-200">
          <div className="flex items-center justify-center gap-x-1">
            <span className="font-bold text-xl xs:text-lg text-secondary-900">
              {t("auth:casing")}
            </span>
            <span className="font-bold text-xl xs:text-lg text-[#005F6A]">
              {t("equipment:list")}
            </span>
          </div>
          <div className="flex gap-4 items-center">
            <div>
              <button
                onClick={() => {
                  setSelectedCasing();
                  setNewCasing(true);
                }}
                className="w-[240px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-[#00727F] hover:border border-teal-500 flex items-center justify-center gap-2 text-sm xs:text-xs font-medium text-white"
              >
                <PlusIcon color="#FFFFFF" />
                {t("casing:createNewCasings")}
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setVisibleLanguage(!visibleLanguage);
                }}
                style={{
                  alignItems: "center",
                  right: match ? 0 : -25,
                  position: "",
                  cursor: "pointer",
                  justifyContent: "center",
                  borderRigth: "0px solid black",
                }}
                className="bg-[#FFFFFF] select-none rounded-md xs:w-7 xs:h-7 w-10 h-10 flex border"
              >
                <GrLanguage className=" xs:w-4 xs:h-4" color="#344054" />
              </button>
            </div>
            {visibleLanguage && (
              <ChangeLanguage
                setSelectedLanguage={setSelectedLanguage}
                selectedLanguage={selectedLanguage}
                setVisibleLanguage={setVisibleLanguage}
              />
            )}
          </div>
        </div>
        {/* table */}
        <div
          style={{ zIndex: newCasing ? "-10" : "0" }}
          className="w-full flex flex-1 overflow-y-auto scrollbar-hide flex-col border border-gray-200 shadow rounded-lg mt-6"
        >
          <div className="w-full h-[73px] min-h-[73px] flex items-center px-6 border-b border-gray-200">
            <span className="h-[22px] px-2 flex items-center justify-center bg-teal-50 rounded-2xl text-xs font-medium text-teal-700">
              {totalCount} {t("auth:casingNameCasing")}
            </span>
            <div className="w-[200px] ml-auto">
              <TableSearch
                height={44}
                setSearchVal={setSearchVal}
                placeholder={t("auth:search")}
              />
            </div>
          </div>
          {/* table head */}
          <div className="xs:w-full xs:overflow-x-scroll h-full w-full">
            <div className="w-full h-11 xs:min-w-[1440px] min-h-[44px] flex items-center justify-between bg-gray-50 border-b border-gray-200">
              <div className="grow w-[calc(100%/4)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:casingName")}
                </p>
              </div>
              <div className="grow w-[calc(100%/8)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:grade")}
                </p>
              </div>
              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:wall")}
                </p>
              </div>
              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:ID")}
                </p>
              </div>
              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center text-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:driftID")}
                </p>
              </div>

              <div className="h-full grow w-[calc(100%/3)] flex flex-col items-center border-r border-gray-200">
                <div className="flex w-full h-1/2 items-center justify-center border-b border-gray-200 hover:bg-gray-100 ">
                  <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                    {t("casing:estimatedTorque")}
                  </p>
                </div>
                <div className="flex w-full h-1/2 items-center justify-between">
                  <div className="w-1/3 h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                      {t("casing:minTorque")}
                    </p>
                  </div>

                  <div className="w-1/3 h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                      {t("casing:optTorque")}
                    </p>
                  </div>

                  <div className="w-1/3 h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                    <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                      {t("casing:maxTorque")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center text-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:collapse")}
                </p>
              </div>

              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center text-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:burst")}
                </p>
              </div>

              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] text-center h-full flex flex-col items-center justify-center hover:bg-gray-100 border-r border-gray-200 relative">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:jointStrenght")}
                </p>
                <span className=" text-[8px] font-medium text-secondary-500">
                  {t("casing:lbs")}
                </span>
              </div>

              <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full text-center flex flex-col items-center justify-center hover:bg-gray-100 border-r border-gray-200 relative">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("casing:bodyYield")}
                </p>
                <span className=" text-[8px] font-medium text-secondary-500">
                  {t("casing:lbs")}
                </span>
              </div>

              <div className=" w-[calc(100%/12)] h-full flex items-center justify-center gap-2 hover:bg-gray-100 border-r border-gray-200">
                <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                  {t("equipment:edit")}
                </p>
              </div>
            </div>
            {/* table body */}
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="w-12 h-12">
                  <Loading />
                </div>
              </div>
            ) : totalCount <= 0 ? (
              <div className="flex items-center justify-center h-full">
                <NoData
                  header={t("noData:header")}
                  text={t("noData:casingText")}
                />
              </div>
            ) : (
              pageData?.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="w-full scrollbar-hide overflow-y-auto xs:min-w-[1440px] h-[66px] min-h-[66px] border-b border-gray-200 hover:bg-gray-50 flex items-center"
                  >
                    <div className="grow w-[calc(100%/4)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.casingName}
                      </p>
                    </div>

                    <div className="grow w-[calc(100%/8)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.grade}
                      </p>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.wall}
                      </p>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.idIn}
                      </p>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.driftDiameter}
                      </p>
                    </div>

                    <div className="h-full grow w-[calc(100%/3)] flex items-center border-r border-gray-200">
                      <div className="w-1/3 h-full flex items-center justify-center gap-2">
                        <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                          {data?.min}
                        </p>
                      </div>

                      <div className="w-1/3 h-full flex items-center justify-center gap-2">
                        <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                          {data?.opt}
                        </p>
                      </div>

                      <div className="w-1/3 h-full flex items-center justify-center gap-2">
                        <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                          {data?.max}
                        </p>
                      </div>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.collapse}
                      </p>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.burst}
                      </p>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.jointStrenght}
                      </p>
                    </div>
                    <div className="grow md:w-[calc(100%/8)] w-[calc(100%/10)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <p className="md:text-[10px] text-center text-xs font-medium text-gray-500">
                        {data?.bodyYield}
                      </p>
                    </div>
                    <div className="grow w-[calc(100%/12)] h-full flex items-center justify-center gap-2 border-r border-gray-200">
                      <div
                        onClick={() => {
                          setSelectedCasing(data);
                          setNewCasing(true);
                        }}
                        className="w-[72px] min-w-[72px]  h-full flex cursor-pointer items-center justify-center gap-2 border-gray-200"
                      >
                        <PencilIcon className="w-5 h-5 text-secondary-500" />
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <PaginationComponent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
        {isModal && (
          <div className="flex flex-row justify-end items-center gap-6 w-full h-[40px] mt-3">
            <div className="flex items-end gap-6 h-[40px]">
              <button
                type="submit"
                onClick={() => {
                  setClose(false);
                }}
                className="w-[144px] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
              >
                OK
              </button>
            </div>
          </div>
        )}
        {/* CREATE NEW CASING PAGE */}
        <div
          className={`${
            newCasing ? "w-full left-0" : "w-0 left-[100%]"
          } overflow-hidden top-0 h-full bottom-0 transition-all duration-500 absolute z-50 bg-white`}
        >
          {newCasing && (
            <CreateNewCasing
              selectedCasing={selectedCasing}
              setNewCasing={setNewCasing}
              handleCasings={handleCasingsPage}
              setInfoModalOpen={setInfoModal}
              setInfoModalData={setInfoModalData}
            />
          )}
        </div>
        <InfoModal
          isOpen={infoModal}
          setIsOpen={setInfoModal}
          text={infoModalData?.text}
          header={infoModalData?.header}
          label={infoModalData?.label}
          isSuccess={infoModalData?.success}
        />
      </div>
    </>
  );
};

export default CasingList;

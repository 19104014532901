import { ArrowDownIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { EditIcon, PlusIcon, JsonIcon, Language } from "../../assets/icons";
import {
  ChangeLanguage,
  Loading,
  TableSearch,
  AlertModal,
  InfoModal,
  NoData,
} from "../../components";
import { casingService, jobService, equipmentService } from "../../services";
import { useAlert, positions } from "react-alert";
import moment from "moment";
import CreateNewJob from "./CreateNewJob";
import CreateNewJobStep2 from "./CreateNewJobStep2";
import { BsTrash } from "react-icons/bs";
import LotTable from "./LotTable";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ImportJobData from "./ImportJobPage";
import { GrLanguage } from "react-icons/gr";
import { useStore } from "../../hooks/useStores";
import { roles } from "../../static/constants";
import SendMailModal from "./SendMailModal";
const JobList = () => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [searchVal, setSearchVal] = useState("");
  // Modal States
  const [isAlert, setIsAlert] = useState(false);
  const [newJob, setNewJob] = useState(false);
  const [newJoint, setNewJoint] = useState(false);
  const [newLot, setNewLot] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);
  // Data States
  const [casings, setCasings] = useState([]);
  const [lots, setLots] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [tempJobs, setTempJobs] = useState([]);
  const [equipments, setEquipments] = useState([]);
  // Main Job Info State
  const [jobInfo, setJobInfo] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});
  const alert = useAlert();
  //lang
  const { t } = useTranslation();
  const { auth } = useStore();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const [loading, setLoading] = useState(false);
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  const handleJobs = async () => {
    setLoading(true);
    await jobService
      .getJobs()
      .then((res) => {
        setJobs(res.data);
        setTempJobs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCasings = async () => {
    await casingService
      .getCasings()
      .then((res) => {
        setCasings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleEqu = async () => {
    await equipmentService
      .getEquipments()
      .then((res) => {
        setEquipments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleCasings();
    handleJobs();
    handleEqu();
  }, []);
  const deleteJob = async (id) => {
    if (auth?.user?.role != roles.CTM) {
      setAlertLoading(true);
      await jobService
        .deleteJob(selectedJob.id)
        .then((res) => {
          if (res.data.code == 0) {
            handleJobs();
            setIsAlert(false);
            setInfoModalData({
              success: true,
              text: `${selectedJob?.jobName} ${t("equipment:deleted")}`,
              header: selectedJob?.jobName,
              label: "OK",
            });
            setInfoModal(true);
          } else {
            setIsAlert(false);
            setInfoModalData({
              success: false,
              text: `${selectedJob?.jobName} ${t("buttons:failed")}`,
              header: selectedJob?.jobName,
              label: "OK",
            });
            setInfoModal(true);
          }
        })
        .catch((err) => console.log(err));
      setAlertLoading(false);
    } else {
      setIsAlert(false);
      setInfoModalData({
        success: false,
        text: t("form:unauthorized"),
        header: selectedJob?.jobName,
        label: "OK",
      });
      setInfoModal(true);
    }
  };
  useEffect(() => {
    if (searchVal) {
      const filterJobs = tempJobs.filter((e) => {
        let name = `${e?.jobName}`;
        return name
          .replace(/^\./g, "")
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setJobs(filterJobs);
    } else {
      setJobs(tempJobs);
    }
  }, [searchVal, setSearchVal]);
  const getJson = async (job) => {
    if (job.isActive) {
      const data = await jobService
        .getJSON(job.id)
        .then((res) => {
          if (res.data.code == 0) {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
              JSON.stringify(res.data.content)
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = `${job.jobName}.json`;
            link.click();
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert.error(t("job:passive"), {
        position: positions.TOP_LEFT,
      });
    }
  };
  const tab = [
    { label: t("job:all"), value: "all" },
    { label: t("job:active"), value: "active" },
    { label: t("job:done"), value: "done" },
  ];
  const tableHeadItems = [
    { label: t("job:approved"), value: "isActive" },
    { label: t("job:jobName"), value: "jobName" },
    { label: t("job:companyRepresentative"), value: "companyRep" },
    { label: t("job:lease"), value: "lease" },
    { label: t("job:rig"), value: "rig" },
    { label: t("job:well"), value: "well" },
    { label: t("job:casingCompany"), value: "casingCompany" },
    { label: t("job:deliveryDate"), value: "date" },
    { label: t("buttons:edit2"), value: "edit" },
    { label: t("buttons:delete"), value: "delete" },
    { label: "JSON", value: "report" },
  ];
  useEffect(() => {
    if (selectedTab != "all") {
      setJobs(
        tempJobs.filter((j, i) => {
          return j.status == selectedTab;
        })
      );
    } else {
      setJobs(tempJobs);
    }
  }, [selectedTab]);
  return (
    <>
      <div className="w-full h-full overflow-hidden flex flex-col relative">
        <div
          style={{ zIndex: newJob ? "-10" : "0" }}
          className="flex flex-col items-start w-full h-full"
        >
          <div className="flex flex-row items-center justify-between pl-5 h-14 border-b-[2px] border-gray-200 w-full">
            <div className="flex items-center justify-center gap-x-1">
              <span className="font-bold text-xl xs:text-lg text-secondary-900">
                {t("job:casingRunning")}
              </span>
              <span className="font-bold text-xl xs:text-lg text-[#005F6A]">
                {t("equipment:list")}
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <div>
                <button
                  onClick={() => {
                    setJobInfo();
                    setSelectedJob(null);
                    setNewJob(true);
                  }}
                  className="w-[160px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-[#005F6A] hover:border border-teal-50 flex items-center justify-center gap-2 text-sm font-medium text-white"
                >
                  <PlusIcon width={12} height={12} color={"#fff"} />
                  <p className="text-sm xs:text-xs text-white">
                    {t("job:createNewJob")}
                  </p>
                </button>
              </div>
              {(auth?.user?.role == roles.CA ||
                auth?.user?.role == roles.CS) && (
                <div>
                  <button
                    onClick={() => {
                      setJobInfo();
                      setSelectedJob(null);
                      setMailModal(true);
                    }}
                    className="w-[150px] xs:w-[140px] xs:h-8 h-10 rounded-lg bg-white border hover:border border-gray-300 flex items-center justify-center gap-2 text-sm font-medium text-teal-700"
                  >
                    <PlusIcon width={12} height={12} color={"#344054"} />
                    <p className="text-sm xs:text-xs text-[#344054]">
                      {t("job:createJobWith")}
                    </p>
                  </button>
                </div>
              )}
              <div>
                <button
                  onClick={() => {
                    setVisibleLanguage(!visibleLanguage);
                  }}
                  style={{
                    alignItems: "center",
                    right: match ? 0 : -25,
                    position: "",
                    cursor: "pointer",
                    justifyContent: "center",
                    borderRigth: "0px solid black",
                  }}
                  className="bg-[#FFFFFF] select-none xs:w-7 xs:h-7 rounded-md w-10 h-10 flex border"
                >
                  <GrLanguage className="xs:w-4 xs:h-4" color="#344054" />
                </button>
              </div>
              {visibleLanguage && (
                <ChangeLanguage
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  setVisibleLanguage={setVisibleLanguage}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between gap-2 w-full h-11 bg-gray-50 rounded-lg mt-4">
            {tab?.map((t, i) => (
              <span
                onClick={() => setSelectedTab(t.value)}
                className={`${
                  selectedTab === t.value
                    ? "bg-white shadow text-[#344054]"
                    : "text-[#667085]"
                } w-1/3 h-[85%] rounded-lg py-2 flex items-center justify-center text-sm cursor-pointer`}
              >
                {t.label}
              </span>
            ))}
          </div>
          <div className="flex flex-col items-start w-full mt-2 h-full">
            <div className="flex-1 flex w-full h-full">
              <div className="flex flex-col items-start w-full h-[99%] overflow-hidden border shadow rounded-lg">
                <div className="flex flex-row justify-between items-center gap-3 px-6 xs:px-2 w-full h-[73px] border-b">
                  <div className=" flex flex-row items-start gap-2 justify-center">
                    {/* <p className="xs:text-sm">{t("job:jobs")}</p> */}
                    <span className="flex items-center justify-center w-[59px] xs:w-[50px] h-6 rounded-2xl bg-[#EDF7F8] text-xs text-[#005F6A]">
                      {tempJobs?.length} {t("job:job")}
                    </span>
                  </div>
                  <div className="w-[200px] ml-auto">
                    <TableSearch
                      height={44}
                      setSearchVal={setSearchVal}
                      placeholder={t("auth:search")}
                    />
                  </div>
                </div>
                <div className="xs:w-full w-full xs:overflow-x-scroll h-full max-h-[90%]">
                  <div className="w-full h-11 xs:min-w-[1440px] bg-gray-50 flex items-center justify-between">
                    {tableHeadItems?.map((past, i) => {
                      return (
                        <span
                          key={i}
                          className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                        >
                          {past.label}
                        </span>
                      );
                    })}
                  </div>
                  <div className="flex flex-col items-start w-full h-full max-h-[90%]">
                    {loading ? (
                      <div className="flex w-full items-center justify-center h-full">
                        <div className="w-12 h-12">
                          <Loading />
                        </div>
                      </div>
                    ) : jobs.length <= 0 ? (
                      <div className="flex w-full items-center justify-center h-full">
                        <NoData
                          header={t("noData:header")}
                          text={t("noData:jobText")}
                        />
                      </div>
                    ) : (
                      <div className="w-full h-full overflow-y-scroll mb-10 scrollbar-hide">
                        {jobs?.map((b, i) => {
                          return (
                            <>
                              <div
                                key={i}
                                className="flex  w-full xs:min-w-[1440px] h-[72px] items-center border-b"
                              >
                                <span className="text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  <li className="min-w-[72px] border-gray-200 min-h-[72px] flex items-center justify-center group-hover:bg-slate-50">
                                    <span
                                      style={{
                                        backgroundColor:
                                          b?.status == "done"
                                            ? "#0E9384"
                                            : "#F79009",
                                      }}
                                      className="w-3 h-3 rounded-full border border-gray-300 shadow-sm"
                                    ></span>
                                  </li>
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {b?.jobName ? b?.jobName : "---"}
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {b?.companyRep ? b?.companyRep : "---"}
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {b?.lease ? b?.lease : "---"}
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {b?.rig}
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {b?.well}
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {b?.casingCompany ? b?.casingCompany : "---"}
                                </span>
                                <span className="md:text-[10px] text-center text-xs w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center">
                                  {moment(b?.deliveryDate).format("DD/MM/YYYY")}
                                </span>
                                <span
                                  onClick={() => {
                                    setSelectedJob(b);
                                    setNewJob(true);
                                  }}
                                  className="md:text-[10px] text-center text-xs cursor-pointer w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                                >
                                  <EditIcon width={24} height={24} />
                                </span>
                                <span
                                  onClick={() => {
                                    setSelectedJob(b);
                                    setIsAlert(true);
                                  }}
                                  className="md:text-[10px] text-center text-xs cursor-pointer w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                                >
                                  <BsTrash size={24} />
                                </span>
                                <span
                                  onClick={() => {
                                    getJson(b);
                                  }}
                                  className="md:text-[10px] text-center text-xs cursor-pointer w-[calc(100%/8)] justify-center font-medium text-gray-500 flex gap-1.5 items-center"
                                >
                                  <JsonIcon />
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            newJob ? "w-full left-0" : "w-0 left-[100%]"
          } overflow-hidden top-0 h-full bottom-0 transition-all duration-500 absolute bg-white`}
        >
          {newJob && (
            <CreateNewJob
              selectedJob={selectedJob}
              setNewJob={setNewJob}
              setNewLot={setNewLot}
              setJobInfo={setJobInfo}
              jobInfo={jobInfo}
              equipments={equipments}
            />
          )}
        </div>
        <div
          className={`${
            importModal ? "w-full left-0" : "w-0 left-[100%]"
          } overflow-hidden top-0 h-full bottom-0 transition-all duration-500 absolute bg-white`}
        >
          {importModal && (
            <ImportJobData
              setSelectedJob={setSelectedJob}
              setImportModal={setImportModal}
              setNewJob={setNewJob}
            />
          )}
        </div>
        <div
          className={`${
            newLot ? "w-full left-0" : "w-0 left-[100%]"
          } overflow-hidden top-0 h-full bottom-0 transition-all duration-500 absolute bg-white`}
        >
          {newLot && (
            <LotTable
              setNewJoint={setNewJoint}
              setNewLot={setNewLot}
              casings={casings}
              lots={lots}
              setLots={setLots}
              selectedJob={selectedJob}
              jobInfo={jobInfo}
            />
          )}
        </div>
        <div
          className={`${
            newJoint ? "w-full left-0" : "w-0 left-[100%]"
          } overflow-hidden top-0 h-full bottom-0 transition-all duration-500 absolute bg-white`}
        >
          {newJoint && (
            <CreateNewJobStep2
              selectedJob={selectedJob}
              setNewJoint={setNewJoint}
              handleJobs={handleJobs}
              jobInfo={jobInfo}
              lots={lots}
              setNewLot={setNewLot}
              setNewJob={setNewJob}
            />
          )}
        </div>
      </div>
      <AlertModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        text={selectedJob?.jobName + t("job:deleteJobSubTitle")}
        icon={<BsTrash className="text-3xl" />}
        header={"upu.jamlog"}
        label={"OK"}
        applyFunction={() => deleteJob()}
        loading={alertLoading}
      />
      <InfoModal
        isOpen={infoModal}
        setIsOpen={setInfoModal}
        text={infoModalData?.text}
        header={infoModalData?.header}
        label={infoModalData?.label}
        isSuccess={infoModalData?.success}
      />
      <SendMailModal isOpen={mailModal} setIsOpen={setMailModal} />
    </>
  );
};

export default JobList;

import React from "react";
import { UpuJamlogLogo, LeftArrow } from "../../assets/icons";
import { useStore } from "../../hooks/useStores";

const ReportHeader = ({ type, title, onClick }) => {
  const { auth } = useStore();
  return (
    <div
      style={{
        border: "1px solid #EAECF0",
      }}
      className="bg-[#FCFCFD] flex items-center justify-between w-full px-4 h-[52px] min-h-[52px] max-h-[52px]"
    >
      <div className="flex flex-col ">
        <p className="text-[#1D2939] text-xs font-semibold">
          {auth?.user?.company?.name}
        </p>
        <p className="text-[#1D2939] text-xs font-semibold">
          {auth?.user?.company?.email}
        </p>
      </div>
      {type === "table" && <p>{title}</p>}
      <div
        onClick={onClick}
        className="flex items-center justify-center gap-x-2 mr-2 text-secondary-700 text-md"
      >
        <LeftArrow className="w-3 h-3" />
        <span>Back</span>
      </div>
    </div>
  );
};

export default ReportHeader;

import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useAlert, positions } from "react-alert";
import { equipmentService } from "../../services";
import { Loading, AlertModal, NewDatePicker, Input } from "../../components";
import { BsTrash } from "react-icons/bs";
import { useStore } from "../../hooks/useStores";
import { roles } from "../../static/constants";
const CreateNewEquipment = ({
  setNewEquipment,
  selectedEquipment,
  handleEqu,
  setInfoModalOpen,
  setInfoModalData,
}) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const alert = useAlert();
  const schema = yup.object({
    tongManufacturer: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    tongSize: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    tongArmLength: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    torqueSubSerial: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    loadCellSerial: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    loadCellCapacity: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    loadCellSensitivity: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    loadCellCalibrationDate: yup.date().required(`${t("validation:empty")}`),
    threadCount: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    comment: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const all = useWatch({
    control: control,
  });
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);
  useEffect(() => {
    if (selectedEquipment) {
      setValue("comment", selectedEquipment?.comment);
      setValue("threadCount", selectedEquipment?.threadCount);
      setValue("loadCellCapacity", selectedEquipment?.loadCellCapacity);
      setValue(
        "loadCellCalibrationDate",
        new Date(selectedEquipment?.loadCellCalibrationDate)
      );
      setValue("loadCellSensitivity", selectedEquipment?.loadCellSensitivity);
      setValue("loadCellSerial", selectedEquipment?.loadCellSerial);
      setValue("tongArmLength", selectedEquipment?.tongArmLength);
      setValue("tongManufacturer", selectedEquipment?.tongManufacturer);
      setValue("tongSize", selectedEquipment?.tongSize);
      setValue("torqueSubSerial", selectedEquipment?.torqueSubSerial);
    } else {
    }
  }, [selectedEquipment]);
  const deleteEqu = async (id) => {
    if (auth?.user?.role == roles.CA || auth?.user?.role == roles.CS) {
      setLoading(true);
      await equipmentService
        .deleteEqu(selectedEquipment.id)
        .then((res) => {
          if (res.data.code == 0) {
            handleEqu();
            setIsAlert(false);
            setNewEquipment(false);
            setInfoModalData({
              success: true,
              text: `${selectedEquipment?.tongManufacturer} ${t(
                "equipment:deleted"
              )}`,
              header: selectedEquipment?.tongManufacturer,
              label: "OK",
            });
            setInfoModalOpen(true);
          } else {
            setIsAlert(false);
            setNewEquipment(false);
            setInfoModalData({
              success: false,
              text: t("buttons:failed"),
              header: selectedEquipment?.tongManufacturer,
              label: "OK",
            });
            setInfoModalOpen(true);
          }
        })
        .catch((err) => console.log(err));
      setLoading(false);
    } else {
      setNewEquipment(false);
      setIsAlert(false);
      setInfoModalData({
        success: false,
        text: t("form:unauthorized"),
        header: selectedEquipment?.tongManufacturer,
        label: "OK",
      });
      setInfoModalOpen(true);
    }
  };
  const onSubmit = async (data) => {
    if (auth?.user?.role == roles.CA || auth?.user?.role == roles.CS) {
      setLoading(true);
      if (selectedEquipment && selectedEquipment.id) {
        await equipmentService
          .updateEqu(selectedEquipment.id, data)
          .then((res) => {
            if (res.data.code == 0) {
              handleEqu();
              setLoading(false);
              setNewEquipment(false);
              setInfoModalData({
                success: true,
                text: `${data?.tongManufacturer} ${t("equipment:updated")}`,
                header: data?.tongManufacturer,
                label: "OK",
              });
              setInfoModalOpen(true);
            } else {
              setInfoModalData({
                success: false,
                text: t("buttons:failed"),
                header: data?.tongManufacturer,
                label: "OK",
              });
              setInfoModalOpen(true);
            }
          })
          .catch((err) => console.log(err));
      } else {
        await equipmentService
          .createEqu(data)
          .then((res) => {
            if (res.data.code == 0) {
              handleEqu();
              setLoading(false);
              setNewEquipment(false);
              setInfoModalData({
                success: true,
                text: `${data?.tongManufacturer} ${t("equipment:created")}`,
                header: data?.tongManufacturer,
                label: "OK",
              });
              setInfoModalOpen(true);
            } else {
              setInfoModalData({
                success: false,
                text: t("buttons:failed"),
                header: data?.tongManufacturer,
                label: "OK",
              });
              setInfoModalOpen(true);
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      setNewEquipment(false);
      setInfoModalData({
        success: false,
        text: t("form:unauthorized"),
        header: selectedEquipment?.tongManufacturer,
        label: "OK",
      });
      setInfoModalOpen(true);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-start px-4 py-5 gap-4 w-full h-full"
    >
      <div className="flex flex-row items-center px-5 h-14 border-b-[2px] border-gray-200 w-full">
        <div className="flex items-center justify-center">
          <p className="font-medium text-xl">
            {selectedEquipment?.tongManufacturer
              ? `${selectedEquipment?.tongManufacturer}`
              : t("equipment:createNewEquipment")}
          </p>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-16 h-16">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="w-full h-[98.5vh] flex items-start flex-col gap-4 overflow-y-auto scrollbar-hide">
            <div className="flex flex-col items-start w-full">
              <div className="flex flex-col items-start gap-4 w-full">
                <div className="w-full xs:hidden xs:min-w-full flex flex-row items-start gap-6 mt-4">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:tongManufacturer")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.tongManufacturer ? "error" : ""}
                        errorMessage={
                          errors?.tongManufacturer
                            ? errors?.tongManufacturer?.message
                            : ""
                        }
                      />
                    )}
                    name="tongManufacturer"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:tongSize")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.tongSize ? "error" : ""}
                        errorMessage={
                          errors?.tongSize ? errors?.tongSize?.message : ""
                        }
                      />
                    )}
                    name="tongSize"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:tongArmLength")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.tongArmLength ? "error" : ""}
                        errorMessage={
                          errors?.tongArmLength
                            ? errors?.tongArmLength?.message
                            : ""
                        }
                      />
                    )}
                    name="tongArmLength"
                  />
                </div>
                <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full  flex-row items-start gap-6 mt-4">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:tongManufacturer")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.tongManufacturer ? "error" : ""}
                        errorMessage={
                          errors?.tongManufacturer
                            ? errors?.tongManufacturer?.message
                            : ""
                        }
                      />
                    )}
                    name="tongManufacturer"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:tongSize")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.tongSize ? "error" : ""}
                        errorMessage={
                          errors?.tongSize ? errors?.tongSize?.message : ""
                        }
                      />
                    )}
                    name="tongSize"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:tongArmLength")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.tongArmLength ? "error" : ""}
                        errorMessage={
                          errors?.tongArmLength
                            ? errors?.tongArmLength?.message
                            : ""
                        }
                      />
                    )}
                    name="tongArmLength"
                  />
                </div>
                <div className="w-full  xs:hidden xs:min-w-full flex flex-row items-start gap-6">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:torqueSubSerial")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.torqueSubSerial ? "error" : ""}
                        errorMessage={
                          errors?.torqueSubSerial
                            ? errors?.torqueSubSerial?.message
                            : ""
                        }
                      />
                    )}
                    name="torqueSubSerial"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:loadCellSerial")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.loadCellSerial ? "error" : ""}
                        errorMessage={
                          errors?.loadCellSerial
                            ? errors?.loadCellSerial?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellSerial"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:loadCellCapacity")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.loadCellCapacity ? "error" : ""}
                        errorMessage={
                          errors?.loadCellCapacity
                            ? errors?.loadCellCapacity?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellCapacity"
                  />
                </div>
                <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:torqueSubSerial")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.torqueSubSerial ? "error" : ""}
                        errorMessage={
                          errors?.torqueSubSerial
                            ? errors?.torqueSubSerial?.message
                            : ""
                        }
                      />
                    )}
                    name="torqueSubSerial"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:loadCellSerial")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.loadCellSerial ? "error" : ""}
                        errorMessage={
                          errors?.loadCellSerial
                            ? errors?.loadCellSerial?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellSerial"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:loadCellCapacity")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.loadCellCapacity ? "error" : ""}
                        errorMessage={
                          errors?.loadCellCapacity
                            ? errors?.loadCellCapacity?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellCapacity"
                  />
                </div>
                <div className="w-full  xs:hidden xs:min-w-full  flex flex-row items-start gap-6">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:loadCellSensitivity")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.loadCellSensitivity ? "error" : ""}
                        errorMessage={
                          errors?.loadCellSensitivity
                            ? errors?.loadCellSensitivity?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellSensitivity"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NewDatePicker
                        label={t("equipment:cellCalibrationDate")}
                        value={value}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        validate={
                          errors?.loadCellCalibrationDate ? "error" : ""
                        }
                        errorMessage={
                          errors?.loadCellCalibrationDate
                            ? errors?.loadCellCalibrationDate?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellCalibrationDate"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:threadCount")}
                        width={"33%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.threadCount ? "error" : ""}
                        errorMessage={
                          errors?.threadCount
                            ? errors?.threadCount?.message
                            : ""
                        }
                      />
                    )}
                    name="threadCount"
                  />
                </div>
                <div className="w-full  hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:loadCellSensitivity")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.loadCellSensitivity ? "error" : ""}
                        errorMessage={
                          errors?.loadCellSensitivity
                            ? errors?.loadCellSensitivity?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellSensitivity"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NewDatePicker
                        label={t("equipment:cellCalibrationDate")}
                        value={value}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        validate={
                          errors?.loadCellCalibrationDate ? "error" : ""
                        }
                        errorMessage={
                          errors?.loadCellCalibrationDate
                            ? errors?.loadCellCalibrationDate?.message
                            : ""
                        }
                      />
                    )}
                    name="loadCellCalibrationDate"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("equipment:threadCount")}
                        width={"100%"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        validate={errors?.threadCount ? "error" : ""}
                        errorMessage={
                          errors?.threadCount
                            ? errors?.threadCount?.message
                            : ""
                        }
                      />
                    )}
                    name="threadCount"
                  />
                </div>
              </div>
              <div className="w-full flex flex-row items-start gap-6 mt-4">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("equipment:comment")}
                      width={"100%"}
                      style={{
                        numberOfLines: 1,
                      }}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      validate={errors?.comment ? "error" : ""}
                      errorMessage={
                        errors?.comment ? errors?.comment?.message : ""
                      }
                    />
                  )}
                  name="comment"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="absolute bottom-5 right-10 flex flex-row justify-end items-center gap-6 w-full h-[40px]">
        <div className="flex items-end gap-6 h-[40px]">
          {selectedEquipment?.id && (
            <button
              onClick={() => {
                setIsAlert(true);
              }}
              type="button"
              className="w-[144px] xs:w-[42%] h-full bg-red-600 text-white flex flex-row items-center justify-center border shadow rounded-lg"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            onClick={() => setNewEquipment(false)}
            type="button"
            className="w-[144px] h-full bg-white flex flex-row items-center justify-center border shadow rounded-lg text-gray-700"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-[144px] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
          >
            {selectedEquipment ? t("buttons:edit") : t("buttons:save")}
          </button>
        </div>
      </div>
      <AlertModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        text={
          i18next.language == "en"
            ? t("equipment:deleteEquipmentSubTitle") +
              " " +
              selectedEquipment?.tongManufacturer +
              "?"
            : selectedEquipment?.tongManufacturer +
              t("equipment:deleteEquipmentSubTitle")
        }
        icon={<BsTrash className="text-3xl" />}
        header={"upu.jamlog"}
        label={"OK"}
        applyFunction={() => deleteEqu()}
        loading={loading}
      />
    </form>
  );
};
export default CreateNewEquipment;

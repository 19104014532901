import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class ModalStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ModalStore",
      properties: [],
      storage: window.localStorage,
    });
  }

  roleMapping = false;
  dashboard = false;
  companyManagement = false;
  lossesManagement = false;

  setRoleMapping(value) {
    this.roleMapping = value;
  }

  setDashboard(value) {
    this.dashboard = value;
  }
  setCompanyManagement(value) {
    this.companyManagement = value;
  }
  //..............................................................................................................
  setLossesManagement(value) {
    this.lossesManagement = value;
  }
}
const modalStore = new ModalStore();
export { modalStore };

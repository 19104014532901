import React, { useEffect } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import ReportHeader from "./ReportHeader";
import Footer from "./Footer";
import moment from "moment";
const Chart = ({
  //report info
  revNo,
  reportDate,
  pageNo,
  // chart info
  torkChartDtaXAxis,
  torkChartDta,
  rpmChartData,
  rpmChartXAxis,
  //joint info
  startTime,
  endTime,
  sequanceNo,
  jointNo,
  casingNo,
  casingName,
  jointAprt,
  jointAprtLen,
  jointSpecific,
  jointLen,
  jointTotalLen,
  jointRemainingLen,
  jointMud,
  jointTMud,
  comment,
  fTork,
  dTork,
  fTurn,
  turnShld,
  dTurn,
  maxRpm,
  sRpm,
  clockTime,
  duration,
  min,
  max,
  opt,
  maxTurn,
  minTurn,
  shoulderTurn,
  torqueShld,
  status,
  //make up info
  depthTally,
  makeUpStatus,
  //casing cell info
  threadType,
  threadCompond,
  casingSize,
  casingWeight,
  casingGrade,
  cellCapacity,
  cellSensitivity,
  armLength,
  jointComment,
  setJointComment,
  closeModal,
  connectionInfo,
  jamVersion,
}) => {
  useEffect(() => {
    if (comment) {
      setJointComment(comment);
    } else {
      setJointComment("");
    }
  }, []);
  const maxValue = Math.max(...torkChartDta);
  const maxTurnValue = Math.max(...torkChartDtaXAxis);
  const maxRpmTurnValue = Math.max(...rpmChartXAxis);
  const maxRpmValue = Math.max(...rpmChartData);
  let option = {
    height: 320,
    width: 900,
    xAxis: {
      type: "value",
      max:
        Number(maxTurnValue) * 1.25 < Number(maxTurn)
          ? Number(maxTurn) * (1.25).toFixed(2)
          : Number(maxTurnValue) * (1.25).toFixed(2),
    },
    yAxis: {
      type: "value",
      max: Number(max) * (1.5).toFixed(2),
    },
    tooltip: {
      showContent: true,
      trigger: "axis",
      formatter: (data) => {
        return `TURN:<b>${data[0].value[0]}</b><br/>TORQUE: <b>${data[0].value[1]}</b>`;
      },
    },
    series: [
      {
        symbol: "none",
        data: [
          [0, 0],
          ...torkChartDtaXAxis.map((v, i) => {
            if (!(v == 0 && torkChartDta[i] == 0)) {
              return [v, torkChartDta[i]];
            }
          }),
        ],
        type: "line",
        markLine: {
          data: [
            {
              name: "Min Torque",
              lineStyle: {
                color: "#B42318",
                type: "solid",
              },

              xAxis: "D",
              label: {
                formatter: ["{a|Min}", "{b|Torque:{c}}"].join("\n"),
                rich: {
                  a: {
                    color: "#B42318",
                    padding: [0, 0, 5, 5],
                    align: "center",
                  },
                  b: {
                    color: "#B42318",
                    fontWeight: 600,
                    align: "center",
                    padding: [0, 0, 0, 5],
                  },
                },
                color: "#B42318",
                padding: [0, 0, 0, 15],
              },
              yAxis: min,
            },
            {
              name: "OPT Torque",
              xAxis: "D",
              lineStyle: {
                color: "#027A48",
                type: "solid",
              },
              label: {
                formatter: ["{a|OPT}", "{b|Torque:{c}}"].join("\n"),
                rich: {
                  a: {
                    color: "#027A48",
                    padding: [0, 0, 5, 5],
                    align: "center",
                  },
                  b: {
                    color: "#027A48",
                    fontWeight: 600,
                    align: "center",
                    padding: [0, 0, 0, 5],
                  },
                },
                color: "#027A48",
                padding: [0, 0, 0, 15],
              },
              yAxis: opt,
            },
            {
              name: "Max Torque",
              xAxis: "D",
              lineStyle: {
                color: "#B42318",
                type: "solid",
              },
              label: {
                formatter: ["{a|Max}", "{b|Torque:{c}}"].join("\n"),
                rich: {
                  a: {
                    color: "#B42318",
                    padding: [0, 0, 5, 5],
                    align: "center",
                  },
                  b: {
                    color: "#B42318",
                    fontWeight: 600,
                    align: "center",
                    padding: [0, 0, 0, 5],
                  },
                },
                color: "#B42318",
                padding: [0, 0, 0, 15],
              },
              yAxis: max,
            },
            {
              name: "Min Turn",
              xAxis: "D",
              lineStyle: {
                color: "#9E77ED",
                type: "solid",
              },

              label: {
                formatter: "Min Turn: {c}",
                color: "#9E77ED",
                padding: [0, 0, 0, 5],
              },
              xAxis: minTurn,
            },
            {
              name: "Max Turn",
              xAxis: "D",
              lineStyle: {
                color: "#9E77ED",
                type: "solid",
              },

              label: {
                formatter: "Max Turn: {c}",
                color: "#9E77ED",
                padding: [0, 0, 0, 5],
              },
              xAxis: maxTurn,
            },
          ],
        },
        markPoint: {
          name: "Shoulder Delta Turns",
          symbol: "arrow",
          symbolSize: 15,
          symbolRotate: -90,
          itemStyle: {
            color: "#FFA500",
          },
          label: {
            formatter: ["{a|Shoulder Delta}", "{b|Turns:{c}}"].join("\n"),
            rich: {
              a: {
                color: "#FFA500",
                padding: [0, 0, 5, 15],
              },
              b: {
                color: "#FFA500",
                fontWeight: 600,
                align: "center",
                padding: [0, 0, 0, 15],
              },
            },
            show: true,
            position: "right",
          },
          data: [
            {
              name: "SHLDR",
              value: Number(shoulderTurn).toFixed(3),
              xAxis: shoulderTurn,
              yAxis: torqueShld,
            },
          ],
        },
      },
    ],
  };

  let option3 = {
    height: 60,
    width: 900,
    xAxis: {
      type: "value",
      max:
        Number(maxRpmTurnValue) * 1.25 < Number(maxTurn)
          ? Number(maxTurn) * (1.25).toFixed(2)
          : Number(maxRpmTurnValue) * (1.25).toFixed(2),
    },
    yAxis: {
      type: "value",
      max: Number(maxRpmValue) * (1.25).toFixed(2),
    },
    series: [
      {
        symbol: "none",
        data: [
          [
            0,
            rpmChartData.filter((e) => {
              return e != 0;
            })[0],
          ],
          ...rpmChartXAxis.map((v, i) => {
            if (!(v == 0 && rpmChartData[i] == 0)) {
              return [v, rpmChartData[i]];
            }
          }),
        ],
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
            {
              offset: 0,
              color: "#FFFFFF",
            },
            {
              offset: 1,
              color: "#175CD3",
            },
          ]),
        },
        markLine: {
          data: [
            {
              xAxis: "D",
              lineStyle: {
                color: "#9E77ED",
                type: "solid",
              },
              xAxis: minTurn,
            },
            {
              xAxis: "D",
              lineStyle: {
                color: "#9E77ED",
                type: "solid",
              },
              xAxis: maxTurn,
            },
          ],
        },
        type: "line",
      },
    ],
  };
  const jointAprtToString = (aprt) => {
    switch (String(aprt)) {
      case "0":
        return "-";
        break;
      case "1":
        return "Centalizer";
        break;
      case "2":
        return "FC";
        break;
      case "3":
        return "LC";
        break;
      case "4":
        return "DV";
        break;
      case "5":
        return "XO";
        break;
      default:
        break;
    }
  };
  const makeUpInfo = [
    {
      name: "Start Time",
      value: moment(startTime, "X").utc().format("HH:mm:ss"),
    },
    { name: "End Time", value: moment(endTime, "X").utc().format("HH:mm:ss") },
    { name: "Depth / Tally", value: depthTally },
    { name: "Make Up Status", value: makeUpStatus },
  ];

  const casingEquipmentDetails = [
    { name: "Thread Type", value: threadType },
    { name: "Thread Compond", value: threadCompond },
    { name: "Casing Size", value: casingSize },
    { name: "Casing Weight", value: casingWeight },
    { name: "Casing Grade", value: casingGrade },
    { name: "Cell Capacity", value: cellCapacity },
    { name: "Cell Sensitivity", value: cellSensitivity },
    { name: "Arm Length", value: armLength },
  ];

  const jointDetails = [
    { name: "Total Length", value: jointTotalLen },
    { name: "Sequence No", value: sequanceNo },
    { name: "Joint Length", value: jointLen },
    {
      name: "Aparatus",
      value: jamVersion.includes("V1.2")
        ? jointAprt
        : jointAprtToString(jointAprt),
    },
    { name: "Aparatus Length", value: jointAprtLen },
    { name: "Remaining Length", value: jointRemainingLen },
    { name: "Mud", value: jointMud },
    { name: "Total Mud", value: jointTMud },
  ];
  return (
    <div className="max-w-[1120px] w-[1120px] h-[820px] min-h-[820px] flex flex-col border-[1px] border-['red']    ">
      <ReportHeader onClick={closeModal} />
      <div className="flex  bx] border-[#EDF7F8]  flex-col  max-w-[1000px] w-[1000px] mx-auto mt-4 mb-2">
        {/* <div className="text-[#667085] text-[10px]">
          Casings: {connectionInfo}
        </div> */}
        <div className="flex items-center border-[1px] w-[1000px] min-w-[1000px] max-w-[1000px] ">
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[83px] text-[#667085] text-[7px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            Joint No
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[84px] text-[#667085] text-[7px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            Casing No
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[84px] text-[#667085] text-[7px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            Casing Name
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[84px] text-[#667085] text-[7px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            Connected Casings
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[200px] min-w-[200px] text-[#667085] text-[7px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px] ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              Torque ft/lbs
            </div>
            <div className="flex items-center justify-between w-full min-w-full">
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                final
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                shld
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                delta
              </div>
            </div>
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[200px] min-w-[200px] text-[#667085] text-[7px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px] ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              Turn
            </div>
            <div className="flex items-center justify-between min-w-full w-full">
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                final
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                shld
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                delta
              </div>
            </div>
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[126px] min-w-[126px] text-[#667085] text-[7px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px] ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              RPM
            </div>
            <div className="flex items-center justify-between w-full min-w-full">
              <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center ">
                max
              </div>
              <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center ">
                shld
              </div>
            </div>
          </div>

          <div className="h-7 max-h-7 min-h-7 w-[72px] min-w-[72px] max-w-[84px]  text-[#667085] text-[7px] flex items-center justify-center bg-[#F9FAFB] border-r-[1px]">
            Clock Time
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[72px] min-w-[72px] max-w-[83px] text-center px-2 text-[#667085] text-[7px] flex items-center justify-center bg-[#F9FAFB] border-r-[1px]">
            Duration (sec)
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[72px] min-w-[72px] max-w-[83px] text-center px-2 text-[#667085] text-[7px] flex items-center justify-center bg-[#F9FAFB] border-r-[1px]">
            Joint Status
          </div>
        </div>
        {/* table body */}

        <div className="flex items-center w-[1000px] max-w-[1000px] border-b-[1px] border-l-[1px]  ">
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[83px] text-[#667085] text-[9px] justify-center flex  text-center items-center  border-r-[1px]">
            {Number(jointNo).toFixed(1)}
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[84px] text-[#667085] text-[9px] justify-center flex  text-center items-center  border-r-[1px]">
            {casingNo ? casingNo : "N/A"}
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[84px] text-[#667085] text-[9px] justify-center flex  text-center items-center  border-r-[1px]">
            {casingName ? casingName : "N/A"}
          </div>
          <div className="h-7 max-h-7 min-h-7 w-[65px] min-w-[65px] max-w-[84px] text-[#667085] text-[9px] justify-center flex  text-center items-center  border-r-[1px]">
            {connectionInfo ? connectionInfo : "N/A"}
          </div>
          <div className="flex items-center justify-between w-full max-w-[200px] min-w-[200px] h-7 max-h-7 min-h-7">
            <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {fTork ? Number(fTork).toFixed(2) : Number(0).toFixed(2)}
            </div>
            <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {torqueShld
                ? Number(torqueShld).toFixed(2)
                : Number(0).toFixed(2)}
            </div>
            <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {dTork ? Number(dTork).toFixed(2) : Number(0).toFixed(2)}
            </div>
          </div>

          <div className="flex items-center justify-between w-full max-w-[200px] min-w-[200px] h-7 max-h-7 min-h-7">
            <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {fTurn ? Number(fTurn).toFixed(2) : Number(0).toFixed(2)}
            </div>
            <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {turnShld ? Number(turnShld).toFixed(2) : Number(0).toFixed(2)}
            </div>
            <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {dTurn ? Number(dTurn).toFixed(2) : Number(0).toFixed(2)}
            </div>
          </div>
          <div className="flex items-center justify-between w-full max-w-[126px] min-w-[126px] h-7 max-h-7 min-h-7">
            <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {maxRpm ? Number(maxRpm).toFixed(2) : Number(0).toFixed(2)}
            </div>
            <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
              {sRpm ? Number(sRpm).toFixed(2) : Number(0).toFixed(2)}
            </div>
          </div>
          <div className="w-[72px] min-w-[72px] text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ">
            {moment(clockTime, "X").utc().format("HH:mm:ss")}
          </div>
          <div
            className={`w-[72px] min-w-[72px] max-w-[83px] text-center flex items-center justify-center border-r-[1px] text-[9px] h-full  `}
          >
            {duration}
          </div>
          <div
            className={`w-[72px] min-w-[72px] max-w-[83px] text-center flex items-center justify-center border-r-[1px] text-[9px] h-full ${
              status == "ACCEPT"
                ? "text-[#039855]"
                : status == "BREAKOUT"
                ? "text-[#026AA2]"
                : "text-[#D92D20]"
            } `}
          >
            {status}
          </div>
        </div>
      </div>

      <div className=" w-[100%] mx-auto   flex flex-col -mt-[50px]  ">
        <ReactECharts
          option={option}
          min={500}
          max={1000}
          opt={700}
          style={{
            height: "560px",
          }}
        />
        <p className="absolute -rotate-90 text-[#344054] font-semibold  text-xs mt-[250px]">
          Torque (ft/lbs)
        </p>
        <div className="w-full relative -mt-[85px]  ">
          <ReactECharts
            style={{
              height: "140px",
              marginTop: "-120px",
            }}
            option={option3}
          />
          <p className="absolute -rotate-90 text-[#1849A9] ml-6 font-semibold  text-xs -mt-[60px]">
            RPM
          </p>
        </div>
      </div>

      <div className="flex items-start mt-2   justify-center gap-x-3  w-[1000px] min-w-[1000px] max-w-[1000px] mx-auto ">
        <div className="flex flex-col w-1/3 border-[1px] rounded-lg  px-2 py-1.5">
          <p className="text-[#344054] text-center text-[10px] font-semibold mb-1">
            Make Up Details
          </p>
          {makeUpInfo.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex items-center justify-between ${
                  index % 2 === 0 ? "bg-[#EDF7F8]" : ""
                }`}
              >
                <p className="text-[#344054] text-[8px] font-semibold px-1.5 py-1">
                  {item?.name}
                </p>
                <p className="text-secondary-500 text-[8px] font-medium px-1.5 py-1">
                  {item?.value}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col w-2/3 border-[1px] rounded-lg  px-2 py-1.5">
          <p className="text-[#344054] text-center text-[10px] font-semibold mb-1">
            Joint Details
          </p>
          <div className="flex items-center flex-wrap w-full">
            {jointDetails.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between w-1/2 ${
                    index % 4 == 1 || index % 4 == 0 ? "bg-[#EDF7F8]" : ""
                  }`}
                >
                  <p className="text-[#344054] text-[8px] font-semibold px-1.5 py-1">
                    {item?.name}
                  </p>
                  <p className="text-secondary-500 text-[8px] font-medium px-1.5 py-1">
                    {item?.value}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col w-2/3 border-[1px] rounded-lg  px-2 py-1.5">
          <p className="text-[#344054] text-center text-[10px] font-semibold mb-1">
            Casing & Equipment Details
          </p>
          <div className="flex items-center flex-wrap w-full">
            {casingEquipmentDetails.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between w-1/2 ${
                    index % 4 == 1 || index % 4 == 0 ? "bg-[#EDF7F8]" : ""
                  }`}
                >
                  <p className="text-secondary-700 text-[8px] font-semibold px-1.5 py-1">
                    {item?.name}
                  </p>
                  <p className="text-secondary-500 text-[8px] font-medium px-1.5 py-1">
                    {item?.value}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="max-w-[1000px] w-[1000px] mx-auto mt-1.5">
        <label
          style={{ width: "100%" }}
          className="text-gray-700 h-[60px] text-xs font-medium leading-3 flex flex-col relative"
        >
          Joint Comment
          <input
            onChange={(e) => setJointComment(e.target.value)}
            value={jointComment}
            className={`h-8 py-2.5 px-3.5 bg-white border border-gray-300 shadow-sm rounded-lg text-xs text-gray-600 focus:outline-none mt-1.5`}
          />
        </label>
      </div>
      <Footer revNo={revNo} pageNo={pageNo} reportDate={reportDate} />
    </div>
  );
};

export default Chart;

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/Inputs/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import NewDatePicker from "../../components/Inputs/DatePicker/DatePicker";
import Select from "../../components/Select/Select";
import { useStore } from "../../hooks/useStores";
const CreateNewJob = ({
  selectedJob,
  setNewJob,
  setNewLot,
  setJobInfo,
  jobInfo,
  equipments,
}) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  // Equipments Select Options
  const opt = equipments?.map((eq, i) => {
    return {
      value: eq.id,
      label: `${eq.tongSize ? eq.tongSize : "-"}-${eq.tongManufacturer}`,
    };
  });
  const schema = yup.object({
    companyRep: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    jobName: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    deliveryDate: yup.date().notRequired(`${t("validation:empty")}`),
    lease: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    firstTech: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    secondTech: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    rig: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    well: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    wellDepth: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingUp: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingShoe: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    ratHole: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    casingCompany: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    casingSuper: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    threadRep: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    // stabTechnician: yup
    //   .string()
    //   .notRequired(`${t("validation:empty")}`)
    //   .typeError(`${t("validation:string")}`),
    tongOperator: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    // threadRepCompany: yup
    //   .string()
    //   .notRequired(`${t("validation:empty")}`)
    //   .typeError(`${t("validation:string")}`),
    flotation: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    // squeeze: yup
    //   .number()
    //   .notRequired(`${t("validation:empty")}`)
    //   .typeError(`${t("validation:number")}`),
    ppcf: yup
      .number()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    equipmentData: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (selectedJob?.id) {
      setValue("rig", selectedJob?.rig);
      setValue("well", selectedJob?.well);
      setValue("wellDepth", selectedJob?.wellDepth);
      setValue("deliveryDate", new Date(selectedJob?.deliveryDate));
      setValue("ppcf", selectedJob?.ppcf);
      setValue("flotation", selectedJob?.flotation);
      setValue("casingUp", selectedJob?.casingUp);
      setValue("casingShoe", selectedJob?.casingShoe);
      setValue("ratHole", selectedJob?.ratHole);
      setValue("companyRep", selectedJob?.companyRep);
      setValue("jobName", selectedJob?.jobName);
      setValue("lease", selectedJob?.lease);
      setValue("firstTech", selectedJob?.firstTech);
      setValue("secondTech", selectedJob?.secondTech);
      setValue("casingCompany", selectedJob?.casingCompany);
      setValue("casingSuper", selectedJob?.casingSuper);
      // setValue("stabTechnician", selectedJob?.stabTechnician);
      setValue("tongOperator", selectedJob?.tongOperator);
      // setValue("threadRepCompany", selectedJob?.threadRepCompany);
      setValue("threadRep", selectedJob?.threadRep);
      // setValue("squeeze", selectedJob?.squeeze);
      setValue("equipmentData", selectedJob?.equipmentData?.id);
    } else {
    }
  }, [selectedJob]);
  const onSubmit = (data) => {
    setJobInfo(data);
    setTimeout(() => {
      setNewLot(true);
    }, 500);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-start px-4 py-5 gap-4 w-full h-full"
    >
      <div
        style={{
          boxShadow:
            "0px 1px 0px rgba(16, 24, 40, 0.1), 0px 1px 0px rgba(16, 24, 40, 0.06)",
        }}
        className="flex flex-row items-center px-4 h-14 border-b-[1px] border-gray-200 w-full"
      >
        <div className="flex items-center justify-center">
          <p className="font-medium text-xl">
            {selectedJob?.jobName
              ? `${selectedJob?.jobName}`
              : t("job:createNewJob")}
          </p>
        </div>
      </div>
      <div className="w-full h-[98.5vh] flex items-start flex-col gap-4 overflow-y-auto  border-b scrollbar-hide border-secondary-300">
        <div className="flex flex-row items-center px-1 border-b-[1px] border-gray-200 w-full my-2">
          <div className="flex items-center justify-center mb-3">
            <p className="font-medium">{t("job:jobInformation")}</p>
          </div>
        </div>
        <div className="flex flex-col items-start w-full">
          <div className="w-full flex flex-row xs:hidden xs:min-w-full items-start gap-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:rig")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.rig ? "error" : ""}
                  errorMessage={errors?.rig ? errors?.rig?.message : ""}
                />
              )}
              name="rig"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:well")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.well ? "error" : ""}
                  errorMessage={errors?.well ? errors?.well?.message : ""}
                />
              )}
              name="well"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <NewDatePicker
                  label={t("job:dateRecord")}
                  value={value}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.deliveryDate ? "error" : ""}
                  errorMessage={
                    errors?.deliveryDate ? errors?.deliveryDate?.message : ""
                  }
                  minDate={true}
                />
              )}
              name="deliveryDate"
            />
          </div>
          <div className="w-full  flex-row hidden xs:flex xs:flex-col xs:min-w-full items-start gap-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:rig")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.rig ? "error" : ""}
                  errorMessage={errors?.rig ? errors?.rig?.message : ""}
                />
              )}
              name="rig"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:well")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.well ? "error" : ""}
                  errorMessage={errors?.well ? errors?.well?.message : ""}
                />
              )}
              name="well"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <NewDatePicker
                  label={t("job:dateRecord")}
                  value={value}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.deliveryDate ? "error" : ""}
                  errorMessage={
                    errors?.deliveryDate ? errors?.deliveryDate?.message : ""
                  }
                  minDate={true}
                />
              )}
              name="deliveryDate"
            />
          </div>
          <div className="w-full flex flex-row xs:hidden xs:min-w-full xs:mx-auto items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:wellDepth")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.wellDepth ? "error" : ""}
                  errorMessage={
                    errors?.wellDepth ? errors?.wellDepth?.message : ""
                  }
                />
              )}
              name="wellDepth"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:mudWeight")}
                  width={"33%"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.ppcf ? "error" : ""}
                  errorMessage={errors?.ppcf ? errors?.ppcf?.message : ""}
                />
              )}
              name="ppcf"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:flotation")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.flotation ? "error" : ""}
                  errorMessage={
                    errors?.flotation ? errors?.flotation?.message : ""
                  }
                />
              )}
              name="flotation"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row  xs:mx-auto items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:wellDepth")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.wellDepth ? "error" : ""}
                  errorMessage={
                    errors?.wellDepth ? errors?.wellDepth?.message : ""
                  }
                />
              )}
              name="wellDepth"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:mudWeight")}
                  width={"100%"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.ppcf ? "error" : ""}
                  errorMessage={errors?.ppcf ? errors?.ppcf?.message : ""}
                />
              )}
              name="ppcf"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:flotation")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.flotation ? "error" : ""}
                  errorMessage={
                    errors?.flotation ? errors?.flotation?.message : ""
                  }
                />
              )}
              name="flotation"
            />
          </div>
          <div className="w-full flex flex-row xs:hidden xs:min-w-full  items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingTopLeft")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingUp ? "error" : ""}
                  errorMessage={
                    errors?.casingUp ? errors?.casingUp?.message : ""
                  }
                />
              )}
              name="casingUp"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingShoeDepth")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingShoe ? "error" : ""}
                  errorMessage={
                    errors?.casingShoe ? errors?.casingShoe?.message : ""
                  }
                />
              )}
              name="casingShoe"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:ratHole")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.ratHole ? "error" : ""}
                  errorMessage={errors?.ratHole ? errors?.ratHole?.message : ""}
                />
              )}
              name="ratHole"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingTopLeft")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingUp ? "error" : ""}
                  errorMessage={
                    errors?.casingUp ? errors?.casingUp?.message : ""
                  }
                />
              )}
              name="casingUp"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingShoeDepth")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingShoe ? "error" : ""}
                  errorMessage={
                    errors?.casingShoe ? errors?.casingShoe?.message : ""
                  }
                />
              )}
              name="casingShoe"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:ratHole")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.ratHole ? "error" : ""}
                  errorMessage={errors?.ratHole ? errors?.ratHole?.message : ""}
                />
              )}
              name="ratHole"
            />
          </div>
          <div className="flex flex-row items-center px-1 border-b-[1px] border-gray-200 w-full mb-2 mt-4">
            <div className="flex items-center justify-center mb-3">
              <p className="font-medium">{t("job:fieldInformation")}</p>
            </div>
          </div>
          <div className="w-full flex flex-row xs:hidden xs:min-w-full items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:jobName")}
                  onChange={onChange}
                  onBlur={onBlur}
                  width={"33%"}
                  value={value}
                  validate={errors?.jobName ? "error" : ""}
                  errorMessage={errors?.jobName ? errors?.jobName?.message : ""}
                />
              )}
              name="jobName"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingCompany")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingCompany ? "error" : ""}
                  errorMessage={
                    errors?.casingCompany ? errors?.casingCompany?.message : ""
                  }
                />
              )}
              name="casingCompany"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingSupervisor")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingSuper ? "error" : ""}
                  errorMessage={
                    errors?.casingSuper ? errors?.casingSuper?.message : ""
                  }
                />
              )}
              name="casingSuper"
            />
          </div>
          <div className="w-full flex-row hidden xs:flex xs:flex-col xs:min-w-full items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:jobName")}
                  onChange={onChange}
                  onBlur={onBlur}
                  width={"100%"}
                  value={value}
                  validate={errors?.jobName ? "error" : ""}
                  errorMessage={errors?.jobName ? errors?.jobName?.message : ""}
                />
              )}
              name="jobName"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingCompany")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingCompany ? "error" : ""}
                  errorMessage={
                    errors?.casingCompany ? errors?.casingCompany?.message : ""
                  }
                />
              )}
              name="casingCompany"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:casingSupervisor")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.casingSuper ? "error" : ""}
                  errorMessage={
                    errors?.casingSuper ? errors?.casingSuper?.message : ""
                  }
                />
              )}
              name="casingSuper"
            />
          </div>
          <div className="w-full flex xs:hidden xs:min-w-full flex-row items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:tongOperator")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.tongOperator ? "error" : ""}
                  errorMessage={
                    errors?.tongOperator ? errors?.tongOperator?.message : ""
                  }
                />
              )}
              name="tongOperator"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  placeholder={t("equipment:equipment")}
                  onBlur={onBlur}
                  width={"33%"}
                  items={opt}
                  onChange={(v) => {
                    setValue("equipmentData", v);
                  }}
                  value={value}
                  validate={errors.equipmentData ? "error" : ""}
                  errorMessage={
                    errors.equipmentData ? errors.equipmentData.message : ""
                  }
                />
              )}
              name="equipmentData"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:companyRepresentative")}
                  onChange={onChange}
                  onBlur={onBlur}
                  width={"33%"}
                  value={value}
                  validate={errors?.companyRep ? "error" : ""}
                  errorMessage={
                    errors?.companyRep ? errors?.companyRep?.message : ""
                  }
                />
              )}
              name="companyRep"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:tongOperator")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.tongOperator ? "error" : ""}
                  errorMessage={
                    errors?.tongOperator ? errors?.tongOperator?.message : ""
                  }
                />
              )}
              name="tongOperator"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  placeholder={t("equipment:equipment")}
                  onBlur={onBlur}
                  width={"33%"}
                  items={opt}
                  onChange={(v) => {
                    setValue("equipmentData", v);
                  }}
                  value={value}
                  validate={errors.equipmentData ? "error" : ""}
                  errorMessage={
                    errors.equipmentData ? errors.equipmentData.message : ""
                  }
                />
              )}
              name="equipmentData"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:companyRepresentative")}
                  onChange={onChange}
                  onBlur={onBlur}
                  width={"33%"}
                  value={value}
                  validate={errors?.companyRep ? "error" : ""}
                  errorMessage={
                    errors?.companyRep ? errors?.companyRep?.message : ""
                  }
                />
              )}
              name="companyRep"
            />
          </div>

          <div className="w-full  xs:hidden xs:min-w-full flex flex-row items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:lease")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.lease ? "error" : ""}
                  errorMessage={errors?.lease ? errors?.lease?.message : ""}
                />
              )}
              name="lease"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:firstTechnician")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.firstTech ? "error" : ""}
                  errorMessage={
                    errors?.firstTech ? errors?.firstTech?.message : ""
                  }
                />
              )}
              name="firstTech"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:secondTechnician")}
                  width={"33%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.secondTech ? "error" : ""}
                  errorMessage={
                    errors?.secondTech ? errors?.secondTech?.message : ""
                  }
                />
              )}
              name="secondTech"
            />
          </div>
          <div className="w-full hidden xs:flex xs:flex-col xs:min-w-full flex-row items-start gap-6 mt-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:lease")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.lease ? "error" : ""}
                  errorMessage={errors?.lease ? errors?.lease?.message : ""}
                />
              )}
              name="lease"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:firstTechnician")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.firstTech ? "error" : ""}
                  errorMessage={
                    errors?.firstTech ? errors?.firstTech?.message : ""
                  }
                />
              )}
              name="firstTech"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("job:secondTechnician")}
                  width={"100%"}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  validate={errors?.secondTech ? "error" : ""}
                  errorMessage={
                    errors?.secondTech ? errors?.secondTech?.message : ""
                  }
                />
              )}
              name="secondTech"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center gap-6 w-full h-[40px]">
        <div className="flex items-end gap-6 h-[40px]">
          <button
            onClick={() => setNewJob(false)}
            type="button"
            className="w-[144px] h-full bg-white flex flex-row items-center justify-center border shadow rounded-lg text-gray-700"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-[144px] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
          >
            {t("buttons:next")}
          </button>
        </div>
      </div>
    </form>
  );
};
export default CreateNewJob;

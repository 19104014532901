import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { CloseIcon } from "../../assets/icons";
const QuickAddModal = ({
  isOpen,
  setIsOpen,
  lots,
  jobInfo,
  setJoints,
  joints,
  setSelectedCasing,
  selectedCasing,
}) => {
  const { t } = useTranslation();
  function closeModal() {
    reset({
      lotNo: "",
      casingQty: "",
      totalLength: "",
    });
    setIsOpen(false);
  }
  const schema = yup.object({
    lotNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    casingQty: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    totalLength: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const calculateWmud = (joi) => {
    let wei;
    let flo = jobInfo?.flotation ? jobInfo?.flotation : 0;
    if (selectedCasing?.weight) {
      wei = selectedCasing?.weight ? selectedCasing?.weight : 0;
    } else {
      wei = joi?.lot?.weight ? joi?.lot?.weight : 0;
    }
    const res = Number(joi?.lengthVal) * Number(wei) * Number(flo) * 0.00148;
    return res.toFixed(2);
  };
  const onSubmit = (data) => {
    const mapper = joints
      ?.map((j) => {
        if (Number(j.casingName)) {
          return j.casingName;
        } else {
          return null;
        }
      })
      ?.filter((n) => {
        return n !== null;
      })
      ?.reverse();
    const lastCasingName = mapper[0] ? mapper[0] : 0;
    const quickJoints = [...Array(data.casingQty)].map((n, i) => {
      return {
        lotNo: data.lotNo,
        lengthVal: Number(data.totalLength) / Number(data.casingQty),
        casingNo: joints.length + i + 1,
        casingName: String(Number(lastCasingName) + i + 1),
        centralizer: false,
        wMud: Number(
          calculateWmud({
            lengthVal: Number(data.totalLength) / Number(data.casingQty),
          })
        ).toFixed(2),
      };
    });
    const res = [...joints, ...quickJoints].sort((a, b) => {
      return a.casingNo - b.casingNo;
    });
    setJoints(res);
    closeModal();
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999] flex" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full  w-auto  items-center justify-center sm:p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-auto xs:mt-40 sm:mt-40 transform overflow-hidden rounded-2xl bg-white p-2 xs:px-0 text-left align-middle shadow-xl transition-all">
                  <div className="h-full overflow-auto w-full xs:w-fit max-w-[450px] max-h-[360px] min-h-[360px]">
                    <div className="min-h-[360px] flex flex-col justify-between items-center min-w-[400px] xs:min-w-fit max-w-[450px] p-3">
                      <div className="flex items-center justify-between w-full">
                        <div className="font-medium text-[18px] text-[#101828] self-start">
                          {t("job:quickAdd")}
                        </div>
                        <div
                          onClick={() => closeModal()}
                          className="w-4 h-4 flex items-center justify-center cursor-pointer"
                        >
                          <CloseIcon width={10} height={10} />
                        </div>
                      </div>
                      <form
                        className="w-full flex flex-col items-center justify-center gap-y-2"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="w-full flex flex-col font-medium text-[16px] gap-y-4">
                          <Controller
                            control={control}
                            className
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <div className="w-full flex flex-col text-left gap-3">
                                <Select
                                  placeholder={t("job:made")}
                                  items={lots.map((n) => {
                                    return {
                                      value: n.lotNo,
                                      label: `${n?.size}" ${n?.grade} ${n?.weight} lb/ft ${n?.typeConn}`,
                                    };
                                  })}
                                  width={"100%"}
                                  fontSize={14}
                                  value={value}
                                  onChange={(v) => {
                                    setSelectedCasing(
                                      lots.filter((c, i) => {
                                        return c.lotNo == v;
                                      })[0]
                                    );
                                    setValue("lotNo", v);
                                  }}
                                />
                              </div>
                            )}
                            name="lotNo"
                          />
                        </div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              label={t("job:totalLength")}
                              width={"100%"}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              validate={errors.totalLength ? "error" : ""}
                              errorMessage={
                                errors.totalLength
                                  ? errors.totalLength.message
                                  : ""
                              }
                            />
                          )}
                          name="totalLength"
                        />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              label={t("job:casingQty")}
                              width={"100%"}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              validate={errors?.casingQty ? "error" : ""}
                              errorMessage={
                                errors?.casingQty
                                  ? errors?.casingQty?.message
                                  : ""
                              }
                            />
                          )}
                          name="casingQty"
                        />
                        <div className="w-full flex justify-center gap-x-3 mt-3">
                          <button
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="w-full max-w-[98%] p-2.5 rounded-lg flex items-center justify-center border shadow bg-white text-sm text-gray-700"
                          >
                            {t("buttons:stop")}
                          </button>
                          <button
                            type="submit"
                            className={`bg-teal-700 text-white w-full max-w-[98%] p-2.5 rounded-lg`}
                          >
                            {t("buttons:add")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default QuickAddModal;

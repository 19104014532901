import { instance } from "../libs/client";
/* eslint-disable camelcase */

class CompanyService {
  addNewIndustry = async (names) => {
    return instance.post("company/industry", {
      name: names,
    });
  };
  listIndustry = async () => {
    return instance.get("company/industry");
  };
  listAllCompanies = async () => {
    return instance.get("company/all");
  };
  listCompanies = async () => {
    return instance.get("company/");
  };
  addCompany = async (data) => {
    return instance.post("company/", data);
  };
  updateCompany = async (id, data) => {
    return instance.put(`/company/${id}`, data);
  };
  updateIndustry = async (id, data) => {
    return instance.put(`/company/industry/${id}`, data);
  };
  deleteCompany = async (id) => {
    return instance.post(`/company/delete/${id}`);
  };
  listCompanies = async () => {
    return instance.get("company/");
  };
  listAllUsers = async () => {
    return instance.get("company/allUsers");
  };
  //   list = () => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get("/company")
  //         .then((response) => {
  //           resolve(response.data);
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   };

  //   getCompany = (id) => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(`/company/${id}`)
  //         .then((response) => {
  //           resolve(response.data);
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   };

  //   oldAddNewIndustry = (model) => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .post("/company/industry", model)
  //         .then((response) => {
  //           resolve(response.data);
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   };

  //   getIndustry = (id) => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(`/company/industry/${id}`)
  //         .then((response) => {
  //           resolve(response.data);
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   };

  //   deleteIndustry = (id) => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .delete(`/company/industry/delete/${id}`)
  //         .then((response) => {
  //           resolve(response.data);
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   };

  //   listCompanyUsers = (id) => {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(`/company/users/${id}`)
  //         .then((response) => {
  //           resolve(response.data);
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   };
}

const companyService = new CompanyService();

export default companyService;

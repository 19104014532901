import "./App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AuthNavigation, Login, ChangePassword } from "./screens/Auth";
import { Dashboard, DashboardNavigation, UserList } from "./screens";
import "./services/i18n";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/AlertTemplate/AlertTemplate";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import {
  IndustryIcon,
  casingListIcon,
  jobListIcon,
  equipmentListIcon,
  ReportsIcon,
  MapIcon,
  UsersIcon,
  cvsUploadIcon,
  CustomerIcon,
} from "./assets/icons";
import Report from "./screens/reportComponents/Report";
import JobList from "./screens/JobComponents/JobList";
import CasingList from "./screens/Casing/CasingList";
import EquipmentList from "./screens/Equipments/EquipmentList";
import LogList from "./screens/Logs/LogList";
import ReportList from "./screens/ReportsPage/ReportList";
import { useStore } from "./hooks/useStores";
import { roles } from "./static/constants";
import CustomerList from "./screens/Customer/CustomerList";
function App() {
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_LEFT,
    timeout: 2500,
    offset: "20px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };
  const { t } = useTranslation();
  const { auth } = useStore();
  const [routes, setRoutes] = useState([
    {
      path: "/auth",
      name: "auth",
      element: AuthNavigation,
      isPrivate: false,
      subRoutes: [
        {
          path: "login",
          name: t("auth:login"),
          element: Login,
        },
      ],
    },
    {
      path: "/dashboard",
      name: "dashboard",
      isPrivate: true,
      element: DashboardNavigation,
      Dashboard,
      subRoutes: [
        {
          path: "map",
          name: "map",
          element: Dashboard,
          icon: MapIcon,
          isVisible: true,
          isPrivate: true,
          roles: ["COMPANY_ADMIN", "COMPANY_SUPERVISOR", "CUSTOMER_SUPERVISOR"],
        },
        {
          path: "report-list",
          name: "reportList",
          icon: cvsUploadIcon,
          element: ReportList,
          isVisible: true,
          isPrivate: true,
          roles: ["COMPANY_ADMIN", "COMPANY_SUPERVISOR"],
        },
        {
          path: "job-list",
          name: "jobList",
          icon: jobListIcon,
          element: JobList,
          isVisible: true,
          isPrivate: true,
          roles: [
            "COMPANY_ADMIN",
            "COMPANY_SUPERVISOR",
            "CUSTOMER_SUPERVISOR",
            "COMPANY_TALLYMAN",
          ],
        },
        {
          path: "casing-list",
          name: "casingList",
          icon: casingListIcon,
          element: CasingList,
          isVisible: true,
          isPrivate: true,
          roles: [
            "COMPANY_ADMIN",
            "COMPANY_SUPERVISOR",
            "CUSTOMER_SUPERVISOR",
            "COMPANY_TALLYMAN",
          ],
        },
        {
          path: "equipment-list",
          name: "equipmentList",
          icon: equipmentListIcon,
          element: EquipmentList,
          isVisible: true,
          isPrivate: true,
          roles: ["COMPANY_ADMIN", "COMPANY_SUPERVISOR"],
        },
        {
          path: "users",
          name: "userList",
          icon: UsersIcon,
          element: UserList,
          isVisible: true,
          isPrivate: true,
          roles: ["COMPANY_ADMIN", "COMPANY_SUPERVISOR"],
        },
        {
          path: "log-list",
          name: "log",
          icon: ReportsIcon,
          element: LogList,
          isVisible: true,
          isPrivate: true,
          roles: ["COMPANY_ADMIN", "COMPANY_SUPERVISOR"],
        },
        {
          path: "customer-list",
          name: "customerList",
          icon: CustomerIcon,
          element: CustomerList,
          isVisible: true,
          isPrivate: true,
          roles: ["COMPANY_ADMIN"],
        },
        {
          path: "report",
          name: "report",
          icon: IndustryIcon,
          element: Report,
          isVisible: false,
          isPrivate: true,
        },
        {
          path: "change-password",
          name: t("auth:changePassword"),
          element: ChangePassword,
          isPrivate: true,
          isVisible: false,
        },
      ],
    },
  ]);

  return (
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <Routes>
          {routes.map((route, i) => {
            return (
              <Route
                key={i}
                path={route.path}
                element={
                  route.isPrivate ? (
                    <PrivateRoute>
                      <route.element routes={route?.subRoutes} />
                    </PrivateRoute>
                  ) : (
                    <route.element routes={route?.subRoutes} />
                  )
                }
              >
                {route.subRoutes?.map((subRoute, key) => {
                  return (
                    <Route
                      key={key}
                      path={subRoute.path}
                      element={
                        subRoute.isPrivate ? (
                          <PrivateRoute>
                            <subRoute.element routes={subRoute?.subRoutes} />
                          </PrivateRoute>
                        ) : (
                          <subRoute.element routes={subRoute?.subRoutes} />
                        )
                      }
                    ></Route>
                  );
                })}
              </Route>
            );
          })}
          <Route path={"*"} element={<Navigate to={"/auth/login"} />} />
        </Routes>
      </AlertProvider>
    </BrowserRouter>
  );
}

export default App;

import { makeAutoObservable } from "mobx";

class JobStore {
  constructor() {
    makeAutoObservable(this);
  }
  casingList = [];
  setCasingList = (data) => {
    this.casingList = data;
  };
}
const jobStore = new JobStore();
export { jobStore };

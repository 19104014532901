import { instance } from "../libs/client";

class EquipmentService {
  createEqu = async (data) => {
    return instance.post(`equipment/`, data);
  };
  getEquipments = async () => {
    return instance.get(`equipment/`);
  };
  updateEqu = async (id, data) => {
    return instance.put(`equipment/${id}`, data);
  };
  deleteEqu = async (id) => {
    return instance.delete(`equipment/${id}`);
  };
  getEquipmentsPaginated = async (pageNo, search = "") => {
    return instance.get(
      `equipment/paginated?page=${pageNo}${search ? `&search=${search}` : ""}`
    );
  };
}
const equipmentService = new EquipmentService();
export { equipmentService };

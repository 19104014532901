import React, { useState } from "react";
import { LeftArrow } from "../../assets/icons";
import JointChartModal from "./JointChartModal";
import { useAlert, positions } from "react-alert";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ModalTemplate } from "../../components";
const JointTable = ({ item, jobData, joints, setJoints, tableData }) => {
  const { t } = useTranslation();
  const { report } = useStore();
  const alert = useAlert();
  const [selectedJoint, setSelectedJoint] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const findConnectedLot = (b, key) => {
    const connectedLot = jobData?.filter((data, i) => {
      return data?.lotData?.lotNO == b?.JAMlotno;
    })[0];
    return connectedLot?.lotData[key];
  };
  const updateJointData = (b, n) => {
    const connectedLot = jobData?.filter((data, i) => {
      return data?.lotData?.lotNO == b?.JAMlotno;
    })[0];
    const foundedJoint = connectedLot?.value?.filter((data, i) => {
      return data?.tableData?.JAMJointN == b?.JAMJointN;
    })[0];
    let maxTork = document.getElementById(`maxTork${n}`).value;
    let minTork = document.getElementById(`minTork${n}`).value;
    let optTork = document.getElementById(`optTork${n}`).value;
    let maxTurn = document.getElementById(`maxTurn${n}`).value;
    let minTurn = document.getElementById(`minTurn${n}`).value;
    if (maxTork != 0) {
      foundedJoint.tableData.maxTork = maxTork;
    }
    if (minTork != 0) {
      foundedJoint.tableData.minTork = minTork;
    }
    if (optTork != 0) {
      foundedJoint.tableData.optTork = optTork;
    }
    if (maxTurn != 0) {
      foundedJoint.tableData.maxTurn = maxTurn;
    }
    if (minTurn != 0) {
      foundedJoint.tableData.minTurn = minTurn;
    }
    if (foundedJoint) {
    }
    report.setReportData(report.reportData);
  };
  const findJointData = (b, n) => {
    const connectedLot = jobData?.filter((data, i) => {
      return data?.lotData?.lotNO == b?.JAMlotno;
    })[0];
    const foundedJoint = connectedLot?.value?.filter((data, i) => {
      return data?.tableData?.JAMJointN == b?.JAMJointN;
    })[0];
    if (foundedJoint) {
      setSelectedJoint(foundedJoint);
      setIsOpen(true);
      report.setReportData(report.reportData);
    } else {
      alert.error(t("job:noChartDataJoint"), {
        position: positions.TOP_LEFT,
      });
    }
  };
  return (
    <div className="flex flex-col items-center p-2 pb-4 w-full h-full">
      <div className="border mt-2 flex flex-col w-full h-full items-center rounded-lg border-secondary-200 shadow-sm">
        <div className="flex items-center w-full h-11 border-b">
          <div className="h-11 w-[56px] min-w-[56px] max-w-[56px] text-[#667085] text-[10px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            Joint No
          </div>
          <div className="h-11 w-[168px] min-w-[168px] text-[#667085] text-[10px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px]  ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              Torque ft/lbs
            </div>
            <div className="flex items-center justify-between w-full min-w-full">
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                final
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                shld
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                delta
              </div>
            </div>
          </div>
          <div className="h-11 w-[168px] min-w-[168px] text-[#667085] text-[10px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px] ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              Turn
            </div>
            <div className="flex items-center justify-between min-w-full w-full">
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                final
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                shld
              </div>
              <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center ">
                delta
              </div>
            </div>
          </div>
          <div className="h-11 w-[112px] min-w-[112px] text-[#667085] text-[10px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px] ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              RPM
            </div>
            <div className="flex items-center justify-between w-full min-w-full">
              <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center ">
                max
              </div>
              <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center ">
                shld
              </div>
            </div>
          </div>
          <div className="h-11 w-[56px] min-w-[56px] max-w-[56px] text-center px-2 text-[#667085] text-[10px] flex items-center justify-center bg-[#F9FAFB] border-r-[1px]">
            Duration (sec)
          </div>
          <div className="h-11 w-[56px] min-w-[56px] max-w-[56px] text-center text-[#667085] text-[10px] flex items-center justify-center bg-[#F9FAFB] border-r-[1px]">
            Clock Time
          </div>
          <div className="h-11 w-[56px] min-w-[56px] max-w-[56px] text-[#667085] text-center text-[10px] flex items-center justify-center bg-[#F9FAFB] border-r-[1px]">
            Joint Status
          </div>
          <div className="h-11 w-[280px] min-w-[280px] text-[#667085] text-[10px] flex flex-col items-center justify-center bg-[#F9FAFB] border-r-[1px] ">
            <div className="border-b-[1px]  w-full flex items-center  justify-center">
              Instant
            </div>
            <div className="flex items-center justify-between min-w-full w-full text-[8px]">
              <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center ">
                MaxTor
              </div>
              <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center ">
                OptTor
              </div>
              <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center ">
                MinTor
              </div>
              <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center ">
                MinTurn
              </div>
              <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center ">
                MaxTurn
              </div>
            </div>
          </div>
          <div className="h-11 w-full min-w-[56px] text-[#667085] text-[10px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            Add to Report
          </div>
          <div className="h-11 w-full min-w-[56px] text-[#667085] text-[10px] justify-center flex  text-center items-center bg-[#F9FAFB] border-r-[1px]">
            View Graph
          </div>
        </div>
        {item.map((e, i) => {
          return (
            <div key={i} className="flex items-center w-full border-b-[1px]">
              <div className="h-8 max-h-8 min-h-8 w-[56px] min-w-[56px] max-w-[56px] max-w-12 text-[#667085] text-[10px] justify-center flex  text-center items-center  border-r-[1px]">
                {Number(e?.JAMJointN).toFixed(2)}
              </div>

              <div className="flex items-center justify-between w-[168px] min-w-[168px] max-w-[168px] h-8 max-h-8 min-h-8">
                <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.fTork
                    ? Number(e?.fTork).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
                <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.sTork
                    ? Number(e?.sTork).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
                <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.dTork
                    ? Number(e?.dTork).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
              </div>

              <div className="flex items-center justify-between w-[168px] min-w-[168px] max-w-[168px] h-8 max-h-8 min-h-8">
                <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.fTurn
                    ? Number(e?.fTurn).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
                <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.STurn
                    ? Number(e?.STurn).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
                <div className="w-1/3 min-w-1/3 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.dTurn
                    ? Number(e?.dTurn).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
              </div>
              <div className="flex items-center justify-between w-[112px] min-w-[112px] max-w-[112px] h-8 max-h-8 min-h-8">
                <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.maxRPM
                    ? Number(e?.maxRPM).toFixed(2)
                    : Number(0).toFixed(2)}
                </div>
                <div className="w-1/2 min-w-1/2 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  {e?.sRPM ? Number(e?.sRPM).toFixed(2) : Number(0).toFixed(2)}
                </div>
              </div>
              <div className="w-[56px] min-w-[56px] max-w-[56px] text-center flex items-center justify-center border-r-[1px] text-[10px] h-8 max-h-8 min-h-8 ">
                {Number(e?.tStop) - Number(e?.tStart)}
              </div>
              <div className="w-[56px] min-w-[56px] max-w-[56px] text-center flex items-center justify-center border-r-[1px] text-[10px] h-8 max-h-8 min-h-8 ">
                {moment(e?.tStart, "X").utc().format("HH:mm:ss")}
              </div>
              <div
                className={`w-[56px] min-w-[56px] max-w-[56px] text-center flex items-center justify-center border-r-[1px] text-[10px] h-8 max-h-8 min-h-8 ${
                  e?.status == "ACCEPT"
                    ? "text-[#039855]"
                    : e?.status == "BREAKOUT"
                    ? "text-[#026AA2]"
                    : "text-[#D92D20]"
                } `}
              >
                {e?.status}
              </div>
              <div className="flex items-center justify-between w-full max-w-[280px] min-w-[280px] h-8 max-h-8 min-h-8">
                <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  <input
                    onChange={() => updateJointData(e, i)}
                    id={`maxTork${i}`}
                    defaultValue={findConnectedLot(e, "maxTork")}
                    className={`w-[90%] px-2 py-0.5 focus:outline-0 border-secondary-300 border-b rounded  text-center`}
                  />
                </div>
                <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  <input
                    onChange={() => updateJointData(e, i)}
                    id={`optTork${i}`}
                    defaultValue={findConnectedLot(e, "optTork")}
                    className={`w-[90%] px-2 py-0.5 focus:outline-0 border-secondary-300 border-b rounded  text-center`}
                  />
                </div>
                <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  <input
                    onChange={() => updateJointData(e, i)}
                    id={`minTork${i}`}
                    defaultValue={findConnectedLot(e, "minTork")}
                    className={`w-[90%] px-2 py-0.5 focus:outline-0 border-secondary-300 border-b rounded  text-center`}
                  />
                </div>
                <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  <input
                    onChange={() => updateJointData(e, i)}
                    id={`minTurn${i}`}
                    defaultValue={findConnectedLot(e, "minTurn")}
                    className={`w-[90%] px-2 py-0.5 focus:outline-0 border-secondary-300 border-b rounded  text-center`}
                  />
                </div>
                <div className="w-1/5 min-w-1/5 text-center flex items-center justify-center border-r-[1px] text-[10px] h-full ">
                  <input
                    onChange={() => updateJointData(e, i)}
                    id={`maxTurn${i}`}
                    defaultValue={findConnectedLot(e, "maxTurn")}
                    className={`w-[90%] px-2 py-0.5 focus:outline-0 border-secondary-300 border-b rounded  text-center`}
                  />
                </div>
              </div>
              <div className="h-8 max-h-8 min-h-8 w-full min-w-[56px] max-w-12 text-[#667085] text-[10px] justify-center flex  text-center items-center  border-r-[1px]">
                <div
                  onClick={() => {
                    if (joints?.includes(e?.JAMJointN)) {
                      setJoints(joints?.filter((id) => id !== e?.JAMJointN));
                    } else {
                      setJoints([...joints, e?.JAMJointN]);
                    }
                  }}
                >
                  <input
                    type={"checkbox"}
                    checked={joints.includes(e?.JAMJointN)}
                    className="w-4 h-4 rounded-md border-secondary-200 accent-teal-600 checked:bg-teal-500"
                  />
                </div>
              </div>
              <div className="cursor-pointer h-8 max-h-8 min-h-8 w-full min-w-[56px] max-w-12 text-[#667085] text-[10px] justify-center flex  text-center items-center  border-r-[1px]">
                <div
                  onClick={() => {
                    findJointData(e, i);
                  }}
                  className="h-5 w-5 flex justify-center items-center rotate-180 text-teal-600 bg-teal-100 rounded"
                >
                  <LeftArrow width={8} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <JointChartModal
        modalIsOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        selectedJoint={selectedJoint}
        report={report}
        jobData={jobData}
        tableData={tableData}
      />
    </div>
  );
};

export default JointTable;

import axios from "axios";

const api = "https://api.jamlog.upu.io/";
// const api = "http://192.168.1.76:8090/";
// const api = "http://localhost:8090/";

const instance = axios.create({
  baseURL: api,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      window.location.href = "/auth/login";
    }
  }
);

export { instance };

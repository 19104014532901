import React from "react";
import { SearchIcon } from "../../assets/icons";

const TableSearch = ({
  setSearchVal,
  placeholder = "Search",
  type,
  height,
  ...rest
}) => {
  return (
    <div className="w-full">
      {type === "menu" ? (
        <div className=" relative rounded-md shadow-none flex items-center border-[1px] border-secondary-300">
          <div className="absolute  left-0 pl-4 flex items-center pointer-events-none outline-none border-none  ">
            <SearchIcon
              width={20}
              height={20}
              className=" text-secondary-500 "
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            className=" block w-full pl-10 sm:text-sm shadow-none pb-2  border-0   bg-white    rounded-lg  focus:shadow-sm focus:outline-none focus:ring focus:ring-teal-50 focus:border-none "
            placeholder={placeholder}
            onChange={(e) => setSearchVal(e.target.value)}
            {...rest}
          />
        </div>
      ) : (
        <div
          style={{ height: height ? height : "40px" }}
          className="  relative rounded-md shadow-none flex items-center"
        >
          <div className="absolute h-full left-0 pl-4 flex items-center pointer-events-none outline-none border-none  ">
            <SearchIcon
              width={20}
              height={20}
              className=" text-secondary-500 "
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            className=" block w-full h-full pl-10 sm:text-sm shadow-none    bg-white border-0 border-b-[1px] border-b-secondary-300 rounded-lg text-secondary-600 focus:shadow-sm focus:outline-none focus:ring focus:ring-teal-50 focus:border-none "
            placeholder={placeholder}
            onChange={(e) => setSearchVal(e.target.value)}
            {...rest}
          />
        </div>
      )}
    </div>
  );
};

export default TableSearch;

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { nodeService, userService } from "../../services";
import i18next from "i18next";
import { BsTrash } from "react-icons/bs";
import {
  Input,
  Loading,
  AlertModal,
  NewInput,
  Select,
  MultiSelect,
} from "../../components";
import { roles } from "../../static/constants";
const CreateNewUser = ({
  setNewUser,
  selectedUser,
  handleUsers,
  setInfoModalOpen,
  setInfoModalData,
}) => {
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState();
  const [nodes, setNodes] = useState([]);
  const { app } = useStore();
  const { t } = useTranslation();
  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lastName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    password: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    role: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    nodes: yup
      .array()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    days: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      lastName: "",
      email: "",
      password: "",
      days: 3,
    },
  });
  const handleNodes = async () => {
    await nodeService
      .listNodes()
      .then((res) => {
        setNodes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleNodes();
    return () => {};
  }, []);
  useEffect(() => {
    if (selectedUser) {
      setValue("name", selectedUser.name);
      setValue("lastName", selectedUser.lastName);
      setValue("email", selectedUser.email);
      setValue("title", selectedUser.title);
      setValue("role", selectedUser.role);
      setValue(
        "nodes",
        selectedUser?.node?.map((node) => {
          return node.id;
        })
      );
      setValue("days", selectedUser.remainingDay);
    } else {
    }
  }, [selectedUser]);
  const onSubmit = async (data) => {
    if (data.password != "" && data.password != passwordRepeat) {
      setNewUser(false);
      setInfoModalData({
        success: false,
        text: t("user:notMatchPassword"),
        header: t("user:password"),
        label: "OK",
      });
      setInfoModalOpen(true);
    } else {
      setLoading(true);
      if (selectedUser && selectedUser.id) {
        app.setLoadingOverlay(true);
        userService
          .updateUser(selectedUser.id, data)
          .then((res) => {
            if (res.data.code === 0) {
              app.setLoadingOverlay(false);
              setLoading(false);
              handleUsers();
              setNewUser(false);
              setInfoModalData({
                success: true,
                text: `${data?.name} ${data?.lastName} ${t("casing:updated")}`,
                header: `${data?.name} ${data?.lastName}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            } else {
              setInfoModalData({
                success: false,
                text: t("buttons:failed"),
                header: `asd`,
                label: "OK",
              });
              setInfoModalOpen(true);
            }
            setLoading(false);
            setNewUser(false);
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      } else {
        app.setLoadingOverlay(true);
        userService
          .addUser(data)
          .then((res) => {
            if (res.data.code === 0) {
              app.setLoadingOverlay(false);
              setLoading(false);
              handleUsers();
              setNewUser(false);
              setInfoModalData({
                success: true,
                text: `${data?.name} ${data?.lastName} ${t("casing:created")}`,
                header: `${data?.name} ${data?.lastName}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            } else if (res.data.code === 2) {
              setNewUser(false);
              setLoading(false);
              setInfoModalData({
                success: false,
                text: t("user:noCredit"),
                header: t("user:noCredit"),
                label: "OK",
              });
              setInfoModalOpen(true);
            } else {
              setInfoModalData({
                success: false,
                text: t("buttons:failed"),
                header: `${data?.name} ${data?.lastName}`,
                label: "OK",
              });
              setInfoModalOpen(true);
            }
            setNewUser(false);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      }
    }
  };
  const deleteUser = async () => {
    setLoading(true);
    app.setLoadingOverlay(true);
    userService
      .deleteUser(selectedUser.id)
      .then((res) => {
        if (res.data.code === 0) {
          app.setLoadingOverlay(false);
          handleUsers();
          setInfoModalData({
            success: true,
            text: `${selectedUser?.name} ${selectedUser?.lastName} ${t(
              "casing:deleted"
            )}`,
            header: `${selectedUser?.name} ${selectedUser?.lastName}`,
            label: "OK",
          });
          setInfoModalOpen(true);
        } else {
          app.setLoadingOverlay(false);
          setInfoModalData({
            success: false,
            text: t("buttons:failed"),
            header: `${selectedUser?.name} ${selectedUser?.lastName}`,
            label: "OK",
          });
          setInfoModalOpen(true);
        }
        setNewUser(false);
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
    setLoading(false);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-start px-4 py-5 gap-4 w-full h-full"
    >
      <div className="flex flex-row items-center px-5 h-14 border-b-[2px] border-gray-200 w-full">
        <div className="flex items-center justify-center">
          <p className="font-medium text-xl">
            {selectedUser?.name
              ? `${selectedUser?.name} ${selectedUser?.lastName}`
              : t("user:createNewUser")}
          </p>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-16 h-16">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="w-full h-[98.5vh] flex items-start flex-col gap-4 overflow-y-auto scrollbar-hide">
            <div className="flex flex-col items-start w-full">
              <div className="flex flex-col items-start gap-4 w-full">
                <div className="w-full xs:hidden xs:min-w-full flex flex-row items-start gap-6 mt-4">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("user:name")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.name ? "error" : ""}
                        errorMessage={errors.name ? errors.name.message : ""}
                        width={"33%"}
                      />
                    )}
                    name="name"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("user:lastName")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.lastName ? "error" : ""}
                        errorMessage={
                          errors.lastName ? errors.lastName.message : ""
                        }
                        width={"33%"}
                      />
                    )}
                    name="lastName"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("user:email")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.email ? "error" : ""}
                        errorMessage={errors.email ? errors.email.message : ""}
                        width={"32.5%"}
                      />
                    )}
                    name="email"
                  />
                </div>
                <div className="w-full xs:hidden xs:min-w-full flex flex-row items-start gap-6">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("mail:validDay")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.days ? "error" : ""}
                        errorMessage={errors.days ? errors.days.message : ""}
                        width={"33%"}
                      />
                    )}
                    name="days"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        placeholder={t("user:role")}
                        onBlur={onBlur}
                        width={"32%"}
                        items={[
                          { label: roles.CA, value: roles.CA },
                          { label: roles.CS, value: roles.CS },
                          { label: roles.CUS, value: roles.CUS },
                          { label: roles.CTM, value: roles.CTM },
                        ]}
                        onChange={(v) => {
                          setValue("role", v);
                        }}
                        value={value}
                        validate={errors.role ? "error" : ""}
                        errorMessage={errors.role ? errors.role.message : ""}
                      />
                    )}
                    name="role"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <MultiSelect
                        placeholder={t("user:jamlog")}
                        onBlur={onBlur}
                        width={"32%"}
                        items={nodes.map((node) => ({
                          label: node.name,
                          value: node.id,
                        }))}
                        onChange={(v) => {
                          setValue("nodes", v);
                        }}
                        value={value}
                        validate={errors.nodes ? "error" : ""}
                        errorMessage={errors.nodes ? errors.nodes.message : ""}
                      />
                    )}
                    name="nodes"
                  />
                </div>
                <div className="w-full xs:hidden xs:min-w-full flex flex-row items-start gap-6">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NewInput
                        label={t("user:password")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        type={"password"}
                        validate={errors.password ? "error" : ""}
                        errorMessage={
                          errors.password ? errors.password.message : ""
                        }
                        width={"32.5%"}
                      />
                    )}
                    name="password"
                  />
                  <NewInput
                    label={t("user:passwordRepeat")}
                    disabled={false}
                    type={"password"}
                    onChange={(e) => {
                      setPasswordRepeat(e.target.value);
                    }}
                    width={"32.5%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="absolute bottom-5 right-10 flex flex-row justify-end items-center gap-6 w-full h-[40px]">
        <div className="flex items-end gap-6 h-[40px]">
          {selectedUser?.id && (
            <button
              onClick={() => {
                setIsAlert(true);
              }}
              type="button"
              className="w-[144px] xs:w-[42%] h-full bg-red-600 text-white flex flex-row items-center justify-center border shadow rounded-lg"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            onClick={() => setNewUser(false)}
            type="button"
            className="w-[144px] h-full bg-white flex flex-row items-center justify-center border shadow rounded-lg text-gray-700"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-[144px] h-full bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
          >
            {selectedUser ? t("buttons:edit") : t("buttons:save")}
          </button>
        </div>
      </div>
      <AlertModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        text={
          i18next.language == "en"
            ? `${t("user:deleteUserSubTitle")} ${selectedUser?.name} ${
                selectedUser?.lastName
              } ?`
            : `${selectedUser?.name} ${selectedUser?.lastName} ${t(
                "user:deleteUserSubTitle"
              )} ?`
        }
        icon={<BsTrash className="text-3xl" />}
        header={"upu.jamlog"}
        label={"OK"}
        applyFunction={() => deleteUser()}
        loading={loading}
      />
    </form>
  );
};

export default CreateNewUser;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InfoModal, ChangeLanguage, NewInput } from "../../components";
import { userService } from "../../services";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useAlert, positions } from "react-alert";
import i18next from "i18next";
import { GrLanguage } from "react-icons/gr";
import UploadProfile from "../../ImageUploader/ImageUploader";

const ChangePassword = () => {
  const { t } = useTranslation();
  const mediaMatch = window.matchMedia("(min-width: 700px)");
  const { auth, app } = useStore();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [changePassword, setChangePassword] = useState(true);
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [match, setMatch] = useState(mediaMatch.matches);
  const alert = useAlert();

  const onSubmit = async () => {
    if (password == passwordRepeat) {
      await userService
        .changePassword({ password: password })
        .then((res) => {
          if (res.data.code === 0) {
            setInfoModalData({
              success: true,
              text: `${auth?.user?.name} ${auth?.user?.lastName} ${t(
                "casing:updated"
              )}`,
              header: `${auth?.user?.name} ${auth?.user?.lastName}`,
              label: "OK",
            });
            setInfoModal(true);
          } else {
            setInfoModalData({
              success: false,
              text: t("buttons:failed"),
              header: `asd`,
              label: "OK",
            });
            setInfoModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setInfoModalData({
        success: false,
        text: t("user:notMatchPassword"),
        header: t("user:password"),
        label: "OK",
      });
      setInfoModal(true);
    }
  };
  return (
    <>
      <div className="w-full h-full flex flex-col relative">
        <div className="flex flex-col items-start w-full h-full">
          <div className="flex flex-row items-center justify-between pl-5 h-14 border-b-[2px] border-gray-200 w-full">
            <div className="flex items-center justify-center gap-x-1">
              <span className="font-bold text-xl xs:text-lg text-secondary-900">
                {t("user:profile")}
              </span>
              <span className="font-bold text-xl xs:text-lg text-[#005F6A]">
                {t("user:page")}
              </span>
            </div>
            <div className="flex gap-4 items-center">
              <div>
                <button
                  onClick={() => {
                    setVisibleLanguage(!visibleLanguage);
                  }}
                  style={{
                    alignItems: "center",
                    right: match ? 0 : -25,
                    position: "",
                    cursor: "pointer",
                    justifyContent: "center",
                    borderRigth: "0px solid black",
                  }}
                  className="bg-[#FFFFFF] select-none xs:w-7 xs:h-7 rounded-md w-10 h-10 flex border"
                >
                  <GrLanguage className="xs:w-4 xs:h-4" color="#344054" />
                </button>
              </div>
              {visibleLanguage && (
                <ChangeLanguage
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  setVisibleLanguage={setVisibleLanguage}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col w-full h-full">
            <div className="flex flex-col w-full gap-x-8 gap-y-8 px-4 py-4 sm:px-6 lg:px-8">
              <div className="md:col-span-2">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <div className="mt-2">
                      <Input
                        disabled={true}
                        value={auth?.user.name}
                        label={t("user:name")}
                        width={"100%"}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <div className="mt-2">
                      <Input
                        disabled={true}
                        value={auth?.user.lastName}
                        label={t("user:lastName")}
                        width={"100%"}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <div className="mt-2">
                      <Input
                        disabled={true}
                        value={auth?.user.email}
                        label={t("user:email")}
                        width={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {changePassword && (
                <div className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <NewInput
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          label={t("user:password")}
                          width={"100%"}
                          type={"password"}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <NewInput
                          onChange={(e) => {
                            setPasswordRepeat(e.target.value);
                          }}
                          label={t("user:passwordRepeat")}
                          width={"100%"}
                          type={"password"}
                        />
                      </div>
                    </div>
                    <div className="mt-8 h-[44px]">
                      <button
                        onClick={() => {
                          onSubmit();
                        }}
                        type="button"
                        className="w-[150px] text-sm h-full ml-auto bg-[#00727F] flex flex-row items-center justify-center border rounded-lg text-white"
                      >
                        {t("auth:changePassword")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <InfoModal
        isOpen={infoModal}
        setIsOpen={setInfoModal}
        text={infoModalData?.text}
        header={infoModalData?.header}
        label={infoModalData?.label}
        isSuccess={infoModalData?.success}
        onClick={() => {
          if (password == passwordRepeat) {
            navigate("/dashboard/map");
          } else {
            setInfoModal(false);
          }
        }}
      />
    </>
  );
};

export default ChangePassword;

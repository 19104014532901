import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import useMarkerMove from "../../useMoveMarker";
import { useStore } from "../../../hooks/useStores";
import AdminModal from "../../../components/Modals/AdminModal";
import SaveReportForm from "../../reportComponents/SaveReportForm";
import JamlogIcon from "../../../assets/images/petrolPin.svg";
import JamlogIconOrange from "../../../assets/images/petrolPinOrange.svg";
import TravellingIcon from "../../../assets/images/travellingMarker.svg";
import JamlogIconPurple from "../../../assets/images/purplePin.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
const lib = ["geometry", "drawing"];
const MapContainer = observer(
  ({
    getLocation,
    reports,
    handleReports,
    selectedReport,
    isOpen,
    setIsOpen,
    setSelectedReport,
    setHovered,
    hovered,
    isLite,
    setCoordinates,
    coordinates,
    selectedJob,
    setSelectedJob,
  }) => {
    const { t } = useTranslation();
    const { report } = useStore();
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyDMfdUq-sI_WZrvKFuA5sXY8vQw3Wj1Usk",
      libraries: lib,
    });
    const [selected, setSelected] = useState({});
    const [currentPosition, setCurrentPosition] = useState({});
    const [activeMarker, setActiveMarker] = useState(null);
    let [currentCoordinates, setCurrentCoordinates] = useState([
      39.3851, 35.1734,
    ]);

    const [defaultCenter, setDefaultCenter] = useState({
      lat: 39.3851,
      lng: 35.1734,
    });
    useEffect(() => {
      if (selectedReport) {
        setCurrentCoordinates([
          selectedReport?.latitude,
          selectedReport?.longitude,
        ]);
      }
      if (selectedJob) {
        setCurrentCoordinates([selectedJob?.latitude, selectedJob?.longitude]);
      }
    }, [selectedReport, selectedJob]);
    useEffect(() => {
      if (
        report?.reportData?.gps?.lat != "0.0" &&
        report?.reportData?.gps?.lng != "0.0"
      ) {
        setCurrentCoordinates([
          Number(report?.reportData?.gps?.lat),
          Number(report?.reportData?.gps?.lng),
        ]);
      }
    }, []);
    const array = [
      {
        icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        key: "1",
        position: { lat: 37.78823262111074, lng: 38.220411350351405 },
      },
    ];
    function TravellingMarker({ position, ...rest }) {
      let [coordinates, setDestination] = useMarkerMove([
        position.lat,
        position.lng,
      ]);
      useEffect(() => {
        setDestination([position.lat, position.lng]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      return (
        <Marker
          icon={{
            // path: google.maps.SymbolPath.CIRCLE,
            url: TravellingIcon,
            fillColor: "#EB00FF",
            // scale: 10,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          position={{
            lat: coordinates[0],
            lng: coordinates[1],
          }}
        />
      );
    }
    const markerRef = useRef(null);
    const googleMap = useRef(null);

    const onSelect = (item) => {
      setSelected(item);
    };

    const success = (position) => {
      const latitude = position?.coords?.latitude;
      const longitude = position?.coords?.longitude;
      const currentPosition = {
        lat: latitude,
        lng: longitude,
      };
      setCurrentPosition(currentPosition);
    };

    const onMarkerDragEnd = (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setCurrentPosition({ lat, lng });
    };

    useEffect(() => {
      navigator.geolocation.getCurrentPosition(success);
    }, []);

    const createMarker = (markerObj) =>
      new window.google.maps.Marker({
        position: { lat: markerObj.lat, lng: markerObj.lng },
        map: googleMap,
        icon: {
          url: markerObj.icon,
          // set marker width and height
          scaledSize: new window.google.maps.Size(50, 50),
        },
      });

    const distanceToMouse = (pt, mp) => {
      if (pt && mp) {
        // return distance between the marker and mouse pointer
        return Math.sqrt(
          (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
      }
    };

    return (
      <>
        {isLoaded && (
          <div className="relative w-full h-full">
            <GoogleMap
              ref={googleMap}
              distanceToMouse={distanceToMouse}
              onClick={(e) => {
                setActiveMarker(null);
                setCurrentCoordinates([e.latLng.lat(), e.latLng.lng()]);
                if (isLite) {
                  setCoordinates([e.latLng.lat(), e.latLng.lng()]);
                }
                if (!(report?.chooseLocation || report?.updateReportLocation)) {
                  setSelectedReport(null);
                }
              }}
              defaultZoom={3}
              defaultCenter={{ lat: -34.397, lng: 150.644 }}
              id="example-map"
              mapContainerStyle={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
              }}
              draggable={true}
              options={{
                mapTypeControl: false,
                streetViewControl: false,
                disableDefaultUI: true,
              }}
              zoom={7}
              center={
                !report.updateReportLocation && selectedReport?.latitude
                  ? {
                      lat: selectedReport?.latitude,
                      lng: selectedReport?.longitude,
                    }
                  : defaultCenter
              }
            >
              {report.chooseLocation || report.updateReportLocation ? (
                <TravellingMarker
                  position={{
                    lat: currentCoordinates[0],
                    lng: currentCoordinates[1],
                  }}
                />
              ) : (
                reports?.map((rep, index) => {
                  // if (selectedReport == rep && report.updateReportLocation) {
                  //   return (
                  //     <TravellingMarker
                  //       key={index}
                  //       position={{
                  //         lat: currentCoordinates[0],
                  //         lng: currentCoordinates[1],
                  //       }}
                  //     />
                  //   );
                  // }
                  if (selectedJob?.id == rep?.id && rep?.status == "active") {
                    return null;
                  } else {
                    return (
                      <>
                        <Marker
                          key={index}
                          position={{ lat: rep.latitude, lng: rep.longitude }}
                          icon={{
                            // path: google.maps.SymbolPath.CIRCLE,
                            url:
                              rep.status == "active"
                                ? JamlogIconOrange
                                : JamlogIcon,
                            fillColor: "#EB00FF",
                            // scale: 100,
                            scaledSize: new window.google.maps.Size(50, 50),
                          }}
                          onDblClick={() => {
                            if (report.chooseLocation != true && !rep.status) {
                              window.open(`https://cdn.upu.io/${rep?.s3Key}`);
                            }
                          }}
                          onClick={() => {
                            setActiveMarker(rep.id);
                            setSelectedReport(rep);
                          }}
                          onMouseOver={() => {
                            setActiveMarker(rep.id);
                          }}
                          onMouseOut={() => {
                            setActiveMarker(null);
                          }}
                          // onMouseOut={() => {
                          //   setTimeout(() => {
                          //     setActiveMarker(null);
                          //   }, 1000);
                          // }}
                        >
                          {activeMarker === rep.id && (
                            <InfoWindow
                              onCloseClick={() => {
                                setActiveMarker(null);
                              }}
                            >
                              <div
                                className=""
                                onClick={() => {
                                  if (
                                    report.chooseLocation != true &&
                                    !rep.status
                                  ) {
                                    window.open(
                                      `https://cdn.upu.io/${rep?.s3Key}`
                                    );
                                  }
                                }}
                              >
                                <div className="flex justify-between flex-col">
                                  <p className="text-xs font-semibold">
                                    {rep.name ? rep.name : rep.jobName}
                                  </p>
                                </div>
                                <p className="text-xs">
                                  {rep.rig + "  " + rep.well}
                                </p>
                                <p className="text-[10px] font-regular">
                                  {rep?.createdDate
                                    ? moment(rep?.createdDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : moment(rep?.deliveryDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                </p>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      </>
                    );
                  }
                })
              )}
              {isLite && (
                <TravellingMarker
                  position={{
                    lat: coordinates[0],
                    lng: coordinates[1],
                  }}
                />
              )}
            </GoogleMap>
            {/* {hovered && <div className="absolute top-0 left-[400px] m-5"></div>} */}
          </div>
        )}
        <AdminModal
          modalIsOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          children={
            <SaveReportForm
              setIsOpen={setIsOpen}
              coordinates={currentCoordinates}
              handleReports={handleReports}
              selectedReport={selectedReport}
              setSelectedReport={setSelectedReport}
              setSelectedJob={setSelectedJob}
            />
          }
          height={"300px"}
          title={t("dashboard:saveReport")}
        />
      </>
    );
  }
);

export default MapContainer;

import React from "react";

import FooterBack from "../..//assets/images/footerback.png";
import { useStore } from "../../hooks/useStores";
import generateFileUrl from "../../utils/generateFileUrl";

const Footer = ({
  companyImage,
  company,
  user,
  revNo = "R1",
  reportDate,
  pageNo,
}) => {
  const { auth } = useStore();
  return (
    <div
      className=" w-full px-5 py-[9px] border-t-[1px] mt-auto min-h-[52px] h-[52px] flex items-center justify-between "
      style={{
        backgroundImage: "url(" + FooterBack + ")",
      }}
    >
      <div className="flex items-center">
        <img
          src={generateFileUrl(auth.user.company.image)}
          className="w-[44px] h-[33px] border-[1px] rounded-[4px]"
        />
        <div className="flex flex-col ml-2">
          <p className="text-[#1D2939] text-[10px] font-semibold">
            {auth?.user?.company?.name}
          </p>
          <p className="text-[#1D2939] text-[9px] ">
            {auth?.user?.name + " " + auth?.user?.lastName}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-x-2  ">
        <span className="flex  flex-col">
          <p className="text-[#1D2939] text-[9px] ">{reportDate}</p>
          <p className="text-[#1D2939] text-[9px] ml-auto">Revizyon:{revNo}</p>
        </span>
        <span className=" p-1 px-2 bg-[#004D56] text-white rounded-full text-xs flex items-center justify-center">
          {pageNo}
        </span>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert, positions } from "react-alert";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Input from "../../components/Inputs/Input";
import NewDatePicker from "../../components/Inputs/DatePicker/DatePicker";
import { useStore } from "../../hooks/useStores";
import { readService } from "../../services/read.service";
import { Loading, LoadingWithValue } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SaveReportForm = ({
  selectedReport,
  setSelectedReport,
  setIsOpen,
  coordinates,
  handleReports,
  setSelectedJob,
}) => {
  const [progress, setProgress] = useState(0);
  const { app, report, auth } = useStore();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = yup.object({
    name: yup
      .string()
      .nullable()
      .required(`${t("validation:empty")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });
  useEffect(() => {
    if (selectedReport) {
      setValue("name", selectedReport?.name);
    } else {
    }
  }, [selectedReport, setSelectedReport]);
  const onSubmit = async (data) => {
    app.setLoadingOverlay(true);
    setLoading(true);
    if (selectedReport && selectedReport.id) {
      await readService
        .updateReport(selectedReport.id, {
          name: data.name,
          latitude: coordinates[0],
          longitude: coordinates[1],
        })
        .then((res) => {
          if (res.data.code == 0) {
            setProgress(100);
            app.setLoadingOverlay(false);
            alert.success(t("job:updated"), {
              position: positions.TOP_LEFT,
            });
            report.chooseLocation = false;
            report.updateReportLocation = false;
            report.setReportData({});
            setSelectedReport(null);
            setSelectedJob(null);
            handleReports();
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.error(t("job:failed"), {
              position: positions.TOP_LEFT,
            });
            report.chooseLocation = false;
            report.updateReportLocation = false;
          }
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios.post(`https://api.jamlog.upu.io/read/save`, {
        content: report?.reportData,
        reportInfo: { name: data.name, coordinates: coordinates },
        uniqueId: report?.reportData?.uniqueId,
      }, {
        timeout: 480000,
        timeoutErrorMessage: "Request Timeout",
        headers: {
          common: {
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      })
        .then((res) => {
          if (res.data.code == 0) {
            setProgress(100);
            app.setLoadingOverlay(false);
            alert.success(t("job:saved"), {
              position: positions.TOP_LEFT,
            });
            report.chooseLocation = false;
            report.updateReportLocation = false;
            report.setReportData({});
            setSelectedReport(null);
            setSelectedJob(null);
            handleReports();
            setIsOpen(false);
          } else if (res.data.code == 2) {
            alert.error(res?.data?.message, {
              position: positions.TOP_LEFT,
            });
          } else {
            app.setLoadingOverlay(false);
            alert.error(t("job:failed"), {
              position: positions.TOP_LEFT,
            });
            report.chooseLocation = false;
            report.updateReportLocation = false;
          }
          setLoading(false);
        })
        .catch((err) => {
          app.setLoadingOverlay(false);
          alert.error('Lütfen daha sonra tekrar deneyin.', {
            position: positions.TOP_LEFT,
          });
          report.chooseLocation = false;
          report.updateReportLocation = false;
          report.setReportData({});
          setSelectedReport(null);
          setSelectedJob(null);
          setIsOpen(false);
        });
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 99 ? 99 : prevProgress + 1
      );
    }, 1400);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="h-full w-full">
      <div>
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <div className="mt-4">
              <LoadingWithValue value={progress} />
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-3 "
          >
            <div className="w-full mt-6">
              <div className="flex flex-col gap-y-2 w-full h-full">
                <div className="w-full h-full gap-6 flex justify-between">
                  <>
                    <div className={`w-full flex flex-col`}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            label={t("dashboard:reportName")}
                            value={value}
                            disabled={false}
                            onBlur={onBlur}
                            onChange={onChange}
                            validate={errors.name ? "error" : ""}
                            errorMessage={
                              errors.name ? errors.name.message : ""
                            }
                          />
                        )}
                        name="name"
                      />
                    </div>
                  </>
                </div>
              </div>
              <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                <button
                  type="button"
                  onClick={() => {
                    report.chooseLocation = false;
                    report.updateReportLocation = false;
                    setIsOpen(false);
                  }}
                  className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                >
                  {t("buttons:stop")}
                </button>
                <button
                  type="submit"
                  className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-[#005F6A] shadow hover:bg-teal-700"
                >
                  {t("buttons:save")}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SaveReportForm;

import React from "react";
import i18next from "i18next";
import { RadioGroup } from "@headlessui/react";
import { BritainFlag, TurkishFlag } from "../assets/icons";
const ChangeLanguage = ({
  setVisibleLanguage,
  setSelectedLanguage,
  selectedLanguage,
}) => {
  const plans = [
    {
      name: "TR",
      value: "tr",
      icon: <TurkishFlag />,
    },
    {
      name: "EN",
      value: "en",
      icon: <BritainFlag />,
    },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div
      className="fixed top-0 w-full left-0 bottom-0 h-full bg-transparent z-50 "
      onClick={() => setVisibleLanguage(false)}
    >
      <div className="absolute right-4 top-20 bg-white">
        <RadioGroup
          value={selectedLanguage}
          className="w-full "
          onChange={(e) => {
            setSelectedLanguage(e);
            i18next.changeLanguage(e);
          }}
        >
          <div className="relative flex flex-col gap-2 bg-white rounded-md p-2 border border-gray-300 shadow-md">
            {plans.map((e, i) => (
              <RadioGroup.Option
                key={e.name}
                value={e.value}
                className={({ checked }) =>
                  classNames(
                    i === 0 ? "rounded-tl-md rounded-tr-md" : "",
                    i === e.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                    e.value === selectedLanguage
                      ? "bg-teal-50 border-teal-200 z-10"
                      : "border-gray-200",
                    "relative border rounded-lg px-6 py-3 flex flex-col cursor-pointer select-none focus:outline-none hover:bg-teal-50"
                  )
                }
                onClick={() => setVisibleLanguage(false)}
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex items-center text-sm justify-between w-full ">
                      <div className="flex items-center gap-x-2 ">
                        <div> {e.icon}</div>
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            e.value === selectedLanguage
                              ? "text-secondary-700"
                              : "text-secondary-500",
                            "mr-3 font-medium"
                          )}
                        >
                          {e.name}
                        </RadioGroup.Label>
                      </div>
                      <span
                        className={classNames(
                          e.value === selectedLanguage
                            ? "bg-teal-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-teal-600" : "",
                          "h-3 w-3 rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5 " />
                      </span>
                    </span>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default ChangeLanguage;

import { instance } from "../libs/client";
import { authStore } from "../stores/auth.store";

class AuthService {
  setAuthInterceptor(token) {
    if (token) {
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete instance.defaults.headers.common.Authorization;
    }
  }
  login({ email, password }) {
    return instance.post("auth/login", {
      email,
      password,
    });
  }
  logout(user) {
    return instance.post("auth/logout", user);
  }

  addPasswordToAccount({ token, password, passwordRepeat }) {
    return instance.post("auth/change-password", {
      token: token,
      password: password,
      passwordRepeat: passwordRepeat,
    });
  }
}
const authService = new AuthService();
export { authService };
